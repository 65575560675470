import { ComposerDrawer } from 'sections/composer/core/ComposerDrawer'
import { SchemaNode } from 'sections/composer/schema/SchemaNode'
import ReactFlow, {
  OnConnectStart,
  OnConnectEnd,
  Background,
  BackgroundVariant,
  NodeOrigin,
  Controls,
  useStoreApi,
  useReactFlow,
  Node,
  NodeChange
} from 'reactflow'
import { RFState, useStore } from 'sections/composer/core/store'
import 'reactflow/dist/style.css'
import { memo, useCallback, useEffect, useRef } from 'react'
import { SettingsNode } from 'sections/composer/settings/SettingsNode'
import { SchematicFlowNode, ViableParentNode } from 'sections/composer/types'
import { GenerateNode } from 'sections/composer/generate/GenerateNode'
import { ArtifactsNode } from 'sections/composer/artifacts/ArtifactsNode'
import { useWorkflow } from 'sections/composer/WorkflowProvider'
import { match } from 'ts-pattern'
import { MenuNode } from 'sections/composer/menu/MenuNode'
import { initializeNodes } from 'sections/composer/core/initializeNodes'
import { OpenSchemaNode } from 'sections/composer/openSchema/OpenSchemaNode'
import { ExportNode } from 'sections/composer/export/ExportNode'

const selector = (state: RFState) => ({
  nodes: state.nodes,
  edges: state.edges,
  onNodesChange: state.onNodesChange,
  onEdgesChange: state.onEdgesChange,
  addChildNode: state.addChildNode,
  initialize: state.initialize
})

// this places the node origin in the center of a node
const nodeOrigin: NodeOrigin = [0, 0.5]

const nodeTypes = {
  artifacts: ArtifactsNode,
  generate: GenerateNode,
  schema: SchemaNode,
  settings: SettingsNode,
  menu: MenuNode,
  'open-schema': OpenSchemaNode,
  export: ExportNode
}

export const Composer = memo(() => {
  const { state, dispatch } = useWorkflow()
  const { workflow } = state

  const store = useStoreApi()
  const { screenToFlowPosition } = useReactFlow()

  const getChildNodePosition = (event: MouseEvent, parentNode?: Node) => {
    const { domNode } = store.getState()

    if (
      !domNode ||
      // we need to check if these properites exist, because when a node is not initialized yet,
      // it doesn't have a positionAbsolute nor a width or height
      !parentNode?.positionAbsolute ||
      !parentNode?.width ||
      !parentNode?.height
    ) {
      return
    }

    const panePosition = screenToFlowPosition({
      x: event.clientX,
      y: event.clientY
    })

    // we are calculating with positionAbsolute here because child nodes are positioned relative to their parent
    const coordinates = {
      x: panePosition.x,
      y: panePosition.y
    }

    return coordinates
  }

  const {
    nodes,
    edges,
    onNodesChange: onNodesChangeInternal,
    onEdgesChange,
    addChildNode,
    initialize
  } = useStore(selector)

  const onNodesChange = useCallback((changes: NodeChange[]) => {
    changes.forEach(change => {
      match(change)
        .with({ type: 'remove' }, ({ id }) => {
          dispatch({
            type: 'removeNode',
            payload: {
              nodeId: id
            }
          })
        })
        .otherwise(() => {})
    })
    onNodesChangeInternal(changes)
  }, [])

  useEffect(() => {
    initialize({
      edges: workflow.content.edges,
      nodes: initializeNodes(workflow.content)
    })
  }, [])

  const connectingNodeId = useRef<string | null>(null)

  const onConnectStart: OnConnectStart = useCallback((_, { nodeId }) => {
    connectingNodeId.current = nodeId
  }, [])

  const onConnectEnd: OnConnectEnd = useCallback(
    event => {
      const { nodeInternals } = store.getState()
      const targetIsPane = (event.target as Element).classList.contains(
        'react-flow__pane'
      )
      const node = (event.target as Element).closest('.react-flow__node')

      if (node) {
        node.querySelector('input')?.focus({ preventScroll: true })
      } else if (targetIsPane && connectingNodeId.current) {
        const parentNode = nodeInternals.get(connectingNodeId.current) as
          | SchematicFlowNode
          | undefined

        const childNodePosition = getChildNodePosition(
          event as MouseEvent,
          parentNode
        )

        if (isViableParentNode(parentNode) && childNodePosition) {
          addChildNode({
            parentNode,
            position: childNodePosition
          })
        }
      }
    },
    [getChildNodePosition]
  )

  useEffect(() => {
    dispatch({
      type: 'updateEdges',
      payload: {
        edges
      }
    })
  }, [edges])

  return (
    <ReactFlow
      nodes={nodes}
      edges={edges}
      onConnectStart={onConnectStart}
      onConnectEnd={onConnectEnd}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      nodeOrigin={nodeOrigin}
      nodeTypes={nodeTypes}
    >
      <Controls showInteractive={false} />
      <Background variant={BackgroundVariant.Dots} gap={12} size={1} />
      <ComposerDrawer />
    </ReactFlow>
  )
})

const viableParentNodes = [
  'open-schema',
  'schema',
  'settings',
  'generate',
  'artifacts'
]

const isViableParentNode = (
  node?: SchematicFlowNode
): node is ViableParentNode => {
  if (!node?.type) {
    return false
  }

  return viableParentNodes.includes(node.type)
}
