import { Method } from '@schematicos/types'
import { Badge } from 'components/Badge/Badge'
import { FC } from 'react'
import { match } from 'ts-pattern'

type MethodBadgeProps = {
  children: Method
}

export const MethodBadge: FC<MethodBadgeProps> = ({ children }) => (
  <Badge
    color={match(children)
      .with('get', () => 'success' as const)
      .with('post', () => 'primary' as const)
      .with('put', () => 'warning' as const)
      .with('delete', () => 'danger' as const)
      .otherwise(() => 'neutral' as const)}
  >
    {children}
  </Badge>
)
