import Alert from '@mui/joy/Alert'
import Box from '@mui/joy/Box'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import FormLabel from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import Switch from '@mui/joy/Switch'
import Typography from '@mui/joy/Typography'
import {
  Method,
  MuiFormConfigType,
  defaultExportPath
} from '@schematicos/types'
import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import { SettingsLiveData } from 'sections/composer/types'
import { updateOperation } from 'sections/composer/settings/mutators'

type MuiFormOperationConfigProps = {
  path: string
  method: Method
  values: MuiFormConfigType | undefined
  internal$: BehaviorSubject<SettingsLiveData>
  voidItemMessage: string | undefined
}

type MuiFormState = {
  selected: boolean
  exportPath: string
}

export const MuiFormOperationConfig = ({
  path,
  method,
  internal$,
  voidItemMessage
}: MuiFormOperationConfigProps) => {
  const [formValues, setFormValues] = useState<MuiFormState>({
    selected: false,
    exportPath: ''
  })

  useEffect(() => {
    internal$.subscribe(data => {
      const values =
        data.settingsConfig?.operations[path]?.[method]?.['mui-form']

      setFormValues(current => ({
        selected: values?.selected ?? current.selected,
        exportPath: values?.exportPath ?? current.exportPath
      }))
    })
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            lineHeight: '16px',
            fontSize: '12px',
            fontWeight: '500',
            color: 'neutral.700',
            textTransform: 'uppercase'
          }}
        >
          MUI Form
        </Typography>

        <Switch
          size="sm"
          checked={voidItemMessage ? false : formValues.selected}
          disabled={Boolean(voidItemMessage)}
          onChange={event => {
            updateOperation({
              type: 'property',
              path,
              method,
              propertyName: 'selected',
              property: event.target.checked,
              library: 'mui-form',
              internal$
            })
          }}
        />
      </Box>

      {voidItemMessage ? (
        <Alert color="warning">{voidItemMessage}</Alert>
      ) : (
        <FormControl /*disabled={!muiFormEnabled} */>
          <FormLabel>Export path</FormLabel>
          <Input
            placeholder={defaultExportPath}
            value={formValues.exportPath}
            onChange={event => {
              updateOperation({
                type: 'property',
                path,
                method,
                propertyName: 'exportPath',
                property: event.target.value,
                library: 'mui-form',
                internal$
              })
            }}
          />
          <FormHelperText sx={{ display: 'inline' }}>
            Destination file for the generated model code
          </FormHelperText>
        </FormControl>
      )}
    </Box>
  )
}
