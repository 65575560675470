import { memo, useEffect } from 'react'
import { NodeProps } from 'reactflow'
import Typography from '@mui/joy/Typography'
import { SchemaNodeSummary } from 'sections/composer/schema/SchemaNodeSummary'
import { NodeContainer } from 'sections/composer/shared/NodeContainer'
import Box from '@mui/joy/Box'
import { SchemaIcon } from 'icons/SchemaIcon'
import Alert from '@mui/joy/Alert'
import { NodeAction, OpenSchemaLiveData } from 'sections/composer/types'
import { openSchemaActionFn } from 'sections/composer/openSchema/openSchemaAction'
import * as Sentry from '@sentry/react'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { useWorkflow } from 'sections/composer/WorkflowProvider'
import { WorkflowNode } from '@schematicos/types'
import {
  createLook,
  getHighlightColor
} from 'sections/composer/shared/createLook'

export const OpenSchemaNode = memo(
  (node: NodeProps<NodeAction<OpenSchemaLiveData>>) => {
    const { dispatch } = useWorkflow()
    const { data, selected } = node

    const { source, schemaModel } = useInternalSubject<OpenSchemaLiveData>(
      data.internal$
    )

    const endpointCount = schemaModel?.operations.length ?? 0
    const modelCount = schemaModel?.components?.models
      ? Object.values(schemaModel?.components.models).length
      : 0

    useEffect(() => {
      const workflowNode: WorkflowNode = {
        id: node.id,
        type: 'open-schema',
        position: { x: node.xPos, y: node.yPos },
        selected: node.selected,
        data: {
          source
        }
      }

      dispatch({
        type: 'updateNode',
        payload: {
          node: workflowNode
        }
      })
    }, [node.xPos, node.yPos, source, node.selected])

    useEffect(() => {
      if (!source?.content) {
        return
      }

      openSchemaActionFn({
        schemaDocument: source.content,
        schemaFormat: 'json'
      })
        .then(newResult => {
          const current = data.internal$.getValue()

          data.internal$.next({
            ...current,
            source,
            schemaModel: newResult.schemaModel
          })
        })
        .catch(error => {
          console.error(error)
          Sentry.captureException(error)
        })
    }, [source?.content])

    return (
      <NodeContainer
        selected={selected}
        look={createLook({ nodeType: 'open-schema', selected })}
        highlightColor={getHighlightColor('open-schema')}
      >
        <Box
          display="flex"
          flex={1}
          sx={{
            color: `hsla(225.9, 70.7%, 40.2%, ${selected ? 1 : 0.4})`,
            padding: '8px',
            gap: '8px'
          }}
        >
          <SchemaIcon width="24px" height="24px" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
          >
            {schemaModel ? (
              <Typography
                sx={{ fontSize: 'sm', lineHeight: '18px', paddingRight: '8px' }}
              >
                {schemaModel?.info.title}
              </Typography>
            ) : (
              <Alert color="warning" sx={{ padding: '8px', mx: '8px' }}>
                Please select a schema
              </Alert>
            )}
            {schemaModel ? (
              <SchemaNodeSummary
                endpointCount={endpointCount}
                modelCount={modelCount}
              />
            ) : null}
          </Box>
        </Box>
      </NodeContainer>
    )
  }
)
