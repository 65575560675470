import Autocomplete from '@mui/joy/Autocomplete'
import CircularProgress from '@mui/joy/CircularProgress'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { useApp } from 'components/AppProvider/AppProvider'
import {
  GithubSourceFormValues,
  GithubRepoSummary,
  githubRepoSummaries
} from '@schematicos/types'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'
import { useFetchError } from 'components/useFetchError/useFetchError'

export const GithubRepos = () => {
  const { control } = useFormContext<Pick<GithubSourceFormValues, 'repo'>>()
  const { handleFetchError } = useFetchError()
  const { state } = useApp()
  const { session } = state
  const [repos, setRepos] = useState<GithubRepoSummary[]>([])
  const [page, setPage] = useState(1)
  const loadingRef = useRef(false)

  if (!session) {
    return null
  }

  const per_page = 100

  useEffect(() => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    const searchParams = new URLSearchParams({
      page: `${page}`,
      per_page: `${per_page}`
    })

    supabaseClient.functions
      .invoke(`github/user/repos?${searchParams}`, {
        method: 'GET'
      })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const incomingRepos = githubRepoSummaries.parse(data)

        if (incomingRepos?.length === per_page) {
          setPage(currentPage => currentPage + 1)
        }

        setRepos(currentRepos => {
          const newRepos = incomingRepos
            .filter(({ id }) => {
              return !currentRepos.find(({ id: currentId }) => currentId === id)
            })
            .map(({ id, name, owner }) => ({
              id,
              name,
              owner
            }))

          return [...currentRepos, ...newRepos]
        })
      })
      .catch(e => {
        console.error(e)
        handleFetchError('Failed to retrieve repos from GitHub')
      })
      .finally(() => (loadingRef.current = false))
  }, [page])

  const options = useMemo(
    () => repos.map(({ name, owner }) => `${owner}/${name}`),
    [repos]
  )

  return (
    <Controller
      name="repo"
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <FormControl sx={{ py: '8px', px: '12px' }}>
          <FormLabel>Select repo</FormLabel>
          <Autocomplete
            {...field}
            size="sm"
            onChange={(_, value) => onChange(value)}
            options={options}
            placeholder="Choose OpenAPI source type…"
            loading={loadingRef.current}
            endDecorator={
              loadingRef.current ? (
                <CircularProgress
                  size="sm"
                  sx={{ bgcolor: 'background.surface' }}
                />
              ) : null
            }
          />
        </FormControl>
      )}
    />
  )
}
