import { AppState, Notification } from 'components/types.ts'

export type AddNotificationAction = {
  type: 'addNotification'
  payload: Notification
}

export const addNotification =
  (state: AppState) =>
  ({ payload }: AddNotificationAction): AppState => ({
    ...state,
    notifications: [...state.notifications, payload]
  })
