import { ApiPathsNavigation } from 'components/ApiPaths/ApiPathsNavigation'
import { memo, useMemo, useState } from 'react'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { match, P } from 'ts-pattern'
import { SettingsLiveData } from 'sections/composer/types'
import { Spinner } from 'components/Spinner/Spinner'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { Observable } from 'rxjs/internal/Observable'
import {
  ModelType,
  OperationType,
  RootType,
  SelectedItem
} from 'sections/composer/settings/types'
import { SettingsDrawerContentItem } from 'sections/composer/settings/SettingsDrawerContentItem'
import {
  toggleModel,
  toggleOperation,
  toggleAll
} from 'sections/composer/settings/mutators'
import { z } from 'zod'
import { generateConfigType, oasRoot } from '@schematicos/types'
import { TreeItem, TreeItemLabel } from 'components/TreeItem/TreeItem.tsx'
import { useInboundObservable } from 'sections/composer/useInboundObservable'

type SettingsDrawerContentProps = {
  inbound$: Observable<Record<string, unknown>>
  internal$: BehaviorSubject<SettingsLiveData>
}

const settingsNodeInbound = z.object({
  schemaHash: z.string().optional(),
  schemaModel: oasRoot.optional(),
  generateConfig: generateConfigType.optional()
})

type SettingsNodeInbound = z.infer<typeof settingsNodeInbound>

export const SettingsDrawerContent = memo(
  ({ inbound$, internal$ }: SettingsDrawerContentProps) => {
    const [selectedNodes, setSelectedNodes] = useState<SelectedItem[]>([])
    const inbound = useInboundObservable<SettingsNodeInbound>(inbound$)

    const { schemaModel, generateConfig } = inbound ?? {}

    const { settingsConfig } = useInternalSubject(internal$)

    const transformers = useMemo(() => {
      return generateConfig?.library ?? []
    }, [generateConfig?.library])

    if (!schemaModel || !settingsConfig) {
      return <Spinner />
    }

    return (
      <PanelGroup direction="horizontal">
        <Panel id="export-paths-panel" minSize={10} defaultSize={15} order={1}>
          <ApiPathsNavigation
            schemaModel={schemaModel}
            settingsConfig={settingsConfig}
            transformers={transformers}
            defaultSelectedNodeId="root"
            toggleModel={modelName => {
              toggleModel({ modelName, internal$ })
            }}
            toggleOperation={({ path, method, selected }) => {
              toggleOperation({
                path,
                method,
                transformers,
                selected,
                internal$
              })
            }}
            toggleAll={selectAll => {
              toggleAll({ schemaModel, transformers, selectAll, internal$ })
            }}
            onNodeSelect={newNodeIds => {
              const newSelectedNodes = newNodeIds.map(newNodeId => {
                return getSelectedNode(newNodeId.split('|'))
              })

              setSelectedNodes(newSelectedNodes)
            }}
          >
            <TreeItem
              key="root"
              nodeId="root"
              label={<TreeItemLabel>Root</TreeItemLabel>}
            />
          </ApiPathsNavigation>
        </Panel>
        <PanelResizeHandle
          style={{
            width: '2px',
            height: '100%',
            background: 'var(--joy-palette-neutral-200, #DDE7EE)'
          }}
        />
        <Panel minSize={25} order={2}>
          <SettingsDrawerContentItem
            schemaModel={schemaModel}
            settingsConfig={settingsConfig}
            internal$={internal$}
            generateConfig={generateConfig}
            selectedNodes={selectedNodes}
          />
        </Panel>
      </PanelGroup>
    )
  }
)

const getSelectedNode = (nodeIdChunks: string[]): SelectedItem => {
  return match(nodeIdChunks)
    .with(
      ['components', 'models', P.string],
      ([, , modelName]): ModelType => ['components', 'models', modelName]
    )
    .with(['root'], (): RootType => ['root'])
    .with(
      [
        'operations',
        P.string,
        P.union(
          'get',
          'put',
          'post',
          'delete',
          'options',
          'head',
          'patch',
          'trace'
        )
      ],
      ([, path, method]): OperationType => ['operations', path, method]
    )
    .otherwise(() => {
      throw new Error('Invalid node id')
    })
}
