import { Spinner } from 'components/Spinner/Spinner'
import { useGetWorkflow } from 'loaders/workflows/useGetWorkflow'
import { useUpdateWorkflow } from 'loaders/workflows/useUpdateWorkflow'
import debounce from 'lodash/debounce'
import { ReactNode, useRef } from 'react'
import { useParams } from 'react-router-dom'
import { WorkflowProvider } from 'sections/composer/WorkflowProvider'

type WorkflowLoaderProps = {
  children: ReactNode
}

export const WorkflowLoader = ({ children }: WorkflowLoaderProps) => {
  const { workflowId } = useParams()

  const updateWorkflow = useUpdateWorkflow()
  const debouncedUpdateWorkflow = useRef(debounce(updateWorkflow, 3000))

  const { data: workflowData } = useGetWorkflow(
    workflowId ? { workflowId } : { skip: true }
  )

  if (!workflowData) {
    return <Spinner />
  }

  return (
    <WorkflowProvider
      persistWorkflow={debouncedUpdateWorkflow.current}
      workflow={workflowData}
    >
      {children}
    </WorkflowProvider>
  )
}
