import { useFetchError } from 'components/useFetchError/useFetchError'
import { TransformerModel, transformerModel } from '@schematicos/types'
import { useEffect, useRef, useState } from 'react'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

type UseGetTransformerArgs =
  | {
      transformerId: string
    }
  | {
      skip: true
    }

export const useGetTransformer = (args: UseGetTransformerArgs) => {
  const { handleFetchError } = useFetchError()
  const [transformer, setTransformer] = useState<TransformerModel>()
  const loadingRef = useRef(false)
  const transformerId = 'transformerId' in args ? args.transformerId : undefined

  useEffect(() => {
    if (loadingRef.current) {
      return
    }

    if ('skip' in args) {
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke(`transformers/${transformerId}`, { method: 'GET' })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = transformerModel.parse(data)

        setTransformer(parsed)
      })
      .catch(handleFetchError('Failed to retrieve transformer'))
      .finally(() => (loadingRef.current = false))

    return () => {
      loadingRef.current = false
      setTransformer(undefined)
    }
  }, [transformerId])

  return {
    data: transformer,
    loading: loadingRef.current
  }
}
