import { TreePanelView } from 'components/TreePanelView/TreePanelView'
import { useSchema } from 'sections/schemas/SchemaProvider'

export const SchemaDetails = () => {
  const { state: schemaState } = useSchema()
  const { schemaModel } = schemaState

  return (
    <TreePanelView title={schemaModel.info.title} schemaModel={schemaModel} />
  )
}
