export const generateLibraries = {
  'rtk-query': 'RTK Query',
  'mui-form': 'MUI Form'
}

export const generateOptions = [
  {
    label: 'RTK Query',
    value: 'rtk-query' as const
  },
  {
    label: 'MUI Form',
    value: 'mui-form' as const
  }
]
