import CardContent from '@mui/joy/CardContent'
import Typography from '@mui/joy/Typography'
import { WorkflowModel } from '@schematicos/types'
import { parseISO } from 'date-fns/parseISO'
import { format } from 'date-fns/format'
import { ViewCard } from 'components/Card/ViewCard'

type ViewWorkflowCardProps = {
  workflow: WorkflowModel
}

export const ViewWorkflowCard = ({ workflow }: ViewWorkflowCardProps) => (
  <ViewCard to={workflow.id}>
    <CardContent>
      <Typography
        level="title-lg"
        sx={{ color: 'neutral.700', fontWeight: 500, fontSize: '16px' }}
      >
        {workflow.name}
      </Typography>

      <Typography
        level="body-sm"
        aria-describedby="card-description"
        sx={{ color: 'neutral.400', fontWeight: 500 }}
      >
        {format(parseISO(workflow.createdAt), 'MMM dd, yyyy')}
      </Typography>
    </CardContent>
  </ViewCard>
)
