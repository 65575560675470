import { useFetchError } from 'components/useFetchError/useFetchError'
import { TransformerModel, transformerModels } from '@schematicos/types'
import { useEffect, useRef, useState } from 'react'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

export const useGetTransformers = () => {
  const { handleFetchError } = useFetchError()
  const [transformers, setTransformers] = useState<TransformerModel[]>()
  const loadingRef = useRef(false)

  useEffect(() => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke('transformers', { method: 'GET' })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = transformerModels.parse(data)

        setTransformers(parsed)
      })
      .catch(handleFetchError('Failed to retrieve transformers'))
      .finally(() => (loadingRef.current = false))
  }, [])

  return {
    data: transformers,
    loading: loadingRef.current
  }
}
