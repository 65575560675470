import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { AddNewCard } from 'components/Card/AddNewCard'
import { DashboardContent } from 'components/Dashboard/DashboardContent'
import { DashboardTop } from 'components/Dashboard/DashboardTop'
import { Spinner } from 'components/Spinner/Spinner'
import { useGetWorkflows } from 'loaders/workflows/useGetWorkflows'
import { WorkflowModels } from '@schematicos/types'
import { match, P } from 'ts-pattern'
import { ViewWorkflowCard } from 'sections/workflows/ViewWorkflowCard'
import { useState } from 'react'
import Modal from '@mui/joy/Modal'
import Sheet from '@mui/joy/Sheet'
import ModalClose from '@mui/joy/ModalClose'
import { SubmitHandler, useForm } from 'react-hook-form'
import Button from '@mui/joy/Button'
import {
  schemaFormModel,
  SchemaSelectForm,
  SchemaFormValues
} from 'components/FormComponents/SchemaSelectForm'
import { useNavigate } from 'react-router-dom'
import { useCreateWorkflow } from 'loaders/workflows/useCreateWorkflow'
import { zodResolver } from '@hookform/resolvers/zod'

export const Workflows = () => {
  const workflowsRes = useGetWorkflows()

  return (
    <>
      <DashboardTop>
        <Typography sx={{ color: 'neutral.600' }}>Workflows</Typography>
      </DashboardTop>
      <DashboardContent sx={{ px: '32px', pt: '40px' }}>
        <Typography level="h2" sx={{ color: 'neutral.700', fontWeight: 500 }}>
          Workflows
        </Typography>
        <Box height="40px" />
        {match(workflowsRes)
          .with({ loading: true }, () => <Spinner />)
          .with({ data: P.array() }, ({ data }) => {
            return <WorkflowsList workflows={data} />
          })

          .otherwise(() => null)}
      </DashboardContent>
    </>
  )
}

type WorkflowsListProps = {
  workflows: WorkflowModels
}

const WorkflowsList = ({ workflows }: WorkflowsListProps) => (
  <Box display="flex" gap="24px" flexWrap="wrap">
    <CreateWorkflow />
    {workflows.map(workflow => (
      <ViewWorkflowCard key={workflow.id} workflow={workflow} />
    ))}
  </Box>
)

const CreateWorkflow = () => {
  const [open, setOpen] = useState(false)

  const { control, handleSubmit } = useForm<SchemaFormValues>({
    defaultValues: {
      schema: null
    },
    resolver: zodResolver(schemaFormModel)
  })

  const navigate = useNavigate()

  const createWorkflowFn = useCreateWorkflow({
    onSuccess: workflow => navigate(`/dashboard/workflows/${workflow.id}`)
  })

  const onSubmit: SubmitHandler<SchemaFormValues> = (data, event) => {
    event?.preventDefault()

    // TODO: Find out if there is a way to set data to
    // be the validated data type rather than initial
    const parsed = schemaFormModel.parse(data)

    createWorkflowFn(parsed.schema)
  }

  return (
    <>
      <AddNewCard
        title="Add workflow"
        onClick={event => {
          event.preventDefault()
          setOpen(true)
        }}
      />
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={open}
        onClose={() => setOpen(false)}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
      >
        <Sheet
          variant="outlined"
          sx={{
            width: 350,
            borderRadius: 'md',
            p: 3,
            boxShadow: 'lg'
          }}
        >
          <ModalClose variant="plain" sx={{ m: 1 }} />
          <Typography
            component="h2"
            id="modal-title"
            level="h4"
            textColor="inherit"
            fontWeight="lg"
            mb={1}
          >
            Create new workflow
          </Typography>
          <form
            onSubmit={event => {
              event.preventDefault()

              void handleSubmit(onSubmit)(event)
            }}
          >
            <Box display="flex" flexDirection="column" gap="16px">
              <SchemaSelectForm control={control} />

              <Button type="submit">Create workflow</Button>
            </Box>
          </form>
        </Sheet>
      </Modal>
    </>
  )
}
