import Box from '@mui/joy/Box'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import FormLabel from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import Typography from '@mui/joy/Typography'
import { RtkQueryRootConfigType, defaultExportPath } from '@schematicos/types'
import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import { SettingsLiveData } from 'sections/composer/types'
import { setRtkQueryImport } from 'sections/composer/settings/mutators'

type RtkQueryRootFormProps = {
  internal$: BehaviorSubject<SettingsLiveData>
}

export const RtkQueryRootForm = ({ internal$ }: RtkQueryRootFormProps) => {
  const [formValues, setFormValues] = useState<RtkQueryRootConfigType>({
    baseApiImport: ''
  })

  useEffect(() => {
    internal$.subscribe(data => {
      const values = data.settingsConfig?.transformers?.['rtk-query']

      setFormValues(current => ({
        baseApiImport: values?.baseApiImport ?? current.baseApiImport
      }))
    })
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            lineHeight: '16px',
            fontSize: '12px',
            fontWeight: '500',
            color: 'neutral.700',
            textTransform: 'uppercase'
          }}
        >
          RTK Query
        </Typography>
      </Box>

      <FormControl /*disabled={!muiFormEnabled} */>
        <FormLabel>Import name</FormLabel>
        <Input
          placeholder={defaultExportPath}
          value={formValues.baseApiImport}
          size="sm"
          onChange={event => {
            setRtkQueryImport({
              baseApiImport: event.target.value,
              internal$
            })
          }}
        />
        <FormHelperText sx={{ display: 'inline' }}>
          Destination file for the generated model code
        </FormHelperText>
      </FormControl>
    </Box>
  )
}
