import { Control, Controller, UseFormWatch } from 'react-hook-form'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { useGetSchemas } from 'loaders/schemas/useGetSchemas'
import { format } from 'date-fns/format'
import { parseISO } from 'date-fns/parseISO'
import { useEffect } from 'react'
import { z } from 'zod'
import { SchemaModel, schemaModel } from '@schematicos/types'
import FormHelperText from '@mui/joy/FormHelperText'
import Typography from '@mui/joy/Typography'
import Autocomplete from '@mui/joy/Autocomplete'
import AutocompleteOption from '@mui/joy/AutocompleteOption'

export const schemaFormModel = z.object({
  schema: schemaModel.required()
})

type SchemaFormModel = z.infer<typeof schemaFormModel>

type FollowProps = {
  watch: UseFormWatch<SchemaFormModel>
  handleChange: (value: SchemaFormModel) => void
}

type SchemaSelectFormProps = {
  control: Control<SchemaFormValues, any>
  follow?: FollowProps
}

export type SchemaFormValues = {
  schema: SchemaModel | null
}

export const SchemaSelectForm = ({
  control,
  follow
}: SchemaSelectFormProps) => {
  const { data: availableSchemas } = useGetSchemas()

  useEffect(() => {
    if (!follow) {
      return
    }

    const { watch, handleChange } = follow

    const subscription = watch(value => {
      const parsed = schemaFormModel.parse(value)

      handleChange(parsed)
    })
    return () => subscription.unsubscribe()
  }, [follow])

  return (
    <Controller
      name="schema"
      control={control}
      render={({ field: { onChange, value: v, ...field }, fieldState }) => {
        const message = fieldState.error?.message
        const customMessage = errorMessageMap[message ?? ''] ?? message

        return (
          <FormControl sx={{ flex: 1 }} error={fieldState.invalid}>
            <FormLabel>Schema</FormLabel>

            <Autocomplete
              {...field}
              value={v}
              onChange={(_, selectedSchema) => onChange(selectedSchema)}
              size="sm"
              placeholder="Choose one…"
              className="nodrag"
              isOptionEqualToValue={(option, value) => option.id === value.id}
              options={availableSchemas ?? []}
              slotProps={{ listbox: { sx: { py: '2px' } } }}
              getOptionLabel={option => option.name}
              renderOption={(props, option) => {
                const updatedAtDate = parseISO(option.updatedAt)
                const updatedAt = format(updatedAtDate, 'yyyy-MM-dd HH:mm')

                return (
                  <AutocompleteOption
                    {...props}
                    sx={{
                      margin: '2px 4px',
                      borderRadius: '4px',
                      flexDirection: 'column',
                      alignItems: 'initial',
                      gap: 0
                    }}
                  >
                    <Typography sx={{ fontSize: '14px' }}>
                      {option.name}
                    </Typography>
                    <Typography
                      sx={{
                        color: 'var(--joy-palette-neutral-500)',
                        fontSize: '12px'
                      }}
                    >
                      {updatedAt}
                    </Typography>
                  </AutocompleteOption>
                )
              }}
            />
            {customMessage ? (
              <FormHelperText>{customMessage}</FormHelperText>
            ) : null}
          </FormControl>
        )
      }}
    />
  )
}

const errorMessageMap: Record<string, string> = {
  'Expected object, received null': 'Please select a schema'
}
