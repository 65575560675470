import Typography from '@mui/joy/Typography'
import { ReactNode } from 'react'

type SectionTitleProps = {
  children: ReactNode
}

export const SectionTitle = ({ children }: SectionTitleProps) => (
  <Typography
    fontSize="12px"
    fontWeight={500}
    sx={{
      color: 'neutral.400',
      textTransform: 'uppercase'
    }}
  >
    {children}
  </Typography>
)
