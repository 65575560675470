import { Navigate, useLocation } from 'react-router-dom'
import { useApp } from 'components/AppProvider/AppProvider'
import { ReactNode } from 'react'

type ProtectedRouteProps = {
  children: ReactNode
}

export const ProtectedRoute = ({ children }: ProtectedRouteProps) => {
  const { state } = useApp()
  const { session } = state

  const comeback = useLocation()

  if (!session) {
    return <Navigate to="/login" state={{ comeback }} />
  }

  return children
}
