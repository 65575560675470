import Box from '@mui/joy/Box'
import FormControl from '@mui/joy/FormControl'
import FormHelperText from '@mui/joy/FormHelperText'
import FormLabel from '@mui/joy/FormLabel'
import Input from '@mui/joy/Input'
import { defaultExportPath } from '@schematicos/types'
import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs'
import { SettingsLiveData } from 'sections/composer/types'
import { setSharedExportPath } from 'sections/composer/settings/mutators'

type SharedRootConfigProps = {
  internal$: BehaviorSubject<SettingsLiveData>
}

type SharedFormState = {
  exportPath: string
}

export const SharedRootConfig = ({ internal$ }: SharedRootConfigProps) => {
  const [formValues, setFormValues] = useState<SharedFormState>({
    exportPath: ''
  })

  useEffect(() => {
    internal$.subscribe(data => {
      setFormValues(() => ({
        exportPath: data.settingsConfig?.exportPath ?? defaultExportPath
      }))
    })
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <FormControl /*disabled={!muiFormEnabled} */>
        <FormLabel>Export path</FormLabel>
        <Input
          placeholder={defaultExportPath}
          value={formValues.exportPath}
          size="sm"
          onChange={event => {
            setSharedExportPath({
              exportPath: event.target.value,
              internal$
            })
          }}
        />
        <FormHelperText sx={{ display: 'inline' }}>
          Destination file for the generated model code
        </FormHelperText>
      </FormControl>
    </Box>
  )
}
