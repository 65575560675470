import Box from '@mui/joy/Box'
import { useEffect, useRef } from 'react'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import CodeMirror, { ReactCodeMirrorRef } from '@uiw/react-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import { useTransformer } from 'sections/transformers/TransformerProvider'
import { DashboardTop } from 'components/Dashboard/DashboardTop'
import { DashboardContent } from 'components/Dashboard/DashboardContent'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import Link from '@mui/joy/Link'
import { Link as RouterLink } from 'react-router-dom'
import { Editable } from 'components/Editable/Editable'
import { PathsEditor } from 'components/Paths/PathsEditor'

export const TransformerEditor = () => {
  // TODO: Figure out way to reselect artifact path when
  // its name changes. At the moment, the ui element remains
  // selected, but the content is not displayed since original
  // key is no longer there

  const { state, dispatch } = useTransformer()
  const { transformer, selectedFilePath, newFileSelected } = state
  const { content } = transformer
  const editorRef = useRef<ReactCodeMirrorRef>(null)

  useEffect(() => {
    if (newFileSelected && selectedFilePath) {
      editorRef.current?.view?.focus()
    }
  }, [newFileSelected, selectedFilePath])

  useEffect(() => {
    // if there is no selected artifact path or
    // an artifacrt path is already selected, do nothing
    if (!content || selectedFilePath) {
      return
    }

    const artifactPaths = Object.keys(content)

    if (artifactPaths.length > 0) {
      dispatch({
        type: 'selectFilePath',
        payload: { filePath: artifactPaths[0], newFileSelected: false }
      })
    }
  }, [])

  const breadcrumbs = [
    { label: 'Transformers', link: '/dashboard/transformers' }
  ]

  return (
    <>
      <DashboardTop>
        <Breadcrumbs aria-label="breadcrumbs" sx={{ p: 0 }}>
          {breadcrumbs?.map(({ link, label }) => (
            <Link
              key={`${link}${label}`}
              component={RouterLink}
              color="neutral"
              to={link}
            >
              {label}
            </Link>
          ))}
          <Editable
            multipleValues={false}
            onChange={name => {
              return dispatch({ type: 'renameTransformer', payload: { name } })
            }}
          >
            {transformer.name}
          </Editable>
        </Breadcrumbs>
      </DashboardTop>

      <DashboardContent>
        <PanelGroup direction="horizontal">
          <Panel id="export-paths-panel" minSize={5} defaultSize={15} order={1}>
            <PathsEditor parentLabel="Transformer code" contentMap={content} />
          </Panel>
          <PanelResizeHandle
            tabIndex={-1}
            style={{
              width: '2px',
              height: '100%',
              background: 'var(--joy-palette-neutral-200, #DDE7EE)'
            }}
          />
          <Panel minSize={25} order={2}>
            <Box
              display="flex"
              height="100%"
              minHeight={0}
              sx={{ overflowY: 'scroll', fontSize: '14px' }}
            >
              <CodeMirror
                ref={editorRef}
                height="auto"
                autoFocus
                // editable={Boolean(selectedFilePath)}
                value={content[selectedFilePath ?? ''] ?? ''}
                extensions={[javascript({ jsx: true, typescript: true })]}
                onChange={fileContent => {
                  if (!selectedFilePath) {
                    return
                  }

                  return dispatch({
                    type: 'updateTransformerContent',
                    payload: { filePath: selectedFilePath, fileContent }
                  })
                }}
              />
            </Box>
          </Panel>
        </PanelGroup>
      </DashboardContent>
    </>
  )
}
