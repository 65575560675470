import { WorkflowModel } from '@schematicos/types'
import { useFetchError } from 'components/useFetchError/useFetchError'
import { createWorkflow } from 'loaders/workflows/createWorkflow'
import { useRef } from 'react'

type UseCreateWorkflowProps = {
  onSuccess: (workflow: WorkflowModel) => void
}

export const useCreateWorkflow = ({ onSuccess }: UseCreateWorkflowProps) => {
  const { handleFetchError } = useFetchError()
  const loadingRef = useRef(false)

  const createWorkflowFn = createWorkflow({
    loadingRef,
    onSuccess,
    onError: handleFetchError
  })

  return createWorkflowFn
}
