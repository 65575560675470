import { WorkflowNode } from '@schematicos/types'

type CreateLookArgs = {
  nodeType: WorkflowNode['type']
  selected: boolean
}

export const createLook = ({ nodeType, selected }: CreateLookArgs) => {
  switch (nodeType) {
    case 'open-schema':
    case 'schema':
      return `linear-gradient(90deg, hsla(225.9, 70.7%, 40.2%, ${selected ? 1 : 0.4}), hsla(198.6, 88.7%, 48.4%, ${selected ? 1 : 0.4}))`

    case 'export':
      return `linear-gradient(90deg, hsla(142.8, 64.2%, 24.1%, ${selected ? 1 : 0.4}), hsla(172.5, 66%, 50.4%, ${selected ? 1 : 0.4}))`

    case 'generate':
      return `linear-gradient(90deg, hsla(26, 90.5%, 37.1%, ${selected ? 1 : 0.4}), hsla(27, 96%, 61%, ${selected ? 1 : 0.4}))`

    case 'settings':
      return `linear-gradient(90deg, hsla(0, 70%, 35.3%, ${selected ? 1 : 0.4}), hsla(349.7, 89.2%, 60.2%,  ${selected ? 1 : 0.4}))`

    case 'artifacts':
      return `linear-gradient(90deg, hsla(263.4, 69.3%, 42.2%, ${selected ? 1 : 0.4}), hsla(293.4, 69.5%, 48.8%,  ${selected ? 1 : 0.4}))`

    default:
      throw new Error(`Unknown node type: ${nodeType}`)
  }
}

export const getMainColor = ({ nodeType, selected }: CreateLookArgs) => {
  switch (nodeType) {
    case 'open-schema':
    case 'schema':
      return `hsla(225.9, 70.7%, 40.2%, ${selected ? 1 : 0.4})`
    case 'export':
      return `hsla(142.8, 64.2%, 24.1%, ${selected ? 1 : 0.4})`
    case 'generate':
      return `hsla(26, 90.5%, 37.1%, ${selected ? 1 : 0.4})`
    case 'settings':
      return `hsla(0, 70%, 35.3%, ${selected ? 1 : 0.4})`
    case 'artifacts':
      return `hsla(263.4, 69.3%, 42.2%, ${selected ? 1 : 0.4})`
    default:
      throw new Error(`Unknown node type: ${nodeType}`)
  }
}

export const getHighlightColor = (nodeType: WorkflowNode['type']) => {
  switch (nodeType) {
    case 'open-schema':
    case 'schema':
      return `hsla(198.6, 88.7%, 48.4%, 0.04)`
    case 'export':
      return `hsla(172.5, 66%, 50.4%, 0.04)`
    case 'generate':
      return `hsla(27, 96%, 61%, 0.04)`
    case 'settings':
      return `hsla(349.7, 89.2%, 60.2%, 0.04)`
    case 'artifacts':
      return `hsla(293.4, 69.5%, 48.8%, 0.04)`
    default:
      throw new Error(`Unknown node type: ${nodeType}`)
  }
}
