import Box from '@mui/joy/Box'
import { ReactNode } from 'react'

type LeftHalfProps = {
  children: ReactNode
}

export const LeftHalf = ({ children }: LeftHalfProps) => (
  <Box display="flex" bgcolor="background.level1">
    <Box
      display="flex"
      flexDirection="column"
      width="50vw"
      height="100vh"
      bgcolor="white"
      px="24px"
      py="100px"
      pb="24px"
      boxShadow="lg"
      borderRight="1px solid"
      sx={{ borderRightColor: 'neutral.200' }}
    >
      {children}
    </Box>
    <Box display="flex" width="50vw" height="100vh"></Box>
  </Box>
)
