import { MutableRefObject } from 'react'
import { SchemaModel, WorkflowModel, workflowModel } from '@schematicos/types'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'
import { nanoid } from 'nanoid'

type CreateWorkflowArgs = {
  loadingRef: MutableRefObject<boolean>
  onError: (title: string) => (error: unknown) => void
  onSuccess: (workflow: WorkflowModel) => void
}

export const createWorkflow =
  ({ loadingRef, onError, onSuccess }: CreateWorkflowArgs) =>
  (schema: SchemaModel) => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke('workflows', {
        method: 'POST',
        body: {
          name: `${schema.name} workflow`,
          content: {
            nodes: [
              {
                id: nanoid(),
                type: 'schema',
                position: { x: 200, y: 150 },
                data: {
                  schemaId: schema.id
                }
              }
            ],
            edges: []
          }
        }
      })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = workflowModel.parse(data)

        onSuccess(parsed)
      })
      .catch(onError('Failed to create workflow'))
      .finally(() => (loadingRef.current = false))
  }
