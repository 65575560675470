import { GenerateLibraryType, Method, OasRoot } from '@schematicos/types'
import { produce } from 'immer'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { SettingsLiveData } from 'sections/composer/types'
import invariant from 'tiny-invariant'
import set from 'lodash/set'

type ToggleModelArgs = {
  modelName: string
  internal$: BehaviorSubject<SettingsLiveData>
}

export const toggleModel = ({ modelName, internal$ }: ToggleModelArgs) => {
  const value = internal$.getValue()

  const nextValue = produce(value, draft => {
    invariant(draft.settingsConfig, 'settingsConfig is undefined')

    const selected = draft.settingsConfig.components.models[modelName].selected

    set(
      draft,
      `settingsConfig.components.models.${modelName}.selected`,
      !selected
    )
  })

  internal$.next(nextValue)
}

type ToggleOperationArgs = {
  path: string
  method: Method
  transformers: GenerateLibraryType[]
  selected: boolean
  internal$: BehaviorSubject<SettingsLiveData>
}

export const toggleOperation = ({
  path,
  method,
  transformers,
  selected,
  internal$
}: ToggleOperationArgs) => {
  const value = internal$.getValue()

  const nextValue = produce(value, draft => {
    transformers.forEach(library => {
      set(
        draft,
        `settingsConfig.operations.${path}.${method}.${library}.selected`,
        selected
      )
    })
  })

  internal$.next(nextValue)
}

type ToggleAllArgs = {
  schemaModel: OasRoot
  transformers: GenerateLibraryType[]
  selectAll: boolean
  internal$: BehaviorSubject<SettingsLiveData>
}

export const toggleAll = ({
  schemaModel,
  transformers,
  selectAll,
  internal$
}: ToggleAllArgs) => {
  const value = internal$.getValue()

  const nextValue = produce(value, draft => {
    Object.keys(schemaModel.components?.models ?? {}).forEach(modelName => {
      invariant(draft.settingsConfig, 'settingsConfig is undefined')

      set(
        draft,
        `settingsConfig.components.models.${modelName}.selected`,
        selectAll
      )
    })

    schemaModel.operations.forEach(({ path, method }) => {
      transformers.forEach(library => {
        set(
          draft,
          `settingsConfig.operations.${path}.${method}.${library}.selected`,
          selectAll
        )
      })
    })
  })

  internal$.next(nextValue)
}

type SetUpdateOperationArgs = {
  type: 'property'
  path: string
  method: Method
  library: GenerateLibraryType
  propertyName: string
  property: unknown
  internal$: BehaviorSubject<SettingsLiveData>
}

export const updateOperation = ({
  path,
  method,
  library,
  internal$,
  propertyName,
  property
}: SetUpdateOperationArgs) => {
  const value = internal$.getValue()

  const nextValue = produce(value, draft => {
    set(
      draft,
      `settingsConfig.operations.${path}.${method}.${library}.${propertyName}`,
      property
    )
  })

  internal$.next(nextValue)
}

type SetModelExportPathArgs = {
  modelName: string
  exportPath: string
  internal$: BehaviorSubject<SettingsLiveData>
}

export const setModelExportPath = ({
  modelName,
  exportPath,
  internal$
}: SetModelExportPathArgs) => {
  const value = internal$.getValue()

  const nextValue = produce(value, draft => {
    set(
      draft,
      `settingsConfig.components.models.${modelName}.exportPath`,
      exportPath === '' ? undefined : exportPath
    )
  })

  internal$.next(nextValue)
}

type SetRtkQueryImportArgs = {
  baseApiImport: string
  internal$: BehaviorSubject<SettingsLiveData>
}

export const setRtkQueryImport = ({
  baseApiImport,
  internal$
}: SetRtkQueryImportArgs) => {
  const value = internal$.getValue()

  const nextValue = produce(value, draft => {
    set(draft, `settingsConfig.transformers['rtk-query']`, { baseApiImport })
  })

  internal$.next(nextValue)
}

type SetSharedExportPathArgs = {
  exportPath: string
  internal$: BehaviorSubject<SettingsLiveData>
}

export const setSharedExportPath = ({
  exportPath,
  internal$
}: SetSharedExportPathArgs) => {
  const value = internal$.getValue()

  const nextValue = produce(value, draft => {
    set(
      draft,
      `settingsConfig.exportPath`,
      exportPath === '' ? undefined : exportPath
    )
  })

  internal$.next(nextValue)
}
