import { useEffect, useState } from 'react'
import { Observable } from 'rxjs/internal/Observable'
import { Subscription } from 'rxjs/internal/Subscription'

export const useInboundObservable = <T extends Record<string, unknown>>(
  inbound$: Observable<T>
) => {
  const [subscription, setSubscription] = useState<Subscription>()
  const [actionResult, setActionResult] = useState<T | null>(null)

  useEffect(() => {
    const newSubscription = inbound$.subscribe({
      next: value => {
        setActionResult(value)
      }
    })

    setSubscription(newSubscription)

    return () => {
      subscription?.unsubscribe()
    }
  }, [inbound$])

  return actionResult
}
