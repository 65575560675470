import { useApp } from 'components/AppProvider/AppProvider'
import * as Sentry from '@sentry/react'
import { nanoid } from 'nanoid'

export const useFetchError = () => {
  const { dispatch } = useApp()

  return {
    handleFetchError: (title: string) => (error: unknown) => {
      dispatch({
        type: 'addNotification',
        payload: {
          id: nanoid(),
          type: 'error',
          title
        }
      })

      console.error(error)

      Sentry.captureException(error)
    }
  }
}
