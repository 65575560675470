import { Spinner } from 'components/Spinner/Spinner'
import { useGetSchema } from 'loaders/schemas/useGetSchema'
import { useParse } from 'loaders/useParse'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { SchemaProvider } from 'sections/schemas/SchemaProvider'

type SchemaLoaderProps = {
  children: ReactNode
}

export const SchemaLoader = ({ children }: SchemaLoaderProps) => {
  const { schemaId } = useParams()

  const { data: schemaDocumentData } = useGetSchema(
    schemaId ? { schemaId } : { skip: true }
  )

  const { data: schemaModelData } = useParse(
    schemaDocumentData
      ? {
          schemaDocument: schemaDocumentData.content,
          schemaFormat: schemaDocumentData.schemaFormat
        }
      : { skip: true }
  )

  return schemaModelData ? (
    <SchemaProvider schemaModel={schemaModelData}>{children}</SchemaProvider>
  ) : (
    <Spinner />
  )
}
