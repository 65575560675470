import Typography from '@mui/joy/Typography'
import { DashboardContent } from 'components/Dashboard/DashboardContent'
import { DashboardTop } from 'components/Dashboard/DashboardTop'

export const DashboardHome = () => (
  <>
    <DashboardTop>
      <Typography sx={{ color: 'neutral.600' }}>Home</Typography>
    </DashboardTop>
    <DashboardContent sx={{ px: '32px', pt: '40px' }}>
      <Typography level="h2" sx={{ color: 'neutral.700', fontWeight: 500 }}>
        API dashboard overview
      </Typography>
    </DashboardContent>
  </>
)
