import Box from '@mui/joy/Box'
import { ReactNode, memo, useEffect } from 'react'
import { Handle, Position, useStoreApi } from 'reactflow'
import { match } from 'ts-pattern'

type HandleType = 'target' | 'source'

type NodeContainerProps = {
  handles?: HandleType[]
  selected: boolean
  children: ReactNode
  look: string
  highlightColor: string
  autoSelect?: {
    nodeId: string
  }
}

export const NodeContainer = memo(
  ({
    handles = ['target', 'source'],
    selected,
    children,
    autoSelect,
    look,
    highlightColor
  }: NodeContainerProps) => {
    const store = useStoreApi()
    const { addSelectedNodes } = store.getState()

    useEffect(() => {
      if (autoSelect) {
        addSelectedNodes([autoSelect.nodeId])
      }
    }, [])

    return (
      <Box
        display="flex"
        flexDirection="column"
        bgcolor="white"
        boxShadow={
          selected
            ? `var(--joy-shadowRing, 0 0 #000),
    0px 2px 8px ${highlightColor},
    0px 2px 8px -2px rgba(var(--joy-shadowChannel, 21 21 21) / 0.04),
    0px 12px 16px ${highlightColor},
    0px 12px 16px -4px rgba(var(--joy-shadowChannel, 21 21 21) / 0.04)
    `
            : `0px 2px 8px -2px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08)),
        0px 12px 16px -4px rgba(var(--joy-shadowChannel, 21 21 21) / var(--joy-shadowOpacity, 0.08))
        `
        }
        borderLeft="1px solid"
        borderBottom="1px solid"
        borderRight="1px solid"
        borderTop="0"
        sx={{
          borderLeftColor: 'neutral.200',
          borderBottomColor: 'neutral.200',
          borderRightColor: 'neutral.200'
        }}
        borderRadius="4px"
        minHeight="86px"
        minWidth="180px"
        maxWidth="230px"
        overflow="hidden"
      >
        <Box
          display="flex"
          height="4px"
          sx={{
            background: look
          }}
        ></Box>
        {handles.map(handle => {
          return match(handle)
            .with('target', matched => (
              <Handle key={matched} type={matched} position={Position.Left} />
            ))
            .with('source', matched => (
              <Handle key={matched} type={matched} position={Position.Right} />
            ))
            .exhaustive()
        })}

        {children}
      </Box>
    )
  }
)
