import Typography from '@mui/joy/Typography'
import IconButton from '@mui/joy/IconButton'
import { FC, useState } from 'react'
import Input from '@mui/joy/Input'
import { PencilIcon } from 'icons/PencilIcon'

type EditableProps = {
  multipleValues?: boolean
  fontSize?: string
  fontWeight?: string
  onChange: (value: string) => void
  children: string
}

export const Editable: FC<EditableProps> = ({
  multipleValues,
  fontSize,
  fontWeight,
  children,
  onChange
}) => {
  const [editing, setEditing] = useState(false)

  return editing ? (
    <Input
      value={children}
      onChange={event => {
        onChange(event.target.value)
      }}
      onBlur={() => {
        setEditing(false)
      }}
    />
  ) : (
    <Typography
      fontSize={fontSize}
      fontWeight={fontWeight}
      endDecorator={
        <IconButton
          onClick={() => setEditing(true)}
          sx={{ color: 'neutral.400', minWidth: '16px', minHeight: '16px' }}
        >
          <PencilIcon width="16px" height="16px" />
        </IconButton>
      }
    >
      {multipleValues ? 'Multiple values' : children}
    </Typography>
  )
}
