import { SelectedItem } from 'sections/composer/settings/types'
import Box from '@mui/joy/Box'
import {
  SettingsConfigType,
  GenerateConfigType,
  OasRoot
} from '@schematicos/types'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { SettingsLiveData } from 'sections/composer/types'
import { ModelSettingsConfig } from 'sections/composer/settings/ModelSettingsConfig'
import { OperationSettingsConfig } from 'sections/composer/settings/OperationSettingsConfig'
import { RootSettingsConfig } from 'sections/composer/settings/RootSettingsConfig'

type SettingsDrawerContentItemProps = {
  schemaModel: OasRoot
  selectedNodes: SelectedItem[]
  settingsConfig: SettingsConfigType
  generateConfig?: GenerateConfigType
  internal$: BehaviorSubject<SettingsLiveData>
}

export const SettingsDrawerContentItem = ({
  schemaModel,
  selectedNodes,
  settingsConfig,
  generateConfig,
  internal$
}: SettingsDrawerContentItemProps) => {
  if (
    selectedNodes.length === 1 &&
    selectedNodes[0][0] === 'components' &&
    selectedNodes[0][1] === 'models' &&
    typeof selectedNodes[0][2] === 'string'
  ) {
    return (
      <ModelSettingsConfig
        modelName={selectedNodes[0][2]}
        settingsConfig={settingsConfig}
        internal$={internal$}
      />
    )
  }

  if (
    selectedNodes.length === 1 &&
    selectedNodes[0][0] === 'operations' &&
    typeof selectedNodes[0][1] === 'string' &&
    typeof selectedNodes[0][2] === 'string'
  ) {
    const operation = schemaModel?.operations.find(({ path, method }) => {
      return path === selectedNodes[0][1] && method === selectedNodes[0][2]
    })

    if (!operation) {
      console.log('Operation not found', selectedNodes[0])
      return null
    }

    return (
      <OperationSettingsConfig
        operation={operation}
        generateConfig={generateConfig}
        settingsConfig={settingsConfig}
        internal$={internal$}
      />
    )
  }

  if (selectedNodes.length === 1 && selectedNodes[0][0] === 'root') {
    return (
      <RootSettingsConfig
        generateConfig={generateConfig}
        internal$={internal$}
      />
    )
  }

  return <Box display="flex">{selectedNodes.join(', ')}</Box>
}
