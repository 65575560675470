import { useEffect, useState } from 'react'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { Subscription } from 'rxjs/internal/Subscription'

export const useInternalSubject = <T>(internal$: BehaviorSubject<T>) => {
  const current = internal$.getValue()

  const [subscription, setSubscription] = useState<Subscription>()
  const [actionResult, setActionResult] = useState<T>(current)

  useEffect(() => {
    const newSubscription = internal$.subscribe({
      next: value => setActionResult(value)
    })

    setSubscription(newSubscription)

    return () => {
      subscription?.unsubscribe()
    }
  }, [internal$])

  return actionResult
}
