import Box from '@mui/joy/Box'
import Sheet from '@mui/joy/Sheet'
import { Outlet } from 'react-router-dom'

export const Parent = () => (
  <Sheet>
    <Box display="flex" width="100vw" height="100vh" flexDirection="column">
      {/* <Api /> */}
      <Outlet />
    </Box>
  </Sheet>
)
