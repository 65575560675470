import { TransformerModel } from '@schematicos/types'
import { useFetchError } from 'components/useFetchError/useFetchError'
import { createTransformer } from 'loaders/transformers/createTransformer'
import { useRef } from 'react'

type UseCreateTransformerProps = {
  onSuccess: (transformer: TransformerModel) => void
}

export const useCreateTransformer = ({
  onSuccess
}: UseCreateTransformerProps) => {
  const { handleFetchError } = useFetchError()
  const loadingRef = useRef(false)

  const createTransformerFn = createTransformer({
    loadingRef,
    onSuccess,
    onError: handleFetchError
  })

  return createTransformerFn
}
