import Box from '@mui/joy/Box'
import { SxProps } from '@mui/joy/styles/types'
import { ReactNode } from 'react'

type DashboardContentProps = {
  sx?: SxProps
  children: ReactNode
}

export const DashboardContent = ({ sx, children }: DashboardContentProps) => (
  <Box
    display="flex"
    flexDirection="column"
    flex={1}
    borderTop="1px solid"
    borderLeft="1px solid"
    borderColor="neutral.300"
    minHeight={0}
    sx={{
      borderTopLeftRadius: '6px',
      bgcolor: 'background.surface',
      boxShadow: 'md',
      ...sx
    }}
  >
    {children}
  </Box>
)
