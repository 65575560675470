import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { AddNewCard } from 'components/Card/AddNewCard'
import { DashboardContent } from 'components/Dashboard/DashboardContent'
import { DashboardTop } from 'components/Dashboard/DashboardTop'
import { ViewTransformerCard } from 'sections/transformers/ViewTransformerCard'
import { Spinner } from 'components/Spinner/Spinner'
import { useGetTransformers } from 'loaders/transformers/useGetTransformers'
import { TransformerModels } from '@schematicos/types'
import { Outlet, useNavigate } from 'react-router-dom'
import { match, P } from 'ts-pattern'
import { useCreateTransformer } from 'loaders/transformers/useCreateTransformer'

export const Transformers = () => {
  const transformersRes = useGetTransformers()

  return (
    <>
      <DashboardTop>
        <Typography sx={{ color: 'neutral.600' }}>Transformers</Typography>
      </DashboardTop>
      <DashboardContent sx={{ px: '32px', pt: '40px' }}>
        <Typography level="h2" sx={{ color: 'neutral.700', fontWeight: 500 }}>
          Transformers
        </Typography>
        <Box height="40px" />
        {match(transformersRes)
          .with({ loading: true }, () => <Spinner />)
          .with({ data: P.array() }, ({ data }) => {
            return <TransformersList transformers={data} />
          })
          .otherwise(() => null)}
      </DashboardContent>
      <Outlet />
    </>
  )
}

type TransformersListProps = {
  transformers: TransformerModels
}

const TransformersList = ({ transformers }: TransformersListProps) => {
  const navigate = useNavigate()

  const createTransformerFn = useCreateTransformer({
    onSuccess: transformer => {
      navigate(`/dashboard/transformers/${transformer.id}`)
    }
  })

  return (
    <Box display="flex" gap="24px" flexWrap="wrap">
      <AddNewCard
        title="Add transformer"
        onClick={event => {
          event.preventDefault()

          createTransformerFn()
        }}
      />
      {transformers.map(transformer => (
        <ViewTransformerCard key={transformer.id} transformer={transformer} />
      ))}
    </Box>
  )
}
