import { AppState } from 'components/types'

export type SetExportModalAction = {
  type: 'setExportModal'
  payload: boolean
}

export const setExportModal =
  (state: AppState) =>
  ({ payload: exportModalOpen }: SetExportModalAction): AppState => {
    return {
      ...state,
      exportModalOpen
    }
  }
