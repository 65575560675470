import {
  TreeItem as JoyTreeItem,
  TreeItemContent,
  TreeItemContentProps
} from '@mui/x-tree-view/TreeItem'
import { FC, JSXElementConstructor, ReactNode } from 'react'
import { Body } from 'components/Text/Body'

type TreeItemProps = {
  nodeId: string
  icon?: ReactNode
  children?: ReactNode
  label: ReactNode
  ContentComponent?: JSXElementConstructor<TreeItemContentProps>
}

export const TreeItem: FC<TreeItemProps> = ({
  nodeId,
  children,
  label,
  icon,
  ContentComponent = TreeItemContent
}) => (
  <JoyTreeItem
    nodeId={nodeId}
    label={label}
    icon={icon}
    ContentComponent={ContentComponent}
    sx={{
      width: '100%'
    }}
  >
    {children}
  </JoyTreeItem>
)

type TreeItemLabelProps = {
  voidItem?: boolean
  children: ReactNode
}

export const TreeItemLabel = ({ children, voidItem }: TreeItemLabelProps) => (
  <Body
    sx={{ lineHeight: '36px', fontSize: 'sm', opacity: voidItem ? 0.5 : 1 }}
  >
    {children}
  </Body>
)
