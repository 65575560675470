import Autocomplete from '@mui/joy/Autocomplete'
import Chip from '@mui/joy/Chip'
import CircularProgress from '@mui/joy/CircularProgress'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Typography from '@mui/joy/Typography'
import { GithubSourceFormValues, PathChunk } from '@schematicos/types'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'
import { useFetchError } from 'components/useFetchError/useFetchError'

type UNSAFE_RepoContent = {
  name: string
  path: string
  type: 'file' | 'dir'
}

const FIRST_PATH_CHUNK: PathChunk = {
  name: '.',
  type: 'dir'
}

export const GithubPaths = () => {
  const { control, watch, setValue } = useFormContext<GithubSourceFormValues>()
  const { handleFetchError } = useFetchError()
  const loadingRef = useRef(false)
  const [inputValue, setInputValue] = useState('')
  const [content, setContent] = useState<Record<string, UNSAFE_RepoContent[]>>(
    {}
  )
  const [repo, path] = watch(['repo', 'path'])
  const lastPathChunk: PathChunk = path?.slice(-1).at(0) ?? FIRST_PATH_CHUNK

  useEffect(() => {
    setValue('path', [FIRST_PATH_CHUNK])
    setContent({})
  }, [repo])

  useEffect(() => {
    setValue('content', '')
  }, [repo, lastPathChunk])

  useEffect(() => {
    if (
      loadingRef.current ||
      !repo ||
      lastPathChunk.type === 'dir' ||
      content[lastPathChunk.name]
    ) {
      return
    }

    loadingRef.current = true

    supabaseClient.functions
      .invoke(
        `github/repos/${repo}/contents/${path
          .map(({ name }) => name)
          .join('/')}?format=raw`,
        {
          method: 'GET'
        }
      )
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const { pathContents } = data

        if (typeof pathContents === 'string') {
          setValue('content', pathContents)
        }
      })
      .catch(handleFetchError('Failed to retrieve repo content'))
      .finally(() => (loadingRef.current = false))
  }, [repo, lastPathChunk])

  useEffect(() => {
    if (
      loadingRef.current ||
      !repo ||
      lastPathChunk.type === 'file' ||
      content[lastPathChunk.name]
    ) {
      return
    }

    loadingRef.current = true

    supabaseClient.functions
      .invoke(
        `github/repos/${repo}/contents/${path
          .map(({ name }) => name)
          .join('/')}`,
        {
          method: 'GET'
        }
      )
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        if (data.repoPaths) {
          setContent(currentContent => ({
            ...currentContent,
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            [lastPathChunk.name]: data.repoPaths
          }))
        }
      })
      .catch(handleFetchError('Failed to retrieve repo content'))
      .finally(() => (loadingRef.current = false))
  }, [repo, lastPathChunk])

  if (!repo) {
    return null
  }

  const options = Array.isArray(content[lastPathChunk.name])
    ? content[lastPathChunk.name].map(({ name, type }) => ({
        name,
        type
      }))
    : []

  return (
    <Controller
      name="path"
      control={control}
      render={({ field: { onChange, ...field } }) => (
        <FormControl sx={{ py: '8px', px: '12px' }}>
          <FormLabel>Path to OpenAPI file</FormLabel>

          <Autocomplete
            {...field}
            multiple
            size="sm"
            onChange={(_, value) => onChange(value)}
            inputValue={inputValue}
            onInputChange={(_, value) => {
              const chunks = value.trim().split('/')

              if (chunks.length === 1) {
                return setInputValue(value)
              }

              const pathChunks = scrubPath(chunks).map(name => ({
                name,
                type: 'unknown'
              }))

              onChange(pathChunks)

              setInputValue('')
            }}
            placeholder="Path to OpenAPI file…"
            options={options}
            getOptionLabel={option => option.name}
            renderTags={pathChunks => {
              return pathChunks.map((pathChunk, index) => {
                if (index === 0) {
                  return null
                }

                const isDir = pathChunk.type === 'dir'
                const notLast = index !== pathChunks.length - 1

                return (
                  <Fragment key={index}>
                    <Chip variant="soft" color="neutral">
                      {pathChunk.name}
                    </Chip>
                    {isDir || notLast ? <Typography>/</Typography> : null}
                  </Fragment>
                )
              })
            }}
            loading={loadingRef.current}
            endDecorator={
              loadingRef.current ? (
                <CircularProgress
                  size="sm"
                  sx={{ bgcolor: 'background.surface' }}
                />
              ) : null
            }
          />
        </FormControl>
      )}
    />
  )
}

const scrubPath = (path: string[]) => {
  // If first chunk is empty, replace it with '.
  if (path[0] === '') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_ignore, ...rest] = path
    return ['.', ...rest]
    // If first chunk is not '.', then prepend '.'
  } else if (path[0] !== '.') {
    return ['.', ...path]
  } else {
    return path
  }
}
