import { memo, useEffect } from 'react'
import { NodeProps } from 'reactflow'
import Typography from '@mui/joy/Typography'
import { NodeContainer } from 'sections/composer/shared/NodeContainer'
import Box from '@mui/joy/Box'
import { ArtifactsIcon } from 'icons/ArtifactsIcon'
import { ArtifactsMapModel, WorkflowNode } from '@schematicos/types'
import { ArtifactsLiveData, NodeAction } from 'sections/composer/types'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { useWorkflow } from 'sections/composer/WorkflowProvider'
import { artifactsActionFn } from 'sections/composer/artifacts/artifactsAction'
import * as Sentry from '@sentry/react'
import { ArtifactsNodeInbound } from 'sections/composer/artifacts/types'
import { useInboundObservable } from 'sections/composer/useInboundObservable'
import {
  createLook,
  getHighlightColor,
  getMainColor
} from 'sections/composer/shared/createLook'

export const ArtifactsNode = memo(
  (node: NodeProps<NodeAction<ArtifactsLiveData>>) => {
    const { data, selected } = node
    const { dispatch } = useWorkflow()
    const inbound = useInboundObservable<ArtifactsNodeInbound>(data.inbound$)
    const { artifactsMap } = useInternalSubject(data.internal$)

    useEffect(() => {
      const workflowNode: WorkflowNode = {
        id: node.id,
        type: 'artifacts',
        position: { x: node.xPos, y: node.yPos },
        selected: node.selected,
        data: {}
      }

      dispatch({
        type: 'updateNode',
        payload: {
          node: workflowNode
        }
      })
    }, [node.xPos, node.yPos, node.selected])

    useEffect(() => {
      const { generateConfig, schemaModel, settingsConfig } = inbound ?? {}

      if (!generateConfig || !schemaModel) {
        return
      }

      artifactsActionFn({ generateConfig, schemaModel, settingsConfig })
        .then(value => {
          data.internal$.next({ artifactsMap: value })
        })
        .catch(error => Sentry.captureException(error))
    }, [inbound])

    return (
      <NodeContainer
        selected={selected}
        look={createLook({ nodeType: 'artifacts', selected })}
        highlightColor={getHighlightColor('artifacts')}
      >
        <Box
          display="flex"
          flex={1}
          sx={{
            color: getMainColor({ nodeType: 'artifacts', selected }),
            padding: '8px',
            gap: '8px'
          }}
        >
          <ArtifactsIcon width="24px" height="24px" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingRight="8px"
          >
            <Typography sx={{ fontSize: 'sm', lineHeight: '16px' }}>
              Artifacts
            </Typography>
            <ArtifactsNodeSummary artifactsMap={artifactsMap} />
          </Box>
        </Box>
      </NodeContainer>
    )
  }
)

type ArtifactsNodeSummaryProps = {
  artifactsMap?: ArtifactsMapModel
}

const ArtifactsNodeSummary = ({ artifactsMap }: ArtifactsNodeSummaryProps) => {
  const artifactCount = Object.keys(artifactsMap ?? {}).length

  const lineCount = Object.values(artifactsMap ?? {}).reduce(
    (acc, artifact) => {
      return artifact?.length ? acc + artifact.split('\n').length : acc
    },
    0
  )

  return (
    <Box display="flex" gap="16px">
      <Box display="flex" gap="2px">
        <Typography
          flex={0}
          fontWeight="500"
          sx={{ color: 'neutral.500', fontSize: 'sm' }}
        >
          {artifactCount}
        </Typography>
        <Typography sx={{ color: 'neutral.400', fontSize: 'sm' }}>
          {artifactCount === 1 ? 'File' : 'Files'}
        </Typography>
      </Box>
      <Box display="flex" gap="2px">
        <Typography
          flex={0}
          fontWeight="500"
          sx={{ color: 'neutral.500', fontSize: 'sm' }}
        >
          {lineCount}
        </Typography>
        <Typography sx={{ color: 'neutral.400', fontSize: 'sm' }}>
          {lineCount === 1 ? 'Line' : 'Lines'}
        </Typography>
      </Box>
    </Box>
  )
}
