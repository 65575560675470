import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { isRef } from 'components/SchemaView/isRef'
import Markdown from 'markdown-to-jsx'
import Link from '@mui/joy/Link'
import { OasOperation, OasParameter } from '@schematicos/types'
import { SectionTitle } from 'sections/schemas/SectionTitle'
import { Badge } from 'components/Badge/Badge'

type CodeProps = {
  children: string
}

const Code = ({ children }: CodeProps) => (
  <Typography
    sx={{
      fontSize: '16px',
      bgcolor: 'neutral.100',
      border: '1px solid',
      p: '2px',
      borderRadius: '4px',
      borderColor: 'neutral.outlinedBorder',
      fontFamily: 'Courier, monospace',
      whiteSpace: 'nowrap'
    }}
  >
    {children}
  </Typography>
)

type ParamsProps = {
  operation: OasOperation
}

export const Params = ({ operation }: ParamsProps) => {
  if (!operation.parameters?.length) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <SectionTitle>Params</SectionTitle>
      <Box display="flex" flexDirection="column" gap="16px">
        <ParamsGroup
          operation={operation}
          title="Query params"
          filterBy="query"
        />

        <ParamsGroup
          operation={operation}
          title="Path params"
          filterBy="path"
        />

        <ParamsGroup
          operation={operation}
          title="Header params"
          filterBy="header"
        />
      </Box>
    </Box>
  )
}

type ParamsGroupProps = {
  title: string
  filterBy: 'query' | 'path' | 'header'
  operation: OasOperation
}

const ParamsGroup = ({ operation, title, filterBy }: ParamsGroupProps) => {
  if (!operation) {
    return null
  }

  const queryParams = operation?.parameters?.filter(
    (item): item is OasParameter => {
      return !isRef(item) && item.location === filterBy
    }
  )

  if (!queryParams?.length) {
    return null
  }

  return (
    <Box display="flex" flexDirection="row" gap="8px">
      <Box display="flex" width="150px">
        <Typography
          fontSize="14px"
          fontWeight={500}
          sx={{
            color: 'neutral.700'
          }}
        >
          {title}
        </Typography>
      </Box>
      <Box display="flex" flexDirection="column" gap="8px">
        {queryParams.map(item => {
          if (isRef(item)) {
            return null
          }

          return (
            <Box display="flex" flexDirection="column" key={item.name}>
              <Box display="flex" gap="16px" alignItems="baseline">
                <Typography
                  sx={{
                    fontWeight: 500,
                    color: 'neutral.700',
                    fontSize: '14px'
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    color: 'neutral.500',
                    fontSize: '14px'
                  }}
                >
                  {item.schema && 'type' in item.schema
                    ? item.schema?.type
                    : 'unknown'}
                </Typography>

                {item.required ? <Badge color="neutral">required</Badge> : null}
              </Box>
              {item.description ? (
                <Typography
                  sx={{
                    color: 'neutral.500',
                    fontSize: '14px'
                  }}
                >
                  <Markdown
                    options={{
                      overrides: {
                        a: Link,
                        code: Code
                      }
                    }}
                  >
                    {item.description}
                  </Markdown>
                </Typography>
              ) : null}
            </Box>
          )
        })}
      </Box>
    </Box>
  )
}
