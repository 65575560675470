import { MutableRefObject } from 'react'
import { TransformerModel, transformerModel } from '@schematicos/types'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

type UpdateTransformerArgs = {
  loadingRef: MutableRefObject<boolean>
  firstCallRef: MutableRefObject<boolean>
  onError: (title: string) => (error: unknown) => void
  onSuccess: (transformer: TransformerModel) => void
}

export const updateTransformer =
  ({ loadingRef, firstCallRef, onError, onSuccess }: UpdateTransformerArgs) =>
  (transformer: TransformerModel) => {
    if (loadingRef.current) {
      return
    }

    // First call is made by useEffect without any
    // changes that need saving
    if (firstCallRef.current) {
      firstCallRef.current = false
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke(`transformers/${transformer.id}`, {
        method: 'PUT',
        body: transformer
      })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = transformerModel.parse(data)

        onSuccess(parsed)
      })
      .catch(onError('Failed to update transformer'))
      .finally(() => (loadingRef.current = false))
  }
