import Box from '@mui/joy/Box'
import { ReactNode } from 'react'

type CodeMirrorBorderProps = {
  children: ReactNode
}

export const CodeMirrorBorder = ({ children }: CodeMirrorBorderProps) => (
  <Box
    display="flex"
    flexDirection="column"
    width="100%"
    minHeight={0}
    sx={{
      p: 0,
      overflowY: 'scroll',
      fontSize: '14px',
      border: '1px solid',
      borderRadius: '4px',
      borderColor: '#CDD7E1',
      flex: 1,
      ':focus-within': {
        '--Textarea-focused': 1,
        '--Textarea-focusedThickness': 'var(--joy-focus-thickness)',
        '--Textarea-focusedHighlight': 'var(--joy-palette-focusVisible)',
        borderColor: 'var(--Textarea-focusedHighlight)',
        outline: '1px solid var(--Textarea-focusedHighlight)'
      }
    }}
  >
    {children}
  </Box>
)
