import Box from '@mui/joy/Box'
import { default as JoyDrawer } from '@mui/joy/Drawer'
import Sheet from '@mui/joy/Sheet'
import { ReactNode, useCallback, useEffect, useRef, useState } from 'react'
import { z } from 'zod'
import * as Sentry from '@sentry/react'

type BottomDrawerProps = {
  open?: boolean
  onClose: () => void
  children: ReactNode
}

export const BottomDrawer = ({
  open = true,
  onClose,
  children
}: BottomDrawerProps) => {
  const [height, setHeight] = useState(getDrawerHeight() ?? 350)
  const draggingRef = useRef(false)
  const startYRef = useRef(0)
  const [moveY, setMoveY] = useState(0)

  useEffect(() => {
    localStorage.setItem(
      'composerDrawer',
      JSON.stringify({ height: height + moveY })
    )
  }, [moveY, height])

  const onMouseMove = useCallback(
    (event: MouseEvent) => {
      if (draggingRef.current) {
        setMoveY(startYRef.current - event.pageY)
      }
    },
    [height]
  )

  const onMouseUp = useCallback(() => {
    draggingRef.current = false
  }, [])

  useEffect(() => {
    if (draggingRef.current === false) {
      document.body.removeEventListener('mousemove', onMouseMove)
      document.body.removeEventListener('mouseup', onMouseUp)
    }
  }, [draggingRef.current, moveY, height])

  return (
    <JoyDrawer
      open={open}
      onClose={onClose}
      anchor="bottom"
      hideBackdrop={true}
      disableEnforceFocus={true}
      disableScrollLock={true}
      slotProps={{
        content: {
          sx: {
            height: `${height + moveY}px`
          }
        }
      }}
    >
      <Sheet
        sx={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflow: 'auto',
          boxShadow: 'lg',
          borderTop: '1px solid',
          borderColor: 'neutral.300',
          bgcolor: 'background.body'
        }}
      >
        <Box
          display="flex"
          flex="none"
          onMouseDown={event => {
            event.preventDefault()

            draggingRef.current = true
            startYRef.current = event.pageY

            setHeight(height + moveY)
            setMoveY(0)

            document.body.addEventListener('mousemove', onMouseMove)
            document.body.addEventListener('mouseup', onMouseUp, { once: true })
          }}
          sx={{
            height: '4px',
            ':hover': {
              bgcolor: 'primary.300',
              cursor: 'ns-resize'
            }
          }}
        />

        {children}
      </Sheet>
    </JoyDrawer>
  )
}

const composerDrawerType = z.object({
  height: z.number()
})

const getDrawerHeight = () => {
  const composerDrawer = localStorage.getItem('composerDrawer')

  if (!composerDrawer) {
    return
  }

  try {
    const json = JSON.parse(composerDrawer)
    const parsed = composerDrawerType.parse(json)

    return parsed.height
  } catch (e) {
    console.error(e)
    Sentry.captureException(e)
  }
}
