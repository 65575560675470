import { default as JoyAvatar } from '@mui/joy/Avatar'
import { useApp } from 'components/AppProvider/AppProvider'

export const Avatar = () => {
  const { state } = useApp()
  const { session } = state

  if (!session) {
    return null
  }

  return (
    <JoyAvatar
      size="sm"
      alt={session.user.user_metadata.full_name}
      src={session.user.user_metadata.avatar_url}
    />
  )
}
