import { FC } from 'react'

type DownloadIconProps = {
  color?: string
  width?: string
  height?: string
  strokeWidth?: number
}

export const DownloadIcon: FC<DownloadIconProps> = ({
  color = 'currentColor',
  width = '24px',
  height = '24px',
  strokeWidth = 1.5
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 24 24"
    stroke={color}
    width={width}
    height={height}
    strokeWidth={strokeWidth}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M3 16.5v2.25A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75V16.5M16.5 12 12 16.5m0 0L7.5 12m4.5 4.5V3"
    />
  </svg>
)
