import { useFormContext } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { useOasVersion } from 'components/ConfigureSource/useOasVersion'
import { useRef } from 'react'
import Button from '@mui/joy/Button'
import { match } from 'ts-pattern'
import { SourceFormValues } from '@schematicos/types'
import Box from '@mui/joy/Box'

export const SchemaFormFooter = () => {
  const { watch } = useFormContext<SourceFormValues>()

  const content = watch('content')

  const navigate = useNavigate()
  const loadingRef = useRef(false)

  const oasVersion = useOasVersion(content)

  if (!content) {
    return null
  }

  return (
    <Box
      p="12px"
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      position="sticky"
      bottom="0"
      borderTop="1px solid"
      borderColor="divider"
      bgcolor="white"
    >
      <Box display="flex" alignItems="center" gap="16px">
        <Button
          variant="plain"
          onClick={() => navigate('..')}
          sx={{ fontWeight: 500 }}
        >
          Cancel
        </Button>

        <Button
          sx={{ fontWeight: 500 }}
          onClick={event => {
            event.preventDefault()

            // saveSchema({
            //   handleFetchError,
            //   loadingRef,
            //   navigate,
            //   initialSchema: getValues()
            // })
          }}
          disabled={
            loadingRef.current ||
            match(oasVersion)
              .with('v2', () => false)
              .with('v3', () => false)
              .otherwise(() => true)
          }
          type="submit"
        >
          Save schema
        </Button>
      </Box>
    </Box>
  )
}
