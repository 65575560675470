import { DrawerSize, DrawerState } from 'components/Drawer/types'
import {
  FC,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useReducer
} from 'react'
import { match } from 'ts-pattern'

type DrawerContextType = {
  state: DrawerState
  dispatch: (action: DrawerAction) => void
}

const DrawerContext = createContext<DrawerContextType | undefined>(undefined)

type SetDrawerSizeAction = {
  type: 'setDrawerSize'
  payload: DrawerSize
}

const setDrawerSize =
  (state: DrawerState) =>
  ({ payload }: SetDrawerSizeAction): DrawerState => ({
    ...state,
    size: payload
  })

type DrawerAction = SetDrawerSizeAction

const initialState: DrawerState = {
  size: 'normal'
}

const DrawerReducer = (
  state: DrawerState,
  action: DrawerAction
): DrawerState => {
  const newState = match(action)
    .with({ type: 'setDrawerSize' }, setDrawerSize(state))
    .exhaustive()

  return newState
}

type DrawerProviderProps = {
  children: ReactNode
}

export const DrawerProvider: FC<DrawerProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<DrawerState, DrawerAction>>(
    DrawerReducer,
    initialState
  )

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  )
}

export const useDrawer = () => {
  const context = useContext(DrawerContext)
  if (context === undefined) {
    throw new Error('useDrawer must be used within a DrawerProvider')
  }
  return context
}
