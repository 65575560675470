import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { Params } from 'sections/schemas/Params'
import { MethodBadge } from 'components/MethodBadge/MethodBadge'
import { useLookupOperation } from 'sections/schemas/useLookupOperation/useLookupOperation'
import { MarkdownDescription } from 'sections/schemas/MarkdownDescription'
import { Responses } from 'sections/schemas/Responses'

export const Operation = () => {
  const operation = useLookupOperation()

  if (!operation) {
    return null
  }

  const { summary, description, path, method, responses } = operation

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      px="32px"
      pt="32px"
      gap="24px"
      height="100%"
      minHeight="0"
      sx={{ overflowY: 'scroll', fontSize: '14px' }}
    >
      <Box display="flex" flexDirection="column" gap="2px">
        <Typography
          sx={{ fontSize: '20px', color: 'neutral.700', fontWeight: 500 }}
        >
          {summary}
        </Typography>
        <Box display="flex" gap="8px" alignItems="center">
          <Typography
            sx={{
              fontSize: '14px',
              color: 'neutral.500',
              letterSpacing: '0.2px'
            }}
          >
            {path}
          </Typography>
          <MethodBadge>{method}</MethodBadge>
        </Box>
      </Box>

      <MarkdownDescription description={description} />

      <Params operation={operation} />

      <Responses responses={responses} />
    </Box>
  )
}
