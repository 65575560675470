import { AppState } from 'components/types'

export type SetExportCodeAction = {
  type: 'setExportCode'
  payload: Record<string, unknown>
}

export const setExportCode =
  (state: AppState) =>
  ({ payload }: SetExportCodeAction): AppState => {
    const { exportFiles, exportModels } = payload
    return {
      ...state,
      exportFiles: exportFiles as Record<string, string> | null,
      exportModels: exportModels as string[]
    }
  }
