import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { OasRoot } from '@schematicos/types'
import { ApiPathsNavigation } from 'components/ApiPaths/ApiPathsNavigation'
import { onNodeSelect } from 'components/ApiPaths/onNodeSelect'
import { DashboardContent } from 'components/Dashboard/DashboardContent'
import { DashboardTop } from 'components/Dashboard/DashboardTop'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { Outlet, useNavigate } from 'react-router-dom'

type TreePanelViewProps = {
  title: string
  schemaModel: OasRoot
}

export const TreePanelView = ({ title, schemaModel }: TreePanelViewProps) => {
  const navigate = useNavigate()

  return (
    <>
      <DashboardTop>
        <Typography sx={{ color: 'neutral.600' }}>{title}</Typography>
      </DashboardTop>

      <DashboardContent>
        <Box
          display="flex"
          flex={1}
          sx={{
            minHeight: 0,
            borderTopLeftRadius: '6px',
            bgcolor: 'background.body'
          }}
        >
          <PanelGroup direction="horizontal">
            <Panel
              id="api-nav-panel"
              minSize={10}
              defaultSize={50}
              maxSize={90}
              order={1}
            >
              <ApiPathsNavigation
                onNodeSelect={onNodeSelect(navigate)}
                schemaModel={schemaModel}
                transformers={[]}
              />
            </Panel>
            <PanelResizeHandle
              style={{
                width: '2px',
                height: '100%',
                background: 'var(--joy-palette-neutral-200, #DDE7EE)'
              }}
            />
            <Panel
              id="api-content-panel"
              minSize={10}
              defaultSize={50}
              order={2}
            >
              <Outlet />
            </Panel>
          </PanelGroup>
        </Box>
      </DashboardContent>
    </>
  )
}
