import Box from '@mui/joy/Box'
import { useEffect, useState } from 'react'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import CodeMirror from '@uiw/react-codemirror'
import { javascript } from '@codemirror/lang-javascript'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { PathsNavigation } from 'components/Paths/PathsNavigation'
import { ArtifactsLiveData } from 'sections/composer/types'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import IconButton from '@mui/joy/IconButton'
import ContentCopyIcon from '@mui/icons-material/ContentCopy'

type ArtifactsDrawerContentProps = {
  internal$: BehaviorSubject<ArtifactsLiveData>
}

export const ArtifactsDrawerContent = ({
  internal$
}: ArtifactsDrawerContentProps) => {
  // TODO: Figure out way to reselect artifact path when
  // its name changes. At the moment, the ui element remains
  // selected, but the content is not displayed since original
  // key is no longer there
  const [selectedArtifactPath, setSelectedArtifactPath] = useState<string>()

  const { artifactsMap } = useInternalSubject(internal$)

  useEffect(() => {
    // if there is no selected artifact path or
    // an artifacrt path is already selected, do nothing
    if (!artifactsMap || selectedArtifactPath) {
      return
    }

    const artifactPaths = Object.keys(artifactsMap)

    if (artifactPaths.length > 0) {
      setSelectedArtifactPath(artifactPaths[0])
    }
  }, [])

  const editorContent =
    artifactsMap && selectedArtifactPath
      ? artifactsMap[selectedArtifactPath]
      : ''

  return (
    <PanelGroup direction="horizontal">
      <Panel id="export-paths-panel" minSize={5} defaultSize={15} order={1}>
        <PathsNavigation
          parentLabel="Export paths"
          contentMap={artifactsMap}
          onNodeSelect={artifactPath => {
            setSelectedArtifactPath(artifactPath)
          }}
        />
      </Panel>
      <Box
        component={PanelResizeHandle}
        sx={{
          width: '2px',
          height: '100%',
          background: 'var(--joy-palette-neutral-200, #DDE7EE)',
          ':hover': {
            bgcolor: 'primary.300'
          }
        }}
      />
      <Panel minSize={25} order={2}>
        <Box
          display="flex"
          height="100%"
          minHeight={0}
          sx={{ overflowY: 'scroll', fontSize: '14px', position: 'relative' }}
        >
          <Box
            sx={{ position: 'absolute', zIndex: 1, top: '10px', right: '10px' }}
          >
            <IconButton
              size="sm"
              onClick={() => void navigator.clipboard.writeText(editorContent)}
              sx={{ color: 'neutral.400' }}
            >
              <ContentCopyIcon />
            </IconButton>
          </Box>
          <CodeMirror
            height="auto"
            value={editorContent}
            editable={false}
            extensions={[javascript({ jsx: true, typescript: true })]}
          />
        </Box>
      </Panel>
    </PanelGroup>
  )
}
