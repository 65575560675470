import {
  FC,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useReducer
} from 'react'
import { match } from 'ts-pattern'
import { AppState } from 'components/types'
import { setExportCode } from 'components/AppProvider/setExportCode'
import { setQuery } from 'components/AppProvider/setQuery'
import { setSession } from 'components/AppProvider/setSession'
import { setExportModal } from 'components/AppProvider/setExportModal'
import { addNotification } from 'components/AppProvider/addNotification'
import { removeNotification } from 'components/AppProvider/removeNotification'
import { AppAction, AppDispatch } from 'components/AppProvider/types'

type AppContextType = {
  state: AppState
  dispatch: AppDispatch
}

const AppContext = createContext<AppContextType | undefined>(undefined)

const initialState: AppState = {
  query: '',
  exportFiles: null,
  exportModels: [],
  completed: [],
  exportModalOpen: false,
  session: null,
  notifications: []
}

const AppReducer = (state: AppState, action: AppAction): AppState => {
  const newState = match(action)
    .with({ type: 'setQuery' }, setQuery(state))
    .with({ type: 'setExportCode' }, setExportCode(state))
    .with({ type: 'setExportModal' }, setExportModal(state))
    .with({ type: 'setSession' }, setSession(state))
    .with({ type: 'addNotification' }, addNotification(state))
    .with({ type: 'removeNotification' }, removeNotification(state))
    .exhaustive()

  return newState
}

type AppProviderProps = {
  children: ReactNode
}

export const AppProvider: FC<AppProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer<Reducer<AppState, AppAction>>(
    AppReducer,
    initialState
  )

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>
}

export const useApp = () => {
  const context = useContext(AppContext)
  if (context === undefined) {
    throw new Error('useApp must be used within a AppProvider')
  }
  return context
}
