import Box from '@mui/joy/Box'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { Controller, useFormContext } from 'react-hook-form'
import CodeMirror, { EditorView } from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
import Typography from '@mui/joy/Typography'
import { match } from 'ts-pattern'
import { useOasVersion } from 'components/ConfigureSource/useOasVersion'
import { CodeMirrorBorder } from 'components/CodeMirror/CodeMirror'
import { SourceFormValues } from '@schematicos/types'

export const DocumentSource = () => {
  const { control, watch } = useFormContext<SourceFormValues>()

  const [sourceType, content] = watch(['sourceType', 'content'])

  const oasVersion = useOasVersion(content)

  const editable = sourceType === 'text'

  if (!content && !editable) {
    return null
  }

  return (
    <Controller
      name="content"
      control={control}
      render={({ field }) => {
        return (
          <FormControl sx={{ minHeight: 0, flex: 1, py: '8px', px: '12px' }}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <FormLabel>OpenAPI schema</FormLabel>
              <Typography
                sx={{
                  color: 'neutral.500',
                  fontSize: 'sm'
                }}
              >
                {match(oasVersion)
                  .with('v2', () => 'OpenAPI 2.0')
                  .with('v3', () => 'OpenAPI 3.0')
                  .with(undefined, () => undefined)
                  .exhaustive()}
              </Typography>
            </Box>
            <CodeMirrorBorder>
              <CodeMirror
                {...field}
                editable={editable}
                minHeight="100%"
                extensions={[json(), EditorView.lineWrapping]}
              />
            </CodeMirrorBorder>
          </FormControl>
        )
      }}
    />
  )
}
