import { Composer } from 'sections/composer/core/Composer'
import { DashboardContent } from 'components/Dashboard/DashboardContent'
import { DashboardTop } from 'components/Dashboard/DashboardTop'
import { useWorkflow } from 'sections/composer/WorkflowProvider'
import { Spinner } from 'components/Spinner/Spinner'
import { ReactFlowProvider } from 'reactflow'
import Breadcrumbs from '@mui/joy/Breadcrumbs'
import Link from '@mui/joy/Link'
import { Editable } from 'components/Editable/Editable'
import { Link as RouterLink } from 'react-router-dom'

export const Workflow = () => {
  const { state, dispatch } = useWorkflow()
  const { workflow } = state

  const breadcrumbs = [{ label: 'Workflows', link: '/dashboard/workflows' }]

  return (
    <>
      <DashboardTop>
        <Breadcrumbs aria-label="breadcrumbs" sx={{ p: 0 }}>
          {breadcrumbs?.map(({ link, label }) => (
            <Link
              key={`${link}${label}`}
              component={RouterLink}
              color="neutral"
              to={link}
            >
              {label}
            </Link>
          ))}
          <Editable
            multipleValues={false}
            onChange={name => {
              return dispatch({ type: 'renameWorkflow', payload: { name } })
            }}
          >
            {workflow.name ?? 'Untitled workflow'}
          </Editable>
        </Breadcrumbs>
      </DashboardTop>

      {workflow ? (
        <DashboardContent>
          <ReactFlowProvider>
            <Composer />
          </ReactFlowProvider>
        </DashboardContent>
      ) : (
        <Spinner />
      )}
    </>
  )
}
