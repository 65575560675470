import Snackbar from '@mui/joy/Snackbar'
import DoneIcon from '@mui/icons-material/Done'
import { useApp } from 'components/AppProvider/AppProvider'
import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { match } from 'ts-pattern'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export const Notifications = () => {
  const { state, dispatch } = useApp()
  const { notifications } = state

  return notifications.map(({ id, type, title, body }) => (
    <Snackbar
      key={id}
      variant="outlined"
      color={match(type)
        .with('success', () => 'success' as const)
        .with('error', () => 'danger' as const)
        .exhaustive()}
      startDecorator={match(type)
        .with('success', () => <DoneIcon />)
        .with('error', () => <ErrorOutlineIcon />)
        .exhaustive()}
      open={true}
      onClose={() => {
        dispatch({
          type: 'removeNotification',
          payload: { notificationId: id }
        })
      }}
      autoHideDuration={6000}
    >
      <Box display="flex" flexDirection="column">
        <Typography level="title-sm">{title}</Typography>
        <Typography level="body-sm">{body}</Typography>
      </Box>
    </Snackbar>
  ))
}
