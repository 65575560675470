import Box from '@mui/joy/Box'
import { memo, useEffect } from 'react'
import { NodeProps } from 'reactflow'
import { SchemaNodeSummary } from 'sections/composer/schema/SchemaNodeSummary'
import { NodeContainer } from 'sections/composer/shared/NodeContainer'
import Typography from '@mui/joy/Typography'
import { SettingsIcon } from 'icons/SettingsIcon'
import {
  SettingsNodeData,
  WorkflowNode,
  createInitialSettingsConfig,
  defaultExportPath,
  generateConfigType
} from '@schematicos/types'
import { NodeAction } from 'sections/composer/types'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { useWorkflow } from 'sections/composer/WorkflowProvider'
import { z } from 'zod'
import { useInboundObservable } from 'sections/composer/useInboundObservable'
import {
  createLook,
  getHighlightColor,
  getMainColor
} from 'sections/composer/shared/createLook'

const settingsNodeSchemaId = z.object({
  schemaHash: z.string().optional(),
  generateConfig: generateConfigType.optional()
})

type SettingsNodeSchemaId = z.infer<typeof settingsNodeSchemaId>

export const SettingsNode = memo(
  (node: NodeProps<NodeAction<SettingsNodeData>>) => {
    const { data, selected, id } = node
    const { dispatch } = useWorkflow()
    const { settingsConfig } = useInternalSubject(data.internal$)

    const inbound = useInboundObservable<SettingsNodeSchemaId>(data.inbound$)

    const { schemaHash, generateConfig } = inbound ?? {}

    useEffect(() => {
      const workflowNode: WorkflowNode = {
        id: node.id,
        type: 'settings',
        position: { x: node.xPos, y: node.yPos },
        selected: node.selected,
        data: {
          settingsConfig: settingsConfig
        }
      }

      dispatch({
        type: 'updateNode',
        payload: {
          node: workflowNode
        }
      })
    }, [node.xPos, node.yPos, settingsConfig, node.selected])

    useEffect(() => {
      const currentSettingsConfig = data.internal$.getValue().settingsConfig

      if (schemaHash && schemaHash !== currentSettingsConfig?.schemaHash) {
        data.internal$.next({
          settingsConfig: schemaHash
            ? createInitialSettingsConfig(schemaHash, defaultExportPath)
            : undefined
        })
      }
    }, [schemaHash, generateConfig])

    const endpointCount = Object.values(
      settingsConfig?.operations ?? {}
    ).reduce((acc, pathConfig) => {
      const { length } = Object.values(pathConfig).filter(operationConfig => {
        return generateConfig?.library.some(
          library => operationConfig[library]?.selected
        )
      })

      return acc + length
    }, 0)

    const modelCount = Object.values(
      settingsConfig?.components?.models ?? {}
    ).reduce((acc, model) => acc + (model.selected ? 1 : 0), 0)

    return (
      <NodeContainer
        selected={selected}
        autoSelect={data.autoSelect ? { nodeId: id } : undefined}
        look={createLook({ nodeType: 'settings', selected })}
        highlightColor={getHighlightColor('settings')}
      >
        <Box
          display="flex"
          gap="8px"
          flex={1}
          sx={{
            color: getMainColor({ nodeType: 'settings', selected }),
            padding: '8px',
            gap: '8px'
          }}
        >
          <SettingsIcon width="24px" height="24px" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingRight="8px"
          >
            <Typography sx={{ fontSize: 'sm', lineHeight: '16px' }}>
              Settings
            </Typography>
            <SchemaNodeSummary
              endpointCount={endpointCount}
              modelCount={modelCount}
            />
          </Box>
        </Box>
      </NodeContainer>
    )
  }
)
