import Box from '@mui/joy/Box'
import { TreeItemLabel } from 'components/TreeItem/TreeItem'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import { KebabIcon } from 'icons/KebabIcon'
import { usePathEditorItem } from 'components/Paths/PathEditorItemProvider'
import Divider from '@mui/joy/Divider'
import { useTransformer } from 'sections/transformers/TransformerProvider'

type PathEditorLabelProps = {
  path: string
}

export const PathEditorLabel = ({ path }: PathEditorLabelProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        '&:hover .item-menu-button': {
          display: 'flex'
        }
      }}
    >
      <TreeItemLabel>{path}</TreeItemLabel>
      <ItemMenu path={path} />
    </Box>
  )
}

type ItemMenuProps = {
  path: string
}

export const ItemMenu = ({ path }: ItemMenuProps) => {
  const { dispatch: pathEditorItemDispatch } = usePathEditorItem()
  const { dispatch: transformerDispatch } = useTransformer()

  return (
    <Dropdown>
      <MenuButton
        className="item-menu-button"
        sx={{
          display: 'none',
          minWidth: '28px',
          minHeight: '28px',
          height: '28px',
          alignSelf: 'center',
          borderRadius: '50%'
        }}
        slots={{ root: IconButton }}
      >
        <KebabIcon width="20px" height="20px" />
      </MenuButton>

      <Menu placement="bottom-end" disablePortal size="sm">
        <MenuItem
          onClick={() => {
            pathEditorItemDispatch({
              type: 'setRenaming',
              payload: { renaming: true }
            })
          }}
          sx={{ color: 'var(--joy-palette-neutral-500)' }}
        >
          Rename
        </MenuItem>
        <Divider />
        <MenuItem
          color="danger"
          onClick={event => {
            event.stopPropagation()

            transformerDispatch({
              type: 'deleteItem',
              payload: { filePath: path }
            })
          }}
        >
          Delete
        </MenuItem>
      </Menu>
    </Dropdown>
  )
}
