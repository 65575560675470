import { useApp } from 'components/AppProvider/AppProvider'
import { useFetchError } from 'components/useFetchError/useFetchError'
import { updateWorkflow } from 'loaders/workflows/updateWorkflow'
import { useRef } from 'react'

export const useUpdateWorkflow = () => {
  const firstCallRef = useRef(true)
  const { handleFetchError } = useFetchError()
  const loadingRef = useRef(false)
  const { state } = useApp()
  const { session } = state

  if (!session) {
    return () => {}
  }

  const updateWorkflowFn = updateWorkflow({
    loadingRef,
    firstCallRef,
    onSuccess: workflow => console.log('Workflow updated', workflow),
    onError: handleFetchError
  })

  return updateWorkflowFn
}
