import {
  FC,
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useRef
} from 'react'
import { TransformerState } from 'sections/transformers/types'
import { TransformerModel } from '@schematicos/types'
import { match } from 'ts-pattern'
import { useImmerReducer } from 'use-immer'
import debounce from 'lodash/debounce'
import { useUpdateTransformer } from 'loaders/transformers/useUpdateTransformer'

type TransformerContextType = {
  state: TransformerState
  dispatch: (action: TransformerAction) => void
}

const TransformerContext = createContext<TransformerContextType | undefined>(
  undefined
)

type RenameTransformer = {
  type: 'renameTransformer'
  payload: {
    name: string
  }
}

const renameTransformer =
  (state: TransformerState) =>
  ({ payload }: RenameTransformer) => {
    state.transformer.name = payload.name
  }

type DeleteItem = {
  type: 'deleteItem'
  payload: {
    filePath: string
  }
}

const deleteItem =
  (state: TransformerState) =>
  ({ payload }: DeleteItem) => {
    if (state.selectedFilePath === payload.filePath) {
      state.selectedFilePath = undefined
    }

    delete state.transformer.content[payload.filePath]
  }

type SetHasNewItem = {
  type: 'setHasNewItem'
  payload: {
    hasNewItem: boolean
  }
}

const setHasNewItem =
  (state: TransformerState) =>
  ({ payload }: SetHasNewItem) => {
    state.hasNewItem = payload.hasNewItem
  }

type SelectFilePath = {
  type: 'selectFilePath'
  payload: {
    filePath: string
    newFileSelected: boolean
  }
}

const selectFilePath =
  (state: TransformerState) =>
  ({ payload }: SelectFilePath) => {
    state.selectedFilePath = payload.filePath
    state.newFileSelected = payload.newFileSelected
  }

type UpdateTransformerContent = {
  type: 'updateTransformerContent'
  payload: {
    filePath: string
    fileContent: string
  }
}

const updateTransformerContent =
  (state: TransformerState) =>
  ({ payload }: UpdateTransformerContent) => {
    state.transformer.content[payload.filePath] = payload.fileContent
  }

type RenameTransformerItem = {
  type: 'renameTransformerItem'
  payload: {
    filePathFrom: string
    filePathTo: string
  }
}

const renameTransformerItem =
  (state: TransformerState) =>
  ({ payload }: RenameTransformerItem) => {
    const { filePathFrom, filePathTo } = payload

    // when handling a new item
    if (state.hasNewItem && filePathFrom === '') {
      // remove new item flag
      state.hasNewItem = false

      // if file has name
      if (filePathTo) {
        // initialise content to empty string
        state.transformer.content[filePathTo] = ''
      }

      return
    }

    // if name has changed, move content to new name
    if (filePathFrom !== filePathTo) {
      state.transformer.content[filePathTo] =
        state.transformer.content[filePathFrom]

      delete state.transformer.content[filePathFrom]
    }
  }

export type TransformerAction =
  | RenameTransformer
  | UpdateTransformerContent
  | RenameTransformerItem
  | SetHasNewItem
  | SelectFilePath
  | DeleteItem

const transformerReducer = (
  state: TransformerState,
  action: TransformerAction
) => {
  match(action)
    .with({ type: 'renameTransformer' }, renameTransformer(state))
    .with({ type: 'updateTransformerContent' }, updateTransformerContent(state))
    .with({ type: 'renameTransformerItem' }, renameTransformerItem(state))
    .with({ type: 'setHasNewItem' }, setHasNewItem(state))
    .with({ type: 'selectFilePath' }, selectFilePath(state))
    .with({ type: 'deleteItem' }, deleteItem(state))
    .exhaustive()
}

type TransformerProviderProps = {
  transformer: TransformerModel
  children: ReactNode
}

export const TransformerProvider: FC<TransformerProviderProps> = ({
  transformer,
  children
}) => {
  const updateTransformer = useUpdateTransformer()
  const debouncedUpdateTransformer = useRef(debounce(updateTransformer, 3000))

  const [state, dispatch] = useImmerReducer<
    TransformerState,
    TransformerAction
  >(transformerReducer, {
    transformer,
    hasNewItem: false,
    selectedFilePath: Object.keys(transformer.content)[0],
    newFileSelected: false
  })

  useEffect(() => {
    debouncedUpdateTransformer.current(state.transformer)
  }, [state.transformer])

  console.log('TransformerContent', state.transformer.content)
  console.log('SelectedFilePath', state.selectedFilePath)
  console.log('NewFileSelected', state.newFileSelected)

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  return (
    <TransformerContext.Provider value={value}>
      {children}
    </TransformerContext.Provider>
  )
}

export const useTransformer = () => {
  const context = useContext(TransformerContext)
  if (context === undefined) {
    throw new Error('useTransformer must be used within a TransformerProvider')
  }
  return context
}
