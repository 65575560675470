import { memo, useEffect } from 'react'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { OpenSchemaLiveData } from 'sections/composer/types'
import { FormProvider, useForm } from 'react-hook-form'
import { ConfigureSource } from 'components/ConfigureSource/ConfigureSource'
import Box from '@mui/joy/Box'
import { DocumentSource } from 'components/ConfigureSource/DocumentSource'
import { SourceFormValues, sourceFormValues } from '@schematicos/types'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { sha256 } from 'lib/hash/sha256'

type OpenSchemaDrawerContentProps = {
  internal$: BehaviorSubject<OpenSchemaLiveData>
}

export const OpenSchemaDrawerContent = memo(
  ({ internal$ }: OpenSchemaDrawerContentProps) => {
    const { source, schemaModel } = useInternalSubject(internal$)

    const formMethods = useForm<SourceFormValues>({
      defaultValues: source ?? { sourceType: null, content: '' }
    })

    const content = formMethods.watch('content')

    useEffect(() => {
      const values = formMethods.getValues()
      const updatedSource = sourceFormValues.safeParse(values)

      if (updatedSource.success) {
        if (!updatedSource.data.content.length) {
          return
        }

        sha256(updatedSource.data.content)
          .then(schemaHash => {
            internal$.next({
              schemaModel,
              source: updatedSource.data,
              schemaHash
            })
          })
          .catch(err => {
            console.error('Error hashing schema', err)
          })
      }
    }, [content])

    return (
      <FormProvider {...formMethods}>
        <Box display="flex" minHeight={0}>
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="400px"
            pl="12px"
            py="16px"
            flex={1}
          >
            <ConfigureSource />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            pr="12px"
            py="16px"
            flex={1}
          >
            <DocumentSource />
          </Box>
        </Box>
      </FormProvider>
    )
  }
)
