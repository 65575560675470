import { FC } from 'react'

type GenerateIconProps = {
  color?: string
  width?: string
  height?: string
}

export const GenerateIcon: FC<GenerateIconProps> = ({
  color = 'currentColor',
  width = '36px',
  height = '36px'
}) => (
  <svg
    width={width}
    height={height}
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill={color}
      d="m61.199 13.398c-0.56641-0.75391-1.4531-1.1992-2.3984-1.1992h-22c-1.3516-0.003907-2.5391 0.89844-2.9023 2.1992l-10.098 35.703c-0.25 0.90234-0.0625 1.8711 0.50391 2.6172 0.57031 0.74609 1.457 1.1836 2.3945 1.1797h15l-2.8984 30.602c-0.125 1.3711 0.69922 2.6484 2 3.1016l1 0.19922c0.99609 0.007813 1.9336-0.48047 2.5-1.3008l31.5-45.801c0.63281-0.92188 0.70312-2.1211 0.17578-3.1094-0.52344-0.98828-1.5586-1.6016-2.6758-1.5898h-17.402l5.8008-20c0.24609-0.89844 0.0625-1.8594-0.5-2.6016zm6.4023 28.602-21.703 31.602 2.1016-22.398v-0.003906c0.085938-0.84375-0.19141-1.6875-0.76562-2.3164-0.57031-0.62891-1.3828-0.98828-2.2344-0.98437h-14.301l8.3008-29.699h15.801l-5.8008 19.902c-0.28906 0.91797-0.12109 1.918 0.45312 2.6875 0.57422 0.77344 1.4844 1.2227 2.4453 1.2109z"
    />
  </svg>
)
