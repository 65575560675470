import { useParams } from 'react-router-dom'
import { useSchema } from 'sections/schemas/SchemaProvider'
import invariant from 'tiny-invariant'

export const useLookupOperation = () => {
  const { state: schemaState } = useSchema()
  const { schemaModel } = schemaState
  const { path: paramPath, method: paramMethod } = useParams()

  invariant(paramPath, 'path is required')
  invariant(paramMethod, 'method is required')

  return schemaModel.operations.find(({ path, method }) => {
    return method === paramMethod && path === paramPath
  })
}
