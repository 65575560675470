import { useFetchError } from 'components/useFetchError/useFetchError'
import { updateTransformer } from 'loaders/transformers/updateTransformer'
import { useRef } from 'react'

export const useUpdateTransformer = () => {
  const firstCallRef = useRef(true)
  const { handleFetchError } = useFetchError()
  const loadingRef = useRef(false)

  const updateTransformerFn = updateTransformer({
    loadingRef,
    firstCallRef,
    onSuccess: transformer => console.log('Transformer updated', transformer),
    onError: handleFetchError
  })

  return updateTransformerFn
}
