import { oasRoot, schemaModel as schemaModelType } from '@schematicos/types'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

type GetSchemaArgs = {
  schemaId: string
}

const getSchema = async ({ schemaId }: GetSchemaArgs) => {
  const { data, error } = await supabaseClient.functions.invoke(
    `schemas/${schemaId}`,
    { method: 'GET' }
  )

  if (error) {
    throw error
  }

  return schemaModelType.parse(data)
}

type ParseSchemaArgs = {
  schemaDocument: string
  schemaFormat: 'json' | 'yaml'
}

const parseSchema = async ({
  schemaDocument,
  schemaFormat
}: ParseSchemaArgs) => {
  const res = await fetch(`${import.meta.env.VITE_API_ORIGIN}/api/parse`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      schemaDocument,
      schemaFormat
    })
  })
  const { data, error } = await res.json()

  if (error) {
    throw error
  }

  return oasRoot.parse(data)
}

type SchemaActionFnArgs = {
  schemaId?: string
}

export const schemaActionFn = async ({ schemaId }: SchemaActionFnArgs) => {
  if (!schemaId) {
    throw new Error('No schemaId provided to schemaActionFn')
  }

  const schema = await getSchema({ schemaId })

  const schemaModel = await parseSchema({
    schemaDocument: schema.content,
    schemaFormat: schema.schemaFormat
  })

  return {
    schema,
    schemaModel
  }
}
