import { AppState } from 'components/types'

export type SetQueryAction = {
  type: 'setQuery'
  payload: string
}

export const setQuery =
  (state: AppState) =>
  ({ payload: query }: SetQueryAction): AppState => ({
    ...state,
    query
  })
