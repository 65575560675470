import Box from '@mui/joy/Box'
import CircularProgress from '@mui/joy/CircularProgress'
import Typography from '@mui/joy/Typography'
import { AddNewCard } from 'components/Card/AddNewCard'
import { DashboardContent } from 'components/Dashboard/DashboardContent'
import { DashboardTop } from 'components/Dashboard/DashboardTop'
import { ViewSchemaCard } from 'sections/schemas/ViewSchemaCard'
import { useGetSchemas } from 'loaders/schemas/useGetSchemas'
import { SchemaModels } from '@schematicos/types'
import { Outlet } from 'react-router-dom'
import { match, P } from 'ts-pattern'

export const SchemaList = () => {
  const schemasRes = useGetSchemas()

  return (
    <>
      <DashboardTop>
        <Typography sx={{ color: 'neutral.600' }}>OpenAPI schemas</Typography>
      </DashboardTop>
      <DashboardContent sx={{ px: '32px', pt: '40px' }}>
        <Typography level="h2" sx={{ color: 'neutral.700', fontWeight: 500 }}>
          OpenAPI schemas
        </Typography>

        <Box height="40px" />
        {match(schemasRes)
          .with({ loading: true }, () => <Loading />)
          .with({ data: P.array() }, ({ data }) => {
            return <SchemasList schemas={data} />
          })

          .otherwise(() => null)}
      </DashboardContent>
      <Outlet />
    </>
  )
}

const Loading = () => (
  <Box display="flex" flexDirection="column">
    <CircularProgress color="neutral" />
  </Box>
)

type SchemasListProps = {
  schemas: SchemaModels
}

const SchemasList = ({ schemas }: SchemasListProps) => (
  <Box display="flex" gap="24px" flexWrap="wrap">
    <AddNewCard to="new/config" title="Add schema" />
    {schemas.map(schema => (
      <ViewSchemaCard key={schema.id} schema={schema} />
    ))}
  </Box>
)
