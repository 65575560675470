import Box from '@mui/joy/Box'
import { TreeItem } from '@mui/x-tree-view/TreeItem'
import {
  SettingsConfigType,
  GenerateLibraryType,
  OasRoot
} from '@schematicos/types'
import { MethodBadge } from 'components/MethodBadge/MethodBadge'
import { TreeItemLabel } from 'components/TreeItem/TreeItem'
import { TreeItemIcon } from 'components/TreeItem/TreeItemIcon'
import { NodeId } from 'sections/composer/types'
import { ToggleOperationArgs } from 'components/types'
import { toOperationNodeId } from 'components/ApiPaths/toOperationNodeId'
import { isVoidOperation } from 'lib/validation/voidChecks'

type PathNavigationProps = {
  settingsConfig?: SettingsConfigType
  toggleOperation?: ({ path, method, selected }: ToggleOperationArgs) => void
  query: string
  transformers: GenerateLibraryType[]
  schemaModel: OasRoot
}

export const PathsNavigation = ({
  settingsConfig,
  toggleOperation,
  transformers,
  query,
  schemaModel
}: PathNavigationProps) => {
  const operations = schemaModel.operations.filter(operation => {
    return (
      !query.length ||
      operation.path.toLocaleLowerCase().includes(query.toLocaleLowerCase())
    )
  })

  if (!operations.length) {
    return null
  }

  return (
    <TreeItem
      key="paths"
      nodeId="apiPaths"
      label={<TreeItemLabel>API paths</TreeItemLabel>}
    >
      {operations.map(operation => {
        const { path, method } = operation

        const nodeId: NodeId = toOperationNodeId({ path, method })

        const voidOperation = isVoidOperation({
          operation,
          transformers
        })

        return (
          <TreeItem
            key={`${path}/${method}`}
            nodeId={nodeId}
            label={
              <Box display="flex" gap="8px" alignItems="center">
                <TreeItemLabel voidItem={voidOperation}>{path}</TreeItemLabel>
                <MethodBadge>{method}</MethodBadge>
              </Box>
            }
            icon={
              toggleOperation ? (
                <TreeItemIcon
                  voidItem={voidOperation}
                  type="operation"
                  operation={operation}
                  transformers={transformers}
                  settingsConfig={settingsConfig}
                  toggleOperation={toggleOperation}
                />
              ) : null
            }
          />
        )
      })}
    </TreeItem>
  )
}
