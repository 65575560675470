import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import Option from '@mui/joy/Option'
import Select from '@mui/joy/Select'
import { Controller, useFormContext } from 'react-hook-form'
import { match } from 'ts-pattern'
import { GithubRepos } from 'components/ConfigureSource/GithubRepos'
import { GithubPaths } from 'components/ConfigureSource/GithubPaths'
import { ExternalUrl } from 'components/ConfigureSource/ExternalUrl'
import { SourceFormValues } from '@schematicos/types'
import { useApp } from 'components/AppProvider/AppProvider'
import Box from '@mui/joy/Box'
import Button from '@mui/joy/Button'

export const ConfigureSource = () => {
  const { control, watch, setValue } = useFormContext<SourceFormValues>()
  const { state } = useApp()
  const { session } = state

  const sourceType = watch('sourceType')

  return (
    <>
      <Controller
        name="sourceType"
        control={control}
        render={({ field: { onChange, ...field } }) => (
          <FormControl sx={{ py: '8px', px: '12px' }}>
            <FormLabel>Select OpenAPI source type</FormLabel>
            <Select
              {...field}
              size="sm"
              onChange={(_, value) => onChange(value)}
              placeholder="Choose OpenAPI source type…"
            >
              {session ? <Option value="github">Github repo</Option> : null}
              <Option value="url">Url</Option>
              <Option value="text">Paste text</Option>
            </Select>
          </FormControl>
        )}
      />

      {match(sourceType)
        .with('github', () => (
          <>
            <GithubRepos />
            <GithubPaths />
          </>
        ))
        .with('url', () => <ExternalUrl />)
        .with('text', () => null)
        .with(null, () => null)
        .exhaustive()}

      <Box sx={{ py: '8px', px: '12px' }}>
        <Button
          size="sm"
          variant="plain"
          onClick={() => {
            setValue('sourceType', 'url')
            setValue('url', 'https://petstore3.swagger.io/api/v3/openapi.json')
          }}
        >
          Try demo schema
        </Button>
      </Box>
    </>
  )
}
