import { memo } from 'react'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { SchemaLiveData } from 'sections/composer/types'
import { FormProvider, useForm } from 'react-hook-form'
import { ConfigureSource } from 'components/ConfigureSource/ConfigureSource'
import Box from '@mui/joy/Box'
import { SourceFormValues } from '@schematicos/types'
import { DocumentSource } from 'components/ConfigureSource/DocumentSource'

type SchemaDrawerContentProps = {
  internal$: BehaviorSubject<SchemaLiveData>
}

export const SchemaDrawerContent = memo(
  ({ internal$ }: SchemaDrawerContentProps) => {
    const formMethods = useForm<SourceFormValues>({
      defaultValues: { sourceType: null }
    })

    console.log(internal$)

    return (
      <FormProvider {...formMethods}>
        <Box display="flex" minHeight={0}>
          <Box
            display="flex"
            flexDirection="column"
            maxWidth="400px"
            pl="12px"
            py="16px"
            flex={1}
          >
            <ConfigureSource />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            pr="12px"
            py="16px"
            flex={1}
          >
            <DocumentSource />
          </Box>
        </Box>
      </FormProvider>
    )
  }
)
