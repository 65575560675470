import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import MoreVert from '@mui/icons-material/MoreVert'

type NavigationMenuProps = {
  toggleAll?: (selectAll: boolean) => void
}

export const NavigationMenu = ({ toggleAll }: NavigationMenuProps) => (
  <Dropdown>
    <MenuButton
      sx={{
        minWidth: '28px',
        minHeight: '28px',
        height: '28px',
        alignSelf: 'center',
        borderRadius: '50%'
      }}
      slots={{ root: IconButton }}
    >
      <MoreVert sx={{ color: 'var(--joy-palette-neutral-500)' }} />
    </MenuButton>

    <Menu placement="bottom-end" disablePortal size="sm">
      <MenuItem
        onClick={() => toggleAll?.(true)}
        sx={{ color: 'var(--joy-palette-neutral-500)' }}
      >
        Select all
      </MenuItem>
      <MenuItem
        onClick={() => toggleAll?.(false)}
        sx={{ color: 'var(--joy-palette-neutral-500)' }}
      >
        Deselect all
      </MenuItem>
    </Menu>
  </Dropdown>
)
