import Box from '@mui/joy/Box'
import CircularProgress from '@mui/joy/CircularProgress'

export const Spinner = () => (
  <Box
    display="flex"
    justifyContent="center"
    alignItems="center"
    flex={1}
    sx={{ bgcolor: 'background.surface' }}
  >
    <CircularProgress />
  </Box>
)
