import { TreeItemContent } from '@mui/x-tree-view/TreeItem'
import { usePathEditorItem } from 'components/Paths/PathEditorItemProvider'
import { PathEditorLabel } from 'components/Paths/PathEditorLabel'
import { RenameInput } from 'components/Paths/RenameInput'
import { TreeItem } from 'components/TreeItem/TreeItem.tsx'
import { TypescriptIcon } from 'icons/TypescriptIcon'
import DocumentIcon from '@heroicons/react/24/outline/DocumentIcon'

export const PathEditorItem = () => {
  const { state } = usePathEditorItem()
  const { renaming, path } = state

  return (
    <TreeItem
      key={path}
      nodeId={path}
      icon={
        (path.endsWith('.ts') || path.endsWith('.tsx')) && !renaming ? (
          <TypescriptIcon />
        ) : (
          <DocumentIcon />
        )
      }
      ContentComponent={renaming ? RenameInput : TreeItemContent}
      label={<PathEditorLabel path={path} />}
    />
  )
}
