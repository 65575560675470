import {
  CssVarsProvider,
  CssVarsProvider as JoyCssVarsProvider,
  extendTheme
} from '@mui/joy/styles'
import CssBaseline from '@mui/joy/CssBaseline'
import { RouterProvider } from 'react-router-dom'
import { router } from 'routes'
import {
  experimental_extendTheme as materialExtendTheme,
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  THEME_ID as MATERIAL_THEME_ID
} from '@mui/material/styles'
import { AppProvider } from 'components/AppProvider/AppProvider'
import { Notifications } from 'components/Notifications/Notifications'
import { Session } from 'components/Auth/Session'
import { mdInsetShadow } from 'components/styles'

const theme = extendTheme({
  components: {
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            boxShadow: mdInsetShadow
          })
        })
      }
    },
    JoyTextarea: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          ...(ownerState.variant === 'outlined' && {
            boxShadow: mdInsetShadow
          })
        })
      }
    },
    JoyDrawer: {
      styleOverrides: {
        // `ownerState` contains the component props and internal state
        root: () => ({
          width: 'fit-content'
        })
      }
    }
  }
})

const materialTheme = materialExtendTheme()

const App = () => (
  <CssVarsProvider theme={theme}>
    <MaterialCssVarsProvider theme={{ [MATERIAL_THEME_ID]: materialTheme }}>
      <JoyCssVarsProvider theme={theme}>
        <CssBaseline enableColorScheme />
        <AppProvider>
          <Notifications />
          <Session />
          <RouterProvider router={router} />
        </AppProvider>
      </JoyCssVarsProvider>
    </MaterialCssVarsProvider>
  </CssVarsProvider>
)

export default App
