import { useEffect } from 'react'
import { useApp } from 'components/AppProvider/AppProvider'
import { match } from 'ts-pattern'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

export const useSession = () => {
  const { dispatch } = useApp()

  useEffect(() => {
    void supabaseClient.auth.getSession().then(({ data }) => {
      dispatch({ type: 'setSession', payload: data.session })
    })

    const {
      data: { subscription }
    } = supabaseClient.auth.onAuthStateChange((event, updatedSession) => {
      match(event).with('INITIAL_SESSION', async () => {
        if (!updatedSession?.user?.id) {
          return
        }

        const { data: d } = await supabaseClient.functions.invoke(
          'user-setup',
          {
            method: 'GET'
          }
        )

        const data = d as { ok: boolean }

        if (!data?.ok && updatedSession.provider_token) {
          await supabaseClient.functions.invoke(`user-setup`, {
            method: 'POST',
            body: {
              provider_token: updatedSession.provider_token
            }
          })
        }
      })

      dispatch({ type: 'setSession', payload: updatedSession })
    })

    return () => subscription.unsubscribe()
  }, [])
}
