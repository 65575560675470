import Typography from '@mui/joy/Typography'
import { SxProps } from '@mui/joy/styles/types'
import { ReactNode } from 'react'

type BodyProps = {
  children: ReactNode
  sx?: SxProps
}

export const Body = ({ sx, ...props }: BodyProps) => (
  <Typography sx={{ color: 'neutral.700', ...sx }} {...props} />
)
