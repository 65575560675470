import Card from '@mui/joy/Card'
import CardContent from '@mui/joy/CardContent'
import Typography from '@mui/joy/Typography'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import { Link as RouterLink } from 'react-router-dom'
import { match, P } from 'ts-pattern'
import Button from '@mui/joy/Button'
import { MouseEvent } from 'react'

type AddNewCardProps =
  | {
      to: string
      title: string
    }
  | {
      onClick: (event: MouseEvent) => void
      title: string
    }

export const AddNewCard = ({ title, ...props }: AddNewCardProps) => {
  const componentProps = match(props)
    .with({ to: P.string }, ({ to }) => ({ to, component: RouterLink }))
    .with({ onClick: P._ }, ({ onClick }) => ({ onClick, component: Button }))
    .exhaustive()

  return (
    <Card
      variant="outlined"
      orientation="horizontal"
      sx={{
        textDecoration: 'none',
        width: 350,
        bgcolor: 'background.body',
        boxShadow: 'sm',
        '&:hover': {
          bgcolor: 'background.body',
          boxShadow: 'md',
          borderColor: 'neutral.outlinedHoverBorder'
        }
      }}
      {...componentProps}
    >
      <CardContent>
        <Typography
          gap="8px"
          sx={{ color: 'neutral.700', fontWeight: 500, fontSize: '16px' }}
          display="flex"
          flex={1}
          justifyContent="center"
          alignItems="center"
        >
          <AddCircleOutlineIcon sx={{ fontSize: '18px' }} /> {title}
        </Typography>
      </CardContent>
    </Card>
  )
}
