import { OasRoot, oasRoot } from '@schematicos/types'
import { useApp } from 'components/AppProvider/AppProvider'
import { nanoid } from 'nanoid'
import { useEffect, useRef, useState } from 'react'

type UseParseArgs =
  | {
      schemaDocument: string
      schemaFormat: 'json' | 'yaml'
    }
  | {
      skip: true
    }

export const useParse = (args: UseParseArgs) => {
  const { dispatch } = useApp()
  const loadingRef = useRef(false)
  const [schema, setSchema] = useState<OasRoot>()

  useEffect(() => {
    if (loadingRef.current) {
      return
    }

    if ('skip' in args) {
      return
    }

    loadingRef.current = true

    fetch(`${import.meta.env.VITE_API_ORIGIN}/api/parse`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      method: 'POST',
      body: JSON.stringify({
        schemaDocument: args.schemaDocument,
        schemaFormat: args.schemaFormat
      })
    })
      .then(res => res.json())
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = oasRoot.parse(data)

        setSchema(parsed)
      })
      .catch(error => {
        dispatch({
          type: 'addNotification',
          payload: {
            id: nanoid(),
            type: 'error',
            title:
              'message' in error
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  error.message
                : 'Failed to parse schema'
          }
        })
        console.log(error)
      })
  }, [args])

  return { data: schema, loading: loadingRef.current }
}
