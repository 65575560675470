import { GenerateLibraryType, OasOperation } from '@schematicos/types'
import { match } from 'ts-pattern'

type IsVoidOperationArgs = {
  operation: OasOperation
  transformers: GenerateLibraryType[]
}

export const isVoidOperation = ({
  operation,
  transformers
}: IsVoidOperationArgs) => {
  return transformers.every(library => {
    return match(library)
      .with('rtk-query', () => isRtkQueryOperationVoid(operation))
      .with('mui-form', () => getMuiFormOperationVoidMessage(operation))
      .exhaustive()
  })
}

type IsVoidLibraryArgs = {
  operation: OasOperation
  library: GenerateLibraryType
}

export const isVoidLibrary = ({ operation, library }: IsVoidLibraryArgs) => {
  const isVoid = match(library)
    .with('rtk-query', () => isRtkQueryOperationVoid(operation))
    .with('mui-form', () => getMuiFormOperationVoidMessage(operation))
    .exhaustive()

  return Boolean(isVoid)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const isRtkQueryOperationVoid = (_operation: OasOperation) => {
  return
}

export const getMuiFormOperationVoidMessage = (operation: OasOperation) => {
  const { method } = operation

  if (!['post', 'put'].includes(method.toLocaleLowerCase())) {
    return 'MUI Forms can only be generated for POST and PUT operations'
  }

  return
}
