import { NavigateFunction } from 'react-router-dom'

export type OnNodeSelect = (nodeIds: string[]) => void

export const onNodeSelect =
  (navigate: NavigateFunction) => (nodeIds: string[]) => {
    if (nodeIds.length > 1) {
      const schemaIds = nodeIds
        .filter(item => item.startsWith('components/schemas/'))
        .map(item => item.replace('components/schemas/', ''))
        .join('%2c')

      if (!schemaIds) {
        return
      }

      navigate(`components/schemas/${schemaIds}`)
      return
    }

    if (nodeIds.length === 1) {
      const chunks = nodeIds[0].split('|')

      const destination = chunks
        .map(chunk => encodeURIComponent(chunk))
        .join('/')

      navigate(destination)
    }
  }
