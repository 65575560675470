import Box from '@mui/joy/Box'
import { memo, useEffect } from 'react'
import { NodeProps } from 'reactflow'
import { NodeContainer } from 'sections/composer/shared/NodeContainer'
import { GenerateIcon } from 'icons/GenerateIcon'
import Typography from '@mui/joy/Typography'
import { GenerateConfigType, WorkflowNode } from '@schematicos/types'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { GenerateLiveData, NodeAction } from 'sections/composer/types'

import { useWorkflow } from 'sections/composer/WorkflowProvider'

import { generateLibraries } from 'sections/composer/generate/generateOptions'
import {
  createLook,
  getHighlightColor,
  getMainColor
} from 'sections/composer/shared/createLook'

export const GenerateNode = memo(
  (node: NodeProps<NodeAction<GenerateLiveData>>) => {
    const { data, selected } = node
    const { dispatch } = useWorkflow()
    const { generateConfig } = useInternalSubject(data.internal$)

    useEffect(() => {
      const workflowNode: WorkflowNode = {
        id: node.id,
        type: 'generate',
        position: { x: node.xPos, y: node.yPos },
        selected: node.selected,
        data: {
          generateConfig: generateConfig
        }
      }

      dispatch({
        type: 'updateNode',
        payload: {
          node: workflowNode
        }
      })
    }, [node.xPos, node.yPos, node.selected, generateConfig])

    return (
      <NodeContainer
        selected={selected}
        look={createLook({ nodeType: 'generate', selected })}
        highlightColor={getHighlightColor('generate')}
      >
        <Box
          display="flex"
          flex={1}
          sx={{
            // eslint-disable-next-line @typescript-eslint/no-unsafe-call
            color: getMainColor({ nodeType: 'generate', selected }),
            padding: '8px',
            gap: '8px'
          }}
        >
          <GenerateIcon width="24px" height="24px" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingRight="8px"
          >
            <Typography sx={{ fontSize: 'sm', lineHeight: '16px' }}>
              Generate
            </Typography>

            <GenerateNodeSummary generateConfig={generateConfig} />
          </Box>
        </Box>
      </NodeContainer>
    )
  }
)

type GenerateNodeSummaryProps = {
  generateConfig?: GenerateConfigType
}

const GenerateNodeSummary = ({ generateConfig }: GenerateNodeSummaryProps) => {
  if (!generateConfig) {
    return null
  }

  return (
    <Box display="flex" gap="16px">
      <Typography sx={{ color: 'neutral.400', fontSize: 'sm' }}>
        {generateConfig.library.length
          ? generateConfig.library
              .map(library => generateLibraries[library])
              .filter(Boolean)
              .join(', ')
          : 'No libraries selected'}
      </Typography>

      <Typography sx={{ color: 'neutral.400', fontSize: 'sm' }}>
        {generateConfig.typeSystem}
      </Typography>
    </Box>
  )
}
