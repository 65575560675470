import IconButton from '@mui/joy/IconButton'
import { match } from 'ts-pattern'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import {
  SettingsConfigType,
  GenerateLibraryType,
  OasOperation
} from '@schematicos/types'
import { ToggleOperationArgs } from 'components/types'
import { isVoidLibrary } from 'lib/validation/voidChecks'

type TreeItemIconProps =
  | {
      type: 'operation'
      settingsConfig?: SettingsConfigType
      voidItem?: boolean
      toggleOperation: ({ path, method, selected }: ToggleOperationArgs) => void
      transformers: GenerateLibraryType[]
      operation: OasOperation
    }
  | {
      type: 'model'
      settingsConfig?: SettingsConfigType
      voidItem?: boolean
      toggleModel: (modelName: string) => void
      modelName: string
    }

export const TreeItemIcon = ({
  settingsConfig,
  ...props
}: TreeItemIconProps) => {
  const selectionState = match(props)
    .with({ type: 'operation' }, ({ operation, transformers }) => {
      const { path, method } = operation

      const selectedCount = transformers.reduce((acc, library) => {
        const isVoid = isVoidLibrary({ operation, library })

        if (isVoid) {
          return acc
        }

        const selected =
          settingsConfig?.operations?.[path]?.[method]?.[library]?.selected

        if (!selected) {
          return acc
        }

        return acc + 1
      }, 0)

      const totalCount = transformers.reduce((acc, library) => {
        const isVoid = isVoidLibrary({ operation, library })

        return !isVoid ? acc + 1 : acc
      }, 0)

      return selectedCount === 0
        ? 'none'
        : selectedCount < totalCount
          ? 'partial'
          : 'selected'
    })
    .with({ type: 'model' }, ({ modelName }) => {
      return settingsConfig?.components?.models?.[modelName]?.selected
        ? 'selected'
        : 'none'
    })
    .exhaustive()

  return (
    <IconButton
      onClick={event => {
        event.stopPropagation()
        match(props)
          .with({ type: 'operation' }, ({ operation, toggleOperation }) => {
            if (props.voidItem) {
              return
            }

            const { path, method } = operation

            toggleOperation({
              path,
              method,
              selected: selectionState === 'none'
            })
          })
          .with({ type: 'model' }, ({ modelName, toggleModel }) => {
            toggleModel(modelName)
          })
          .exhaustive()
      }}
    >
      {match(selectionState)
        .with('none', () => (
          <CheckBoxOutlineBlankIcon
            sx={{
              color: 'var(--joy-palette-neutral-500)',
              opacity: props.voidItem ? 0.5 : 1
            }}
          />
        ))
        .with('partial', () => (
          <IndeterminateCheckBoxIcon
            sx={{
              color: 'var(--joy-palette-neutral-500)',
              opacity: props.voidItem ? 0.5 : 1
            }}
          />
        ))
        .with('selected', () => (
          <CheckBoxIcon
            sx={{
              color: 'var(--joy-palette-neutral-500)',
              opacity: props.voidItem ? 0.5 : 1
            }}
          />
        ))
        .exhaustive()}
    </IconButton>
  )
}
