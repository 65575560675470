import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'

type SchemaNodeSummaryProps = {
  endpointCount: number
  modelCount: number
}

export const SchemaNodeSummary = ({
  endpointCount,
  modelCount
}: SchemaNodeSummaryProps) => (
  <Box display="flex" gap="16px">
    <Box display="flex" gap="2px">
      <Typography
        flex={0}
        fontWeight="500"
        sx={{ color: 'neutral.500', fontSize: 'sm' }}
      >
        {endpointCount}
      </Typography>
      <Typography sx={{ color: 'neutral.400', fontSize: 'sm' }}>
        Endpoints
      </Typography>
    </Box>
    <Box display="flex" gap="2px">
      <Typography
        flex={0}
        fontWeight="500"
        sx={{ color: 'neutral.500', fontSize: 'sm' }}
      >
        {modelCount}
      </Typography>
      <Typography sx={{ color: 'neutral.400', fontSize: 'sm' }}>
        Models
      </Typography>
    </Box>
  </Box>
)
