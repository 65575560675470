import { Method } from '@schematicos/types'
import { OasOperationId } from 'sections/composer/types'

type ToOperationNodeIdArgs = {
  path: string
  method: Method
}

export const toOperationNodeId = ({
  path,
  method
}: ToOperationNodeIdArgs): OasOperationId => {
  return `operations|${path}|${method}`
}
