import Box from '@mui/joy/Box'
import {
  SettingsConfigType,
  GenerateConfigType,
  OasOperation
} from '@schematicos/types'
import Typography from '@mui/joy/Typography'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { SettingsLiveData } from 'sections/composer/types'
import DialogContent from '@mui/joy/DialogContent'
import { MethodBadge } from 'components/MethodBadge/MethodBadge'
import { match } from 'ts-pattern'
import { getMuiFormOperationVoidMessage } from 'lib/validation/voidChecks'
import { RtkQueryOperationConfig } from 'sections/composer/settings/RtkQueryOperationForm'
import { MuiFormOperationConfig } from 'sections/composer/settings/MuiFormOperationForm'

type OperationSettingsConfigProps = {
  operation: OasOperation
  generateConfig?: GenerateConfigType
  settingsConfig: SettingsConfigType
  internal$: BehaviorSubject<SettingsLiveData>
}

export const OperationSettingsConfig = ({
  operation,
  generateConfig,
  settingsConfig,
  internal$
}: OperationSettingsConfigProps) => {
  const { summary, path, method } = operation

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      px="32px"
      pt="32px"
      height="100%"
      minHeight="0"
      maxWidth="400px"
      sx={{ overflowY: 'scroll', fontSize: '14px' }}
    >
      <DialogContent sx={{ gap: '32px' }}>
        <Box display="flex" flexDirection="column" gap="2px">
          <Typography
            sx={{ fontSize: '20px', color: 'neutral.700', fontWeight: 500 }}
          >
            {summary}
          </Typography>
          <Box display="flex" gap="8px" alignItems="center">
            <Typography
              sx={{
                fontSize: '14px',
                color: 'neutral.500',
                letterSpacing: '0.2px'
              }}
            >
              {path}
            </Typography>

            <MethodBadge>{method}</MethodBadge>
          </Box>
        </Box>

        {generateConfig?.library.map(library => {
          return match(library)
            .with('rtk-query', () => (
              <RtkQueryOperationConfig
                key={`rtk-query-${path}-${method}`}
                path={path}
                method={method}
                values={settingsConfig.operations?.[path]?.[method]?.[library]}
                internal$={internal$}
              />
            ))
            .with('mui-form', () => (
              <MuiFormOperationConfig
                key={`mui-form-${path}-${method}`}
                voidItemMessage={getMuiFormOperationVoidMessage(operation)}
                path={path}
                method={method}
                values={settingsConfig.operations?.[path]?.[method]?.[library]}
                internal$={internal$}
              />
            ))
            .exhaustive()
        })}
      </DialogContent>
    </Box>
  )
}
