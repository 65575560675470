import { Session } from '@supabase/supabase-js'
import { AppState } from 'components/types'

export type SetSessionAction = {
  type: 'setSession'
  payload: Session | null
}

export const setSession =
  (state: AppState) =>
  ({ payload }: SetSessionAction): AppState => {
    return {
      ...state,
      session: payload
    }
  }
