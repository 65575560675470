import Box from '@mui/joy/Box'
import Switch from '@mui/joy/Switch'
import Typography from '@mui/joy/Typography'
import { Method, RtkQueryConfigType } from '@schematicos/types'
import { SettingsLiveData } from 'sections/composer/types'
import { BehaviorSubject } from 'rxjs'
import { updateOperation } from 'sections/composer/settings/mutators'
import { useEffect, useState } from 'react'

type RtkQueryOperationConfigProps = {
  path: string
  method: Method
  values: RtkQueryConfigType | undefined
  internal$: BehaviorSubject<SettingsLiveData>
}

type RTKQueryFormState = {
  selected: boolean
}

export const RtkQueryOperationConfig = ({
  path,
  method,
  internal$
}: RtkQueryOperationConfigProps) => {
  const [formValues, setFormValues] = useState<RTKQueryFormState>({
    selected: false
  })

  useEffect(() => {
    internal$.subscribe(data => {
      const values =
        data.settingsConfig?.operations[path]?.[method]?.['rtk-query']

      setFormValues(current => ({
        selected: values?.selected ?? current.selected
      }))
    })
  }, [])

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          sx={{
            lineHeight: '16px',
            fontSize: '12px',
            fontWeight: '500',
            color: 'neutral.700',
            textTransform: 'uppercase'
          }}
        >
          RTK Query
        </Typography>

        <Switch
          size="sm"
          checked={formValues.selected}
          onChange={event => {
            updateOperation({
              type: 'property',
              path,
              method,
              propertyName: 'selected',
              property: event.target.checked,
              library: 'rtk-query',
              internal$
            })
          }}
        />
      </Box>
    </Box>
  )
}
