import Box from '@mui/joy/Box'
import Link from '@mui/joy/Link'
import { ReactElement } from 'react'
import { NavLink, Link as RouterLink, useLocation } from 'react-router-dom'
import { SchemaIcon } from 'icons/SchemaIcon'
import Tooltip from '@mui/joy/Tooltip'
import { WorkflowIcon } from 'icons/WorkflowIcon'
import { SchematicLogo } from 'icons/SchematicLogo'
import { TransformerIcon } from 'icons/TransformerIcon'

export const DashboardNavigation = () => (
  <Box display="flex" flexDirection="column" pt="8px" px="8px" gap="8px">
    <Box
      component={RouterLink}
      to="/dashboard"
      display="flex"
      justifyContent="center"
      alignItems="center"
      mx="-8px"
      my="-8px"
    >
      <SchematicLogo
        width="48px"
        height="48px"
        color="var(--joy-palette-neutral-500)"
      />
    </Box>

    <NavItem
      label="Schemas"
      icon={<SchemaIcon width="24px" height="24px" />}
      to="schemas"
    />

    <NavItem
      label="Transformers"
      icon={<TransformerIcon width="24px" height="24px" />}
      to="transformers"
    />

    <NavItem
      label="Workflows"
      icon={<WorkflowIcon width="24px" height="24px" />}
      to="workflows"
    />
  </Box>
)

type NavItemProps = {
  icon: ReactElement
  label: string
  to: string
}

const NavItem = ({ icon, label, to }: NavItemProps) => {
  const location = useLocation()

  const isActive = location.pathname.startsWith(`/dashboard/${to}`)

  return (
    <Tooltip title={label} variant="outlined" arrow placement="right">
      <Link
        component={NavLink}
        to={to}
        display="flex"
        flexDirection="column"
        alignItems="center"
        gap="4px"
        p="8px"
        sx={{
          color: isActive ? 'primary.500' : 'neutral.500',
          textDecoration: 'none',
          textTransform: 'uppercase',
          fontWeight: 700,
          borderRadius: '8px',
          fontSize: '10px',
          lineHeight: '16px',
          letterSpacing: '0.05em',
          backgroundColor: isActive ? 'neutral.200' : 'transparent',
          ':hover': {
            backgroundColor: isActive ? 'neutral.300' : 'neutral.200',
            textDecoration: 'none',
            color: isActive ? 'primary.500' : 'neutral.700'
          }
        }}
      >
        {icon}
      </Link>
    </Tooltip>
  )
}
