import { OasComponentId } from 'sections/composer/types'

type ToModelNodeIdArgs = {
  modelName: string
}

export const toModelNodeId = ({
  modelName
}: ToModelNodeIdArgs): OasComponentId => {
  return `components|models|${modelName}`
}
