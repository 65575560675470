import { Avatar } from 'components/Auth/Avatar'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

export const UserMenu = () => (
  <Dropdown>
    <MenuButton
      slots={{ root: IconButton }}
      slotProps={{ root: { variant: 'plain' } }}
      sx={{ px: 0, borderRadius: '50%' }}
    >
      <Avatar />
    </MenuButton>
    <Menu placement="bottom-end" size="sm">
      <MenuItem onClick={() => void supabaseClient.auth.signOut()}>
        Logout
      </MenuItem>
    </Menu>
  </Dropdown>
)
