import { useNavigate } from 'react-router-dom'
import { Drawer } from 'components/Drawer/Drawer'
import { ConfigureSource } from 'components/ConfigureSource/ConfigureSource'
import { SchemaFormFooter } from 'components/ConfigureSource/SchemaFormFooter'
import { ConfigureSourceFormProvider } from 'components/ConfigureSource/ConfigureSourceFormProvider'
import { DocumentSource } from 'components/ConfigureSource/DocumentSource'

export const CreateSchemaSource = () => {
  const navigate = useNavigate()

  return (
    <ConfigureSourceFormProvider
      initialValues={{ sourceType: null, content: '' }}
    >
      <Drawer title="Edit schema source" onClose={() => navigate('..')}>
        <ConfigureSource />
        <DocumentSource />
        <SchemaFormFooter />
      </Drawer>
    </ConfigureSourceFormProvider>
  )
}
