import { AppState } from 'components/types.ts'

export type RemoveNotificationAction = {
  type: 'removeNotification'
  payload: {
    notificationId: string
  }
}

export const removeNotification =
  (state: AppState) =>
  ({ payload }: RemoveNotificationAction): AppState => ({
    ...state,
    notifications: state.notifications.filter(
      ({ id }) => id !== payload.notificationId
    )
  })
