import { OpenAPIV3 } from 'openapi-types'

export const isRef = (value: unknown): value is OpenAPIV3.ReferenceObject => {
  if (
    value &&
    typeof value === 'object' &&
    '$ref' in value &&
    typeof value.$ref === 'string'
  ) {
    return true
  } else {
    return false
  }
}
