import { useMemo } from 'react'
import { getVersion } from 'lib/getVersion/getVersion'
import * as Sentry from '@sentry/react'

export const useOasVersion = (document: string) => {
  return useMemo(() => {
    if (!document) {
      return undefined
    }

    try {
      const parsed: Record<string, unknown> = JSON.parse(document)
      return getVersion(parsed)
    } catch (error) {
      Sentry.captureException(error)
    }
  }, [document])
}
