import { Outlet } from 'react-router-dom'
import { DashboardNavigation } from 'components/Dashboard/DashboardNavigation'
import Box from '@mui/joy/Box'

export const Dashboard = () => (
  <Box
    display="flex"
    flex={1}
    width="100%"
    sx={{ bgcolor: 'background.level1', minHeight: 0 }}
  >
    <DashboardNavigation />
    <Box
      display="flex"
      flexDirection="column"
      flex={1}
      minHeight={0}
      minWidth={0}
    >
      <Outlet />
    </Box>
  </Box>
)
