import { WorkflowModel, workflowModel } from '@schematicos/types'
import { stripNodes } from 'loaders/workflows/updateWorkflow'
import debounce from 'lodash/debounce'
import { nanoid } from 'nanoid'
import { ReactNode } from 'react'
import { WorkflowProvider } from 'sections/composer/WorkflowProvider'

type OpenWorkflowLoaderProps = {
  children: ReactNode
}

export const OpenWorkflowLoader = ({ children }: OpenWorkflowLoaderProps) => {
  const workflow = loadWorkflow() || createInitialFlow()

  return (
    <WorkflowProvider workflow={workflow} persistWorkflow={persistWorkflow}>
      {children}
    </WorkflowProvider>
  )
}

const createInitialFlow = (): WorkflowModel => {
  const openSchemaNodeId = nanoid()
  const generateNodeId = nanoid()
  const settingsNodeId = nanoid()
  const artifactsNodeId = nanoid()
  const exportNodeId = nanoid()

  return {
    id: 'PLACEHOLDER_ID',
    name: 'New workflow',
    ownerId: 'PLACEHOLDER_OWNER_ID',
    createdAt: new Date().toISOString(),
    content: {
      nodes: [
        {
          id: openSchemaNodeId,
          type: 'open-schema',
          position: {
            x: 25,
            y: 125
          },
          selected: true,
          data: {}
        },
        {
          id: generateNodeId,
          type: 'generate',
          position: {
            x: 300,
            y: 150
          },
          selected: false,
          data: {}
        },
        {
          id: settingsNodeId,
          type: 'settings',
          position: {
            x: 525,
            y: 175
          },
          selected: false,
          data: {}
        },
        {
          id: artifactsNodeId,
          type: 'artifacts',
          position: {
            x: 775,
            y: 200
          },
          selected: false,
          data: {}
        },
        {
          id: exportNodeId,
          type: 'export',
          position: {
            x: 1000,
            y: 225
          },
          selected: false,
          data: {
            exportConfig: {
              type: null,
              repo: '',
              title: '',
              head: '',
              base: ''
            }
          }
        }
      ],
      edges: [
        {
          id: nanoid(),
          source: openSchemaNodeId,
          target: generateNodeId,
          animated: true
        },
        {
          id: nanoid(),
          source: generateNodeId,
          target: settingsNodeId,
          animated: true
        },
        {
          id: nanoid(),
          source: settingsNodeId,
          target: artifactsNodeId,
          animated: true
        },
        {
          id: nanoid(),
          source: artifactsNodeId,
          target: exportNodeId,
          animated: true
        }
      ]
    }
  }
}

const persistWorkflow = debounce((workflow: WorkflowModel) => {
  const strippedWorkflow = {
    ...workflow,
    content: {
      nodes: stripNodes(workflow.content.nodes),
      edges: workflow.content.edges
    }
  }

  localStorage.setItem('workflow', JSON.stringify(strippedWorkflow))
}, 3000)

const loadWorkflow = (): WorkflowModel | undefined => {
  const loaded: string | null = localStorage.getItem('workflow')

  if (!loaded) {
    return undefined
  }

  const json: unknown = JSON.parse(loaded)
  const parsed = workflowModel.parse(json)

  return parsed
}
