import { Dashboard } from 'components/Dashboard/Dashboard'
import { SchemaDetails } from 'sections/schemas/SchemaDetails'
import { SchemaList } from 'sections/schemas/SchemaList'
import { Parent } from 'components/Parent/Parent'
import { Register } from 'components/Auth/Register'
import { createBrowserRouter, Navigate } from 'react-router-dom'
import { Login } from 'components/Auth/Login'
import { ProtectedRoute } from 'components/Auth/ProtectedRoute'
import { DashboardHome } from 'components/Dashboard/DashboardHome'
import { SchemaRoute } from 'sections/schemas/SchemaRoute'
import { Operation } from 'sections/schemas/Operation'
import { SchemaPreview } from 'sections/schemas/SchemaPreview'
import { Transformers } from 'sections/transformers/Transformers'
import { TransformerEditor } from 'sections/transformers/TransformerEditor'
import { EditSchemaSource } from 'sections/schemas/EditSchemaSource'
import { CreateSchemaSource } from 'sections/schemas/CreateSchemaSource'
import { SchemaLoader } from 'sections/schemas/SchemaLoader'
import { WorkflowLoader } from 'sections/composer/WorkflowLoader'
import { Workflow } from 'sections/composer/Workflow'
import { Workflows } from 'sections/workflows/Workflows'
import { TransformerLoader } from 'sections/transformers/TransformerLoader'
import { OpenWorkflowLoader } from 'sections/composer/OpenWorkflowLoader'

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Parent />,
    children: [
      {
        index: true,
        element: <Navigate to="/open" />
      },
      {
        path: 'register',
        element: <Register />
      },
      {
        path: 'login',
        element: <Login />
      },
      {
        path: 'dashboard',
        element: (
          <ProtectedRoute>
            <Dashboard />
          </ProtectedRoute>
        ),
        children: [
          {
            index: true,
            element: <DashboardHome />
          },
          {
            path: 'schemas',
            element: <SchemaList />,
            children: [
              {
                path: 'new/config',
                element: <CreateSchemaSource />
              },
              {
                path: ':schemaId/config',
                element: <EditSchemaSource />
              }
            ]
          },
          {
            path: 'schemas/:schemaId',
            element: (
              <SchemaLoader>
                <SchemaDetails />
              </SchemaLoader>
            ),
            children: [
              {
                path: 'operations/:path/:method',
                element: <Operation />
              },
              {
                path: 'components/schemas/:schema',
                element: <SchemaRoute />
              },
              {
                path: 'definitions/:definition',
                element: <SchemaRoute />
              }
            ]
          },
          {
            path: 'transformers',
            element: <Transformers />
          },
          {
            path: 'transformers/:transformerId',
            element: (
              <TransformerLoader>
                <TransformerEditor />
              </TransformerLoader>
            )
          },
          {
            path: 'preview/:schemaId',
            element: (
              <SchemaLoader>
                <SchemaPreview />
              </SchemaLoader>
            )
          },
          {
            path: 'workflows',
            element: <Workflows />
          },
          {
            path: 'workflows/:workflowId',
            element: (
              <WorkflowLoader>
                <Workflow />
              </WorkflowLoader>
            )
          }
        ]
      },
      {
        path: 'open',
        element: <Dashboard />,
        children: [
          {
            index: true,
            element: (
              <OpenWorkflowLoader>
                <Workflow />
              </OpenWorkflowLoader>
            )
          }
        ]
      }
    ]
  }
])
