import { OasResponse, OasResponseRef } from '@schematicos/types'
import Tabs from '@mui/joy/Tabs'
import TabList from '@mui/joy/TabList'
import Tab, { tabClasses } from '@mui/joy/Tab'
import TabPanel from '@mui/joy/TabPanel'
import { SectionTitle } from 'sections/schemas/SectionTitle'
import Box from '@mui/joy/Box'
import { MarkdownDescription } from 'sections/schemas/MarkdownDescription'
import Typography from '@mui/joy/Typography'

type ResponsesProps = {
  responses: Record<string, OasResponse | OasResponseRef>
}

export const Responses = ({ responses }: ResponsesProps) => {
  const responseValues = Object.values(responses)

  if (!responseValues.length) {
    return null
  }

  return (
    <Box display="flex" flexDirection="column" gap="8px">
      <SectionTitle>Responses</SectionTitle>

      <Tabs
        aria-label="Basic tabs"
        defaultValue={0}
        sx={{ bgcolor: 'transparent' }}
      >
        <TabList
          sx={{
            [`& .${tabClasses.root}[aria-selected="true"]`]: {
              bgcolor: 'transparent'
            }
          }}
        >
          {Object.keys(responses ?? {}).map(key => (
            <Tab
              key={key}
              sx={{
                fontSize: '14px',
                borderTopLeftRadius: '4px',
                borderTopRightRadius: '4px'
              }}
            >
              {key}
            </Tab>
          ))}
        </TabList>
        {Object.values(responses ?? {}).map((value, index) => (
          <TabPanel
            key={index}
            value={index}
            sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}
          >
            <MarkdownDescription
              description={value.description}
              sx={{ fontSize: '14px' }}
            />
            <Box display="flex">
              <Box display="flex" flex={2}>
                <Typography
                  fontSize="14px"
                  fontWeight={500}
                  sx={{
                    color: 'neutral.700'
                  }}
                >
                  Stuff
                </Typography>
              </Box>
              <Box display="flex" flexDirection="column" flex={1}>
                <Typography
                  fontSize="14px"
                  fontWeight={500}
                  sx={{
                    color: 'neutral.700'
                  }}
                >
                  Example - use dropdown for content options
                </Typography>

                <Box component="pre" sx={{ fontSize: '14px' }}>
                  {value.schematicType === 'response' ? (
                    <code>{JSON.stringify(value, null, 2)}</code>
                  ) : null}
                </Box>
              </Box>
            </Box>
          </TabPanel>
        ))}
      </Tabs>
    </Box>
  )
}
