import Input from '@mui/joy/Input'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { UrlSourceFormValues } from '@schematicos/types'
import { useEffect } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useFetchError } from 'components/useFetchError/useFetchError'

export const ExternalUrl = () => {
  const { control, watch, setValue } = useFormContext<UrlSourceFormValues>()

  const { handleFetchError } = useFetchError()

  const url = watch('url')

  useEffect(() => {
    setValue('content', '')
  }, [url])

  useEffect(() => {
    if (!url) {
      return
    }

    fetch(url)
      .then(res => {
        return Promise.all([
          res.text(),
          Promise.resolve(res.headers.get('content-type'))
        ])
      })
      .then(([content, contentType]) => {
        if (contentType === 'application/json') {
          const parsed = JSON.parse(content)
          setValue('content', JSON.stringify(parsed, null, 2))
        } else {
          setValue('content', content)
        }
      })
      .catch(handleFetchError('Failed to load schema'))
  }, [url])

  return (
    <Controller
      name="url"
      control={control}
      defaultValue=""
      render={({ field: { onChange, ...field } }) => (
        <FormControl sx={{ py: '8px', px: '12px' }}>
          <FormLabel>OpenAPI schema url</FormLabel>
          <Input
            {...field}
            size="sm"
            onChange={onChange}
            placeholder="https://..."
          />
        </FormControl>
      )}
    />
  )
}
