import { MutableRefObject } from 'react'
import { WorkflowModel, WorkflowNode, workflowModel } from '@schematicos/types'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

type UpdateWorkflowArgs = {
  loadingRef: MutableRefObject<boolean>
  firstCallRef: MutableRefObject<boolean>
  onError: (title: string) => (error: unknown) => void
  onSuccess: (workflow: WorkflowModel) => void
}

export const updateWorkflow =
  ({ loadingRef, firstCallRef, onError, onSuccess }: UpdateWorkflowArgs) =>
  (workflow: WorkflowModel) => {
    if (loadingRef.current) {
      return
    }

    // First call is made by useEffect without any
    // changes that need saving
    if (firstCallRef.current) {
      firstCallRef.current = false
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke(`workflows/${workflow.id}`, {
        method: 'PUT',
        body: {
          ...workflow,
          content: {
            ...workflow.content,
            nodes: stripNodes(workflow.content.nodes)
          }
        }
      })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = workflowModel.parse(data)

        onSuccess(parsed)
      })
      .catch(onError('Failed to update workflow'))
      .finally(() => (loadingRef.current = false))
  }

export const stripNodes = (nodes: WorkflowNode[]) => {
  return nodes.map(node => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { parentNode, ...cleanNode } = node
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const { internal$, inbound$, outbound$, ...data } = node.data

    return {
      ...cleanNode,
      data
    }
  })
}
