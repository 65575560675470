import Box from '@mui/joy/Box'
import Typography from '@mui/joy/Typography'
import { useApp } from 'components/AppProvider/AppProvider'
import { Avatar } from 'components/Auth/Avatar'

export const SignedIn = () => {
  const { state } = useApp()
  const { session } = state

  if (!session) {
    return null
  }

  return (
    <Box display="flex" alignItems="center" gap="4px">
      <Typography sx={{ color: 'neutral.500' }}>Signed in as</Typography>

      <Typography sx={{ color: 'neutral.900' }}>
        {session.user.user_metadata.full_name}
      </Typography>
      <Avatar />
    </Box>
  )
}
