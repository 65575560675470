import CardContent from '@mui/joy/CardContent'
import Typography from '@mui/joy/Typography'
import { TransformerModel } from '@schematicos/types'
import { parseISO } from 'date-fns/parseISO'
import { format } from 'date-fns/format'
import { ViewCard } from 'components/Card/ViewCard'

type ViewTransformerCardProps = {
  transformer: TransformerModel
}

export const ViewTransformerCard = ({
  transformer
}: ViewTransformerCardProps) => (
  <ViewCard to={transformer.id}>
    <CardContent>
      <Typography level="title-lg" sx={{ color: 'neutral.700' }}>
        {transformer.name}
      </Typography>
      <Typography level="body-md" sx={{ color: 'neutral.500' }}>
        {transformer.description}
      </Typography>
      <Typography
        level="body-sm"
        aria-describedby="card-description"
        sx={{ color: 'neutral.400', fontWeight: 500 }}
      >
        {format(parseISO(transformer.createdAt), 'MMM dd, yyyy')}
      </Typography>
    </CardContent>
  </ViewCard>
)
