import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem, TreeItemLabel } from 'components/TreeItem/TreeItem.tsx'
import Box from '@mui/joy/Box'
import IconButton from '@mui/joy/IconButton'
import { AddFileIcon } from 'icons/AddFileIcon'
import { PathEditorItem } from 'components/Paths/PathEditorItem'
import { PathEditorItemProviderProvider } from 'components/Paths/PathEditorItemProvider'
import { useTransformer } from 'sections/transformers/TransformerProvider'

type PathsEditor = {
  parentLabel: string
  contentMap?: Record<string, string>
}

export const PathsEditor = ({ parentLabel, contentMap }: PathsEditor) => {
  const { state, dispatch } = useTransformer()
  const { hasNewItem, transformer } = state

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <TreeView
        aria-label={parentLabel}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={[parentLabel]}
        defaultSelected={Object.keys(transformer.content)[0]}
        sx={{
          height: '100%',
          flexGrow: 1,
          overflowY: 'auto',
          whiteSpace: 'nowrap'
        }}
        onNodeSelect={(_event, nodeId) => {
          dispatch({
            type: 'selectFilePath',
            payload: { filePath: nodeId, newFileSelected: false }
          })
        }}
      >
        <TreeItem
          key="label"
          nodeId={parentLabel}
          label={
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <TreeItemLabel>{parentLabel}</TreeItemLabel>
              <IconButton
                onClick={event => {
                  event.stopPropagation()

                  dispatch({
                    type: 'setHasNewItem',
                    payload: { hasNewItem: true }
                  })
                }}
                sx={{
                  borderRadius: '50%',
                  minWidth: '28px',
                  minHeight: '28px',
                  height: '28px',
                  alignSelf: 'center'
                }}
              >
                <AddFileIcon width="16px" height="16px" />
              </IconButton>
            </Box>
          }
        >
          {Object.keys(contentMap ?? {}).map(path => (
            <PathEditorItemProviderProvider key={path} path={path}>
              <PathEditorItem />
            </PathEditorItemProviderProvider>
          ))}
          {hasNewItem ? (
            <PathEditorItemProviderProvider key="__NEW_ITEM__" path="" renaming>
              <PathEditorItem />
            </PathEditorItemProviderProvider>
          ) : null}
        </TreeItem>
      </TreeView>
    </Box>
  )
}
