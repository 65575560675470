import {
  SettingsConfigType,
  GenerateConfigType,
  OasRoot,
  PrettierConfigType,
  artifactsMapModel
} from '@schematicos/types'

type GenerateActionFnArgs = {
  generateConfig: GenerateConfigType
  schemaModel: OasRoot
  settingsConfig?: SettingsConfigType
}

export const artifactsActionFn = async ({
  generateConfig,
  schemaModel,
  settingsConfig
}: GenerateActionFnArgs) => {
  const res = await fetch(`${import.meta.env.VITE_API_ORIGIN}/api/generate`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      generateConfig,
      schemaModel,
      settingsConfig,
      prettierConfig
    })
  })

  const { data, error } = await res.json()

  if (error) {
    throw error
  }

  try {
    return artifactsMapModel.parse(data)
  } catch (e) {
    console.error(e)
    throw e
  }
}

const prettierConfig: PrettierConfigType = {
  tabWidth: 2,
  useTabs: false,
  semi: false,
  singleQuote: true,
  trailingComma: 'none',
  bracketSpacing: true,
  arrowParens: 'avoid'
}
