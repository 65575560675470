import { oasRoot } from '@schematicos/types'

type ParseSchemaArgs = {
  schemaDocument: string
  schemaFormat: 'json' | 'yaml'
}

const parseSchema = async ({
  schemaDocument,
  schemaFormat
}: ParseSchemaArgs) => {
  const res = await fetch(`${import.meta.env.VITE_API_ORIGIN}/api/parse`, {
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json'
    },
    method: 'POST',
    body: JSON.stringify({
      schemaDocument,
      schemaFormat
    })
  })
  const { data, error } = await res.json()

  if (error) {
    throw error
  }

  return oasRoot.parse(data)
}

type SchemaActionFnArgs = {
  schemaDocument: string
  schemaFormat: 'json' | 'yaml'
}

export const openSchemaActionFn = async ({
  schemaDocument,
  schemaFormat
}: SchemaActionFnArgs) => {
  const schemaModel = await parseSchema({
    schemaDocument,
    schemaFormat
  })

  return {
    schemaModel
  }
}
