export const getVersion = (document: Record<string, unknown>) => {
  if (
    'openapi' in document &&
    typeof document.openapi === 'string' &&
    document.openapi.startsWith('3.0')
  ) {
    return 'v3'
  }

  if (
    'swagger' in document &&
    typeof document.swagger === 'string' &&
    document.swagger.startsWith('2.0')
  ) {
    return 'v2'
  }
}
