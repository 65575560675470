import Box from '@mui/joy/Box'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import {
  Controller,
  FormProvider,
  SubmitHandler,
  useForm,
  useFormContext
} from 'react-hook-form'
import Radio from '@mui/joy/Radio'
import RadioGroup from '@mui/joy/RadioGroup'
import DialogContent from '@mui/joy/DialogContent'
import { useEffect, useRef, useState } from 'react'
import {
  ExportConfigType,
  GenerateConfigType,
  OasRoot,
  exportConfigType
} from '@schematicos/types'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { ExportLiveData } from 'sections/composer/types'
import { Observable } from 'rxjs/internal/Observable'
import JsZip from 'jszip'
import { useInboundObservable } from 'sections/composer/useInboundObservable'
import Button from '@mui/joy/Button'
import { DownloadIcon } from 'icons/DownloadIcon'
import { format } from 'date-fns/format'
import { generateLibraries } from 'sections/composer/generate/generateOptions'
import * as Sentry from '@sentry/react'
import { useApp } from 'components/AppProvider/AppProvider'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'
import { GithubRepos } from 'components/ConfigureSource/GithubRepos'
import Input from '@mui/joy/Input'
import { match, P } from 'ts-pattern'
import { useFetchError } from 'components/useFetchError/useFetchError'
import { createPullRequest } from 'loaders/github/createPullRequest'
import invariant from 'tiny-invariant'
import { nanoid } from 'nanoid'
import Link from '@mui/joy/Link'

type ExportDrawerContentProps = {
  inbound$: Observable<Record<string, unknown>>
  internal$: BehaviorSubject<ExportLiveData>
}

export const ExportDrawerContent = ({
  inbound$,
  internal$
}: ExportDrawerContentProps) => {
  const loadingRef = useRef(false)
  const { handleFetchError } = useFetchError()
  const { exportConfig } = useInternalSubject<ExportLiveData>(internal$)
  const { dispatch } = useApp()

  const inbound = useInboundObservable<{
    artifactsMap?: Record<string, string>
    schemaModel?: OasRoot
    generateConfig?: GenerateConfigType
  }>(inbound$)

  const { artifactsMap, schemaModel, generateConfig } = inbound ?? {}

  const form = useForm<ExportConfigType>({
    defaultValues: {
      type: exportConfig?.type ?? null,
      repo: exportConfig?.repo ?? '',
      title: exportConfig?.title ?? '',
      head: exportConfig?.head ?? '',
      base: exportConfig?.base ?? ''
    }
  })

  const { control, watch, getValues } = form

  const { type } = getValues()

  useEffect(() => {
    const subscription = watch((value: unknown) => {
      internal$.next({ exportConfig: exportConfigType.parse(value) })
    })

    return () => subscription.unsubscribe()
  }, [watch])

  const createPullRequestFn = createPullRequest({
    loadingRef,
    onError: handleFetchError,
    onSuccess: pullRequestUrl => {
      dispatch({
        type: 'addNotification',
        payload: {
          id: nanoid(),
          type: 'success',
          title: 'Pull request created',
          body: (
            <Link href={pullRequestUrl} target="_blank">
              {pullRequestUrl}
            </Link>
          )
        }
      })
    }
  })

  const onSubmit: SubmitHandler<ExportConfigType> = (formData, event) => {
    event?.preventDefault()

    const { repo: r, title, head, base } = formData

    invariant(r, 'Repo is required')
    invariant(title, 'Title is required')
    invariant(head, 'Head is required')
    invariant(base, 'Base is required')
    invariant(artifactsMap, 'Artifacts map is required')

    const [owner, repo] = r.split('/')

    createPullRequestFn({
      owner,
      repo,
      title,
      head,
      base,
      files: artifactsMap
    })
  }

  return (
    <FormProvider {...form}>
      <Box
        component="form"
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={form.handleSubmit(onSubmit)}
        display="flex"
        minHeight={0}
        flex={1}
        pt="16px"
        px="12px"
      >
        <Box
          display="flex"
          flexDirection="column"
          minHeight={0}
          flex="none"
          width="300px"
        >
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <FormControl sx={{ py: '8px', px: '12px', gap: '4px' }}>
                <FormLabel
                  sx={{
                    fontSize: 'sm'
                  }}
                >
                  Export code to
                </FormLabel>

                <RadioGroup {...field} sx={{ gap: '12px', marginTop: 0 }}>
                  <Radio
                    label="Github pull request"
                    value="github"
                    size="sm"
                    sx={{
                      marginBlockStart: '0',
                      marginLeft: '0',
                      paddingLeft: '8px',
                      gap: '8px'
                    }}
                  />

                  <Radio
                    label="Download zip file"
                    value="zip"
                    size="sm"
                    sx={{
                      marginBlockStart: '0',
                      marginLeft: '0',
                      paddingLeft: '8px',
                      gap: '8px'
                    }}
                  />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Box>
        <DialogContent>
          <Box
            height="100%"
            display="flex"
            flexDirection="column"
            maxWidth="400px"
          >
            {match(type)
              .with('zip', () => (
                <Box display="flex" pt="34px" justifyContent="center">
                  <DownloadZipButton
                    artifactsMap={artifactsMap}
                    schemaModel={schemaModel}
                    generateConfig={generateConfig}
                  />
                </Box>
              ))
              .with('github', () => <GithubExportDestination />)
              .with(P.nullish, () => null)
              .exhaustive()}
          </Box>
        </DialogContent>
      </Box>
    </FormProvider>
  )
}

const GithubExportDestination = () => {
  const { state } = useApp()
  const { session } = state
  const { control, getValues } = useFormContext<ExportConfigType>()
  const authRef = useRef<HTMLDivElement>(null)
  const values = getValues()

  useEffect(() => {
    if (!authRef.current) {
      return
    }

    const icon = document.querySelector('.supabase-auth-ui_ui-button svg')

    icon?.setAttribute('width', '20px')
    icon?.setAttribute('height', '20px')
    icon?.setAttribute('fill', 'white')
  })

  if (values.type !== 'github') {
    return null
  }

  if (!session) {
    return (
      <Box ref={authRef} sx={{ py: '24px' }}>
        <Button
          onClick={() => {
            const redirectTo = `${import.meta.env.VITE_API_ORIGIN}/open`

            console.log('redirectTo', redirectTo)

            supabaseClient.auth
              .signInWithOAuth({
                provider: 'github',
                options: {
                  redirectTo,
                  scopes: 'repo'
                }
              })

              .catch(error => Sentry.captureException(error))
          }}
        >
          Github
        </Button>
      </Box>
    )
  }

  return (
    <>
      <GithubRepos />

      <Controller
        name="base"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ py: '8px', px: '12px' }}>
            <FormLabel>Destination branch</FormLabel>
            <Input {...field} size="sm" placeholder="main" />
          </FormControl>
        )}
      />

      <Controller
        name="title"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ py: '8px', px: '12px' }}>
            <FormLabel>Pull request title</FormLabel>
            <Input
              {...field}
              size="sm"
              placeholder="[Schematic] RTK Query codegen"
            />
          </FormControl>
        )}
      />

      <Controller
        name="head"
        control={control}
        render={({ field }) => (
          <FormControl sx={{ py: '8px', px: '12px' }}>
            <FormLabel>Branch name for commit</FormLabel>
            <Input
              {...field}
              size="sm"
              placeholder="schematic-rtk-query-codegen"
            />
          </FormControl>
        )}
      />

      <Box
        display="flex"
        flexDirection="column"
        sx={{ py: '16px', px: '12px' }}
        width="100%"
      >
        <Button type="submit" size="lg">
          Create pull request
        </Button>
      </Box>
    </>
  )
}

type DownloadZipButtonProps = {
  generateConfig?: GenerateConfigType
  artifactsMap?: Record<string, string>
  schemaModel?: OasRoot
}

const DownloadZipButton = ({
  generateConfig,
  artifactsMap,
  schemaModel
}: DownloadZipButtonProps) => {
  const [zipFile, setZipFile] = useState<JsZip | null>(null)

  useEffect(() => {
    if (!artifactsMap || typeof artifactsMap !== 'object') {
      return
    }

    const zip = new JsZip()

    Object.entries(artifactsMap).forEach(([key, value]) => {
      zip.file(key, value)
    })

    setZipFile(zip)
  }, [artifactsMap])

  return (
    <Button
      sx={{ width: '200px', selfAlign: 'center' }}
      onClick={event => {
        event.preventDefault()

        const transformers = generateConfig?.library
          ?.map(transformer => generateLibraries[transformer])
          .join(', ')

        zipFile
          ?.generateAsync({ type: 'blob' })
          .then(blob => {
            const blobUrl = URL.createObjectURL(blob)
            const a = document.createElement('a')
            a.href = blobUrl
            a.download = `Schematic - ${schemaModel?.info?.title} (${transformers})- ${format(new Date(), `YYY-MM-dd HH-mm-ss`)}.zip`
            document.body.appendChild(a)
            a.click()
            a.remove()
          })
          .catch(error => Sentry.captureException(error))
      }}
      size="lg"
      startDecorator={<DownloadIcon width="20px" height="20px" />}
    >
      Download
    </Button>
  )
}

{
  /* <Snackbar
        variant="outlined"
        color="success"
        startDecorator={<DoneIcon />}
        open={Boolean(pullRequestUrl)}
        onClose={() => setPullRequestUrl(undefined)}
        autoHideDuration={6000}
      >
        <Box display="flex" flexDirection="column">
          <Typography level="title-sm">Pull request created</Typography>
          <Typography level="body-sm">
            <Link href={pullRequestUrl} target="_blank">
              {pullRequestUrl}
            </Link>
          </Typography>
        </Box>
      </Snackbar> */
}
