import {
  Node,
  Edge,
  EdgeChange,
  OnNodesChange,
  OnEdgesChange,
  applyNodeChanges,
  applyEdgeChanges,
  XYPosition
} from 'reactflow'
import { create } from 'zustand'
import { nanoid } from 'nanoid'
import {
  MenuLiveData,
  NodeType,
  SchematicFlowNode,
  SchematicFlowNodeData,
  ViableParentNode
} from 'sections/composer/types'
import {
  createBaseNode,
  createChildNode,
  createSettingsNode
} from 'sections/composer/core/createNode'
import { match, P } from 'ts-pattern'

type InitializeArgs = {
  nodes?: SchematicFlowNode[]
  edges?: Edge[]
}

type AddChildNodeArgs = {
  parentNode: ViableParentNode
  position: XYPosition
}

type SelectMenuNodeArgs = {
  menuNodeId: string
  menuNodeData: MenuLiveData
  menuNodePosition: XYPosition
  selectedNodeType: NodeType
}

export type RFState = {
  nodes: SchematicFlowNode[]
  edges: Edge[]
  onNodesChange: OnNodesChange
  onEdgesChange: OnEdgesChange
  deleteNode: (nodeId: string) => void
  selectMenuNode: (args: SelectMenuNodeArgs) => void
  addChildNode: ({ parentNode, position }: AddChildNodeArgs) => void
  initialize: ({ nodes, edges }: InitializeArgs) => void
}

export const useStore = create<RFState>((set, get) => ({
  deleteNode: nodeId => {
    set({
      nodes: get().nodes.filter(node => node.id !== nodeId)
    })
  },
  selectMenuNode: ({
    menuNodeId,
    menuNodeData,
    menuNodePosition,
    selectedNodeType
  }) => {
    const { outbound$, parentNodeId } = menuNodeData

    const baseNode = createBaseNode({
      parentNodeId: parentNodeId,
      position: menuNodePosition
    })

    const newEdge = {
      id: nanoid(),
      source: parentNodeId,
      target: baseNode.id,
      animated: true
    }

    const selectedNode = match(selectedNodeType)
      .with('settings', () => {
        return createSettingsNode({ autoSelect: true, outbound$ })
      })
      .otherwise(matched => {
        throw new Error(`Menu node type not implemented: ${matched}`)
      })

    set({
      nodes: [
        ...get().nodes.filter(({ id }) => id !== menuNodeId),
        { ...baseNode, ...selectedNode }
      ],
      edges: [...get().edges, newEdge]
    })
  },
  addChildNode: ({
    parentNode,
    position
  }: AddChildNodeArgs): SchematicFlowNode | null => {
    const newNode = createChildNode({ autoSelect: true, parentNode, position })

    match(newNode)
      .with(P.nullish, () => null)
      .with({ type: 'menu' }, (matched: Node<MenuLiveData, 'menu'>) => {
        set({
          nodes: [...get().nodes, matched]
        })
      })
      .otherwise(matched => {
        const newEdge = {
          id: nanoid(),
          source: parentNode.id,
          target: matched.id,
          animated: true
        }

        set({
          nodes: [...get().nodes, matched],
          edges: [...get().edges, newEdge]
        })
      })

    return newNode
  },
  initialize: ({ nodes = [], edges = [] }) => {
    set({
      nodes,
      edges
    })
  },
  nodes: [],
  edges: [],
  onNodesChange: changes => {
    set({
      nodes: applyNodeChanges<SchematicFlowNodeData>(
        changes,
        get().nodes as Node<SchematicFlowNodeData>[]
      ) as SchematicFlowNode[]
    })
  },
  onEdgesChange: (changes: EdgeChange[]) => {
    set({
      edges: applyEdgeChanges(changes, get().edges)
    })
  }
}))
