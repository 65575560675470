import { SettingsDrawerContent } from 'sections/composer/settings/SettingsDrawerContent'
import { SchemaDrawerContent } from 'sections/composer/schema/SchemaDrawerContent'
import { Fragment, useState } from 'react'
import { useReactFlow, Node, useOnSelectionChange } from 'reactflow'
import { match } from 'ts-pattern'
import {
  SchematicFlowNode,
  SchematicFlowNodeData
} from 'sections/composer/types'
import { BottomDrawer } from 'components/Drawer/BottomDrawer'
import { GenerateDrawerContent } from 'sections/composer/generate/GenerateDrawerContent'
import { ArtifactsDrawerContent } from 'sections/composer/artifacts/ArtifactsDrawerContent'
import Box from '@mui/joy/Box'
import DialogTitle from '@mui/joy/DialogTitle'
import PushPinIcon from '@mui/icons-material/PushPin'
import IconButton from '@mui/joy/IconButton'
import Divider from '@mui/joy/Divider'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import { OpenSchemaDrawerContent } from 'sections/composer/openSchema/OpenSchemaDrawerContent'
import { ExportDrawerContent } from 'sections/composer/export/ExportDrawerContent'
import { z } from 'zod'

const pinnedType = z.array(z.string())

const getPinned = () => {
  const pinned = localStorage.getItem('pinned') || '[]'

  const json = JSON.parse(pinned)

  return pinnedType.parse(json)
}

const usePinned = () => {
  const [pinned, setP] = useState<string[]>(getPinned())

  const setPinned = (newPinned: string[]) => {
    localStorage.setItem('pinned', JSON.stringify(newPinned))
    setP(newPinned)
  }

  return { pinned, setPinned }
}

export const ComposerDrawer = () => {
  const { getNodes, setNodes } = useReactFlow<SchematicFlowNodeData>()

  const { pinned, setPinned } = usePinned()

  const pinnedNodes = getNodes().filter(
    ({ id, selected }) => !selected && pinned.includes(id)
  )

  const [selectedNodes, setSelectedNodes] = useState<
    Node<SchematicFlowNodeData>[]
  >([])

  useOnSelectionChange({
    onChange: ({ nodes }) => setSelectedNodes(nodes)
  })

  const drawers = [...selectedNodes, ...pinnedNodes]
    .map(node => {
      return nodeToDrawerContent({
        node: node as SchematicFlowNode,
        pinned,
        setPinned
      })
    })
    .filter((node): node is JSX.Element => Boolean(node))

  const closeDrawer = () => {
    setNodes(nodes => {
      return nodes.map(node => ({
        ...node,
        selected: false
      }))
    })
  }

  return (
    <BottomDrawer open={Boolean(drawers.length > 0)} onClose={closeDrawer}>
      <DrawerContent drawers={drawers} />
    </BottomDrawer>
  )
}

type DrawerContentProps = {
  drawers: JSX.Element[]
}

const DrawerContent = ({ drawers }: DrawerContentProps) => {
  if (drawers.length === 0) {
    return null
  }

  if (drawers.length === 1) {
    return drawers[0]
  }

  return (
    <PanelGroup direction="horizontal">
      {drawers.map((drawer, index) => (
        <Fragment key={index}>
          <Panel minSize={25} order={index + 1}>
            {drawer}
          </Panel>
          {index < drawers.length - 1 && (
            <PanelResizeHandle
              style={{
                width: '2px',
                height: '100%',
                background: 'var(--joy-palette-neutral-200, #DDE7EE)'
              }}
            />
          )}
        </Fragment>
      ))}
    </PanelGroup>
  )
}

type NodeToDrawerContentArgs = {
  node: SchematicFlowNode
  pinned: string[]
  setPinned: (pinned: string[]) => void
}

const nodeToDrawerContent = ({
  node,
  pinned,
  setPinned
}: NodeToDrawerContentArgs) => {
  const isPinned = pinned.includes(node.id)

  const togglePin = () => {
    setPinned(
      isPinned ? pinned.filter(type => type !== node.id) : [node.id, ...pinned]
    )
  }

  return match(node)
    .with({ type: 'settings' }, matched => (
      <>
        <ComposerDrawerTitleBar isPinned={isPinned} togglePin={togglePin}>
          Settings
        </ComposerDrawerTitleBar>
        <Divider />
        <SettingsDrawerContent
          internal$={matched.data.internal$}
          inbound$={matched.data.inbound$}
        />
      </>
    ))
    .with({ type: 'export' }, matched => (
      <>
        <ComposerDrawerTitleBar isPinned={isPinned} togglePin={togglePin}>
          Export
        </ComposerDrawerTitleBar>
        <Divider />
        <ExportDrawerContent
          internal$={matched.data.internal$}
          inbound$={matched.data.inbound$}
        />
      </>
    ))
    .with({ type: 'schema' }, matched => (
      <>
        <ComposerDrawerTitleBar isPinned={isPinned} togglePin={togglePin}>
          Schema
        </ComposerDrawerTitleBar>
        <Divider />
        <SchemaDrawerContent internal$={matched.data.internal$} />
      </>
    ))
    .with({ type: 'open-schema' }, matched => (
      <>
        <ComposerDrawerTitleBar isPinned={isPinned} togglePin={togglePin}>
          Schema (open)
        </ComposerDrawerTitleBar>
        <Divider />
        <OpenSchemaDrawerContent internal$={matched.data.internal$} />
      </>
    ))
    .with({ type: 'generate' }, matched => (
      <>
        <ComposerDrawerTitleBar isPinned={isPinned} togglePin={togglePin}>
          Generate
        </ComposerDrawerTitleBar>
        <Divider />
        <GenerateDrawerContent internal$={matched.data.internal$} />
      </>
    ))
    .with({ type: 'artifacts' }, matched => (
      <>
        <ComposerDrawerTitleBar isPinned={isPinned} togglePin={togglePin}>
          Artifacts
        </ComposerDrawerTitleBar>
        <Divider />
        <ArtifactsDrawerContent internal$={matched.data.internal$} />
      </>
    ))
    .otherwise(() => null)
}

type ComposerDrawerTitleBarProps = {
  isPinned: boolean
  togglePin: () => void
  children: string
}

const ComposerDrawerTitleBar = ({
  isPinned,
  togglePin,
  children
}: ComposerDrawerTitleBarProps) => (
  <Box
    display="flex"
    py="8px"
    px="12px"
    justifyContent="space-between"
    alignItems="center"
  >
    <DialogTitle
      sx={{
        fontWeight: 600,
        fontSize: '12px',
        textTransform: 'uppercase',
        letterSpacing: '1px'
      }}
    >
      {children}
    </DialogTitle>
    <IconButton
      variant={isPinned ? 'solid' : 'plain'}
      onClick={() => togglePin()}
      sx={{
        minWidth: '12px',
        minHeight: '12px',
        p: '2px',
        borderRadius: '50%'
      }}
    >
      <PushPinIcon sx={{ width: '12px', height: '12px' }} />
    </IconButton>
  </Box>
)
