import { MutableRefObject } from 'react'
import { PullRequestType } from '@schematicos/types'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

type CreatePullRequestArgs = {
  loadingRef: MutableRefObject<boolean>
  onError: (title: string) => (error: unknown) => void
  onSuccess: (pullRequestUrl: string) => void
}

export const createPullRequest =
  ({ loadingRef, onError, onSuccess }: CreatePullRequestArgs) =>
  (pullRequest: PullRequestType) => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke('/github/pull-requests', {
        method: 'POST',
        body: pullRequest
      })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          typeof data?.data?.html_url === 'string'
        ) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          onSuccess(data.data.html_url as string)
        } else {
          throw new Error('Invalid response from server')
        }
      })
      .catch(onError('Failed to create pull request'))
      .finally(() => (loadingRef.current = false))
  }
