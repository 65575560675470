import Typography from '@mui/joy/Typography'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useApp } from 'components/AppProvider/AppProvider'
import { LeftHalf } from 'components/Layout/LeftHalf'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'
import { Navigate, useLocation, Location } from 'react-router-dom'

type Comeback = {
  comeback?: Location
}

export const Login = () => {
  const { state } = useApp()
  const { session } = state

  const { state: locationState }: Location<Comeback> = useLocation()
  const { comeback } = locationState ?? {}

  return (
    <LeftHalf>
      <Typography
        level="h2"
        sx={{ display: 'flex', flex: 'none', flexDirection: 'column' }}
      >
        Log in with Github
      </Typography>

      {!session ? (
        <Auth
          supabaseClient={supabaseClient}
          appearance={{ theme: ThemeSupa }}
          providers={['github']}
          providerScopes={{ github: 'repo' }}
          onlyThirdPartyProviders
        />
      ) : (
        <Navigate to={comeback ? comeback : '/dashboard'} />
      )}
    </LeftHalf>
  )
}
