import { SettingsConfigType, OasRoot } from '@schematicos/types'
import { toModelNodeId } from 'components/ApiPaths/toComponentNodeId copy'
import { TreeItem, TreeItemLabel } from 'components/TreeItem/TreeItem.tsx'
import { TreeItemIcon } from 'components/TreeItem/TreeItemIcon'
import { FC } from 'react'
import { NodeId } from 'sections/composer/types'

type ComponentsNavigationProps = {
  query: string
  schemaModel: OasRoot
  settingsConfig?: SettingsConfigType
  toggleModel?: (modelName: string) => void
}

export const ComponentsNavigation: FC<ComponentsNavigationProps> = ({
  toggleModel,
  settingsConfig,
  query,
  schemaModel
}) => {
  const modelNames = Object.keys(schemaModel?.components?.models ?? {}).filter(
    modelName =>
      !query.length ||
      modelName.toLocaleLowerCase().includes(query.toLocaleLowerCase())
  )

  if (!modelNames.length) {
    return null
  }

  return (
    <TreeItem
      key="components"
      nodeId="Components"
      label={<TreeItemLabel>Components</TreeItemLabel>}
    >
      <TreeItem
        key="models"
        nodeId="Schemas"
        label={<TreeItemLabel>Schemas</TreeItemLabel>}
      >
        {modelNames.map(modelName => {
          const nodeId: NodeId = toModelNodeId({ modelName })

          return (
            <TreeItem
              key={modelName}
              nodeId={nodeId}
              label={<TreeItemLabel>{modelName}</TreeItemLabel>}
              icon={
                toggleModel ? (
                  <TreeItemIcon
                    type="model"
                    modelName={modelName}
                    toggleModel={toggleModel}
                    settingsConfig={settingsConfig}
                  />
                ) : null
              }
            />
          )
        })}
      </TreeItem>
    </TreeItem>
  )
}
