import {
  FC,
  ReactNode,
  Reducer,
  createContext,
  useContext,
  useReducer
} from 'react'
import { SchemaState } from 'sections/schemas/types'
import { OasRoot } from '@schematicos/types'

type SchemaContextType = {
  state: SchemaState
  dispatch: (action: SchemaAction) => void
}

const SchemaContext = createContext<SchemaContextType | undefined>(undefined)

type DummyAction = {
  type: 'dummy'
}

type SchemaAction = DummyAction

const SchemaReducer = (state: SchemaState): SchemaState => {
  const newState = state

  return newState
}

type SchemaProviderProps = {
  schemaModel: OasRoot
  children: ReactNode
}

export const SchemaProvider: FC<SchemaProviderProps> = ({
  schemaModel,
  children
}) => {
  const [state, dispatch] = useReducer<Reducer<SchemaState, SchemaAction>>(
    SchemaReducer,
    { schemaModel }
  )

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  return (
    <SchemaContext.Provider value={value}>{children}</SchemaContext.Provider>
  )
}

export const useSchema = () => {
  const context = useContext(SchemaContext)
  if (context === undefined) {
    throw new Error('useSchema must be used within a SchemaProvider')
  }
  return context
}
