import { useFetchError } from 'components/useFetchError/useFetchError'
import { useEffect, useRef, useState } from 'react'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'
import { WorkflowModel, workflowModel } from '@schematicos/types'

type UseGetWorkflowArgs =
  | {
      workflowId: string
    }
  | {
      skip: true
    }

export const useGetWorkflow = (args: UseGetWorkflowArgs) => {
  const { handleFetchError } = useFetchError()
  const [workflow, setWorkflow] = useState<WorkflowModel>()
  const loadingRef = useRef(false)
  const workflowId = 'workflowId' in args ? args.workflowId : undefined

  useEffect(() => {
    if (loadingRef.current) {
      return
    }

    if ('skip' in args) {
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke(`workflows/${workflowId}`, { method: 'GET' })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = workflowModel.parse(data)

        setWorkflow(parsed)
      })
      .catch(handleFetchError('Failed to retrieve workflow'))
      .finally(() => (loadingRef.current = false))
  }, [])

  return {
    data: workflow,
    loading: loadingRef.current
  }
}
