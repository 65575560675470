import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { TreeItem, TreeItemLabel } from 'components/TreeItem/TreeItem.tsx'
import Box from '@mui/joy/Box'
import { Fragment, useEffect } from 'react'
import { TypescriptIcon } from 'icons/TypescriptIcon'

type PathsNavigation = {
  parentLabel: string
  contentMap?: Record<string, string>
  onNodeSelect: (nodeId: string) => void
}

export const PathsNavigation = ({
  parentLabel,
  contentMap,
  onNodeSelect
}: PathsNavigation) => {
  const firstKey = contentMap ? Object.keys(contentMap)[0] : undefined

  useEffect(() => {
    if (firstKey) {
      onNodeSelect(firstKey)
    }
  }, [firstKey])

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <TreeView
        aria-label={parentLabel}
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={[parentLabel]}
        defaultSelected={firstKey}
        sx={{
          height: '100%',
          flexGrow: 1,
          overflowY: 'auto',
          whiteSpace: 'nowrap'
        }}
        onNodeSelect={(_event, nodeId) => onNodeSelect(nodeId)}
      >
        <TreeItem
          key="label"
          nodeId={parentLabel}
          label={<TreeItemLabel>{parentLabel}</TreeItemLabel>}
        >
          {Object.keys(contentMap ?? {}).map(path => (
            <TreeItem
              key={path}
              nodeId={path}
              icon={path.endsWith('.ts') ? <TypescriptIcon /> : <Fragment />}
              label={<TreeItemLabel>{path}</TreeItemLabel>}
            />
          ))}
        </TreeItem>
      </TreeView>
    </Box>
  )
}
