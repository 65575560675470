import {
  ArtifactsMapModel,
  GeneratePayload,
  artifactsMapModel
} from '@schematicos/types'
import { useApp } from 'components/AppProvider/AppProvider'
import { nanoid } from 'nanoid'
import { useRef, useState } from 'react'

type LazyTransformTrigger = (args: GeneratePayload) => void
type LazyTransformResult = {
  data: ArtifactsMapModel | undefined
  loading: boolean
}
type LazyReturn = [LazyTransformTrigger, LazyTransformResult]

export const useLazyGenerate = (): LazyReturn => {
  const { dispatch } = useApp()
  const loadingRef = useRef(false)
  const [artifactsMap, setArtifactsMap] = useState<ArtifactsMapModel>()

  const trigger = (args: GeneratePayload) => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    return fetch(`${import.meta.env.VITE_API_ORIGIN}/api/generate`, {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      method: 'POST',
      body: JSON.stringify(args)
    })
      .then(res => res.json())
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = artifactsMapModel.parse(data)

        setArtifactsMap(parsed)
      })
      .catch(error => {
        dispatch({
          type: 'addNotification',
          payload: {
            id: nanoid(),
            type: 'error',
            title:
              'message' in error
                ? // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                  error.message
                : 'Failed to parse schema'
          }
        })
        console.log(error)
      })
  }

  return [trigger, { data: artifactsMap, loading: loadingRef.current }]
}
