import Card from '@mui/joy/Card'
import { Link as RouterLink } from 'react-router-dom'
import { ReactNode } from 'react'

type ViewCardProps = {
  to: string
  children: ReactNode
}

export const ViewCard = ({ to, children }: ViewCardProps) => (
  <Card
    variant="outlined"
    orientation="horizontal"
    component={RouterLink}
    to={to}
    sx={{
      bgcolor: 'background.body',
      textDecoration: 'none',
      boxShadow: 'sm',
      width: 350,
      '&:hover': {
        boxShadow: 'md',
        borderColor: 'neutral.outlinedHoverBorder'
      }
    }}
  >
    {children}
  </Card>
)
