import { useNavigate, useParams } from 'react-router-dom'
import { Drawer } from 'components/Drawer/Drawer'
import invariant from 'tiny-invariant'
import { ConfigureSource } from 'components/ConfigureSource/ConfigureSource'
import { useEffect, useState } from 'react'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'
import { useFetchError } from 'components/useFetchError/useFetchError'
import { schemaModel, toSourceFormValues } from '@schematicos/types'
import { SourceFormValues } from '@schematicos/types'
import { Spinner } from 'components/Spinner/Spinner'
import { SchemaFormFooter } from 'components/ConfigureSource/SchemaFormFooter'
import { ConfigureSourceFormProvider } from 'components/ConfigureSource/ConfigureSourceFormProvider'
import { DocumentSource } from 'components/ConfigureSource/DocumentSource'

export const EditSchemaSource = () => {
  const { schemaId } = useParams()
  const { handleFetchError } = useFetchError()
  const [schema, setSchema] = useState<SourceFormValues>()

  invariant(schemaId, 'schemaId is required')

  const navigate = useNavigate()

  useEffect(() => {
    void supabaseClient.functions
      .invoke(`/schemas/${schemaId}`, {
        method: 'GET'
      })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = schemaModel.parse(data)

        const sourceFormValues = toSourceFormValues(parsed)

        setSchema(sourceFormValues)
      })
      .catch(handleFetchError('Failed to load schema'))
  }, [schemaId])

  return schema ? (
    <ConfigureSourceFormProvider initialValues={schema}>
      <Drawer title="Edit schema source" onClose={() => navigate('..')}>
        <ConfigureSource />
        <DocumentSource />
        <SchemaFormFooter />
      </Drawer>
    </ConfigureSourceFormProvider>
  ) : (
    <Drawer title="Edit schema source" onClose={() => navigate('..')}>
      <Spinner />
    </Drawer>
  )
}
