import { MutableRefObject } from 'react'
import {
  TransformerInitialModel,
  TransformerModel,
  transformerModel
} from '@schematicos/types'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

type CreateTransformerArgs = {
  loadingRef: MutableRefObject<boolean>
  onError: (title: string) => (error: unknown) => void
  onSuccess: (transformer: TransformerModel) => void
}

export const createTransformer =
  ({ loadingRef, onError, onSuccess }: CreateTransformerArgs) =>
  (transfomer?: TransformerInitialModel) => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke('transformers', {
        method: 'POST',
        body: {
          name: transfomer?.name ?? `Untitled transformer`,
          description: transfomer?.description ?? '',
          content: transfomer?.content ?? {
            'index.ts': '// Your transformer code here'
          }
        }
      })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = transformerModel.parse(data)

        onSuccess(parsed)
      })
      .catch(onError('Failed to create transformer'))
      .finally(() => (loadingRef.current = false))
  }
