import Box from '@mui/joy/Box'
import { UserMenu } from 'components/UserMenu/UserMenu'
import { ReactNode } from 'react'

type DashboardTopProps = {
  children: ReactNode
}

export const DashboardTop = ({ children }: DashboardTopProps) => {
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      pr="16px"
      pt="6px"
      pb="6px"
    >
      {children}
      <UserMenu />
    </Box>
  )
}
