import Box from '@mui/joy/Box'
import { DashboardContent } from 'components/Dashboard/DashboardContent'
import { DashboardTop } from 'components/Dashboard/DashboardTop'
import { Panel, PanelGroup, PanelResizeHandle } from 'react-resizable-panels'
import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
import Button from '@mui/joy/Button'
import { GenerateConfigType, PrettierConfigType } from '@schematicos/types'
import { useSchema } from 'sections/schemas/SchemaProvider'
import { useLazyGenerate } from 'loaders/useGenerate'
import Typography from '@mui/joy/Typography'

export const SchemaPreview = () => {
  const { state: schemaState } = useSchema()
  const { schemaModel } = schemaState

  const [trigger, result] = useLazyGenerate()

  return (
    <>
      <DashboardTop>
        <Typography sx={{ color: 'neutral.600' }}>"Schema details</Typography>
      </DashboardTop>

      <DashboardContent>
        <Box display="flex" flex={1} minHeight={0} flexDirection="column">
          <Box display="flex" p="16px">
            <Button
              onClick={() => {
                if (!schemaModel) {
                  return
                }

                trigger({
                  schemaModel,
                  settingsConfig: undefined,
                  generateConfig,
                  prettierConfig
                })
              }}
            >
              Run
            </Button>
          </Box>
          <PanelGroup direction="horizontal">
            <Panel id="schema-source-panel" defaultSize={50} order={1}>
              <Box
                display="flex"
                minHeight="0"
                height="100%"
                sx={{ overflowY: 'scroll', fontSize: '14px' }}
              >
                <CodeMirror
                  height="auto"
                  width="auto"
                  editable={false}
                  value={JSON.stringify(schemaModel, null, 2)}
                  extensions={[json()]}
                />
              </Box>
            </Panel>
            <PanelResizeHandle
              style={{
                width: '2px',
                height: '100%',
                background: 'var(--joy-palette-neutral-200, #DDE7EE)'
              }}
            />
            <Panel id="schema-artifact-panel" defaultSize={50} order={2}>
              <Box
                display="flex"
                minHeight="0"
                height="100%"
                sx={{ overflowY: 'scroll', fontSize: '14px' }}
              >
                <CodeMirror
                  height="auto"
                  width="auto"
                  editable={false}
                  value={JSON.stringify(result.data, null, 2)}
                  extensions={[json()]}
                />
              </Box>
            </Panel>
          </PanelGroup>
        </Box>
      </DashboardContent>
    </>
  )
}

const prettierConfig: PrettierConfigType = {
  tabWidth: 2,
  useTabs: false,
  semi: false,
  singleQuote: true,
  trailingComma: 'none',
  bracketSpacing: true,
  arrowParens: 'avoid'
}

const generateConfig: GenerateConfigType = {
  language: 'typescript',
  library: [],
  typeSystem: 'zod'
}
