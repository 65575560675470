import Typography from '@mui/joy/Typography'
import Markdown from 'markdown-to-jsx'
import Link from '@mui/joy/Link'
import { SxProps } from '@mui/joy/styles/types'

type MarkdownDescriptionProps = {
  sx?: SxProps
  description: string | undefined
}

export const MarkdownDescription = ({
  sx,
  description
}: MarkdownDescriptionProps) => {
  if (!description) {
    return null
  }

  return (
    <Typography sx={sx}>
      <Markdown
        options={{
          overrides: {
            a: Link
          }
        }}
      >
        {description}
      </Markdown>
    </Typography>
  )
}
