import Typography from '@mui/joy/Typography'
import { Auth } from '@supabase/auth-ui-react'
import { ThemeSupa } from '@supabase/auth-ui-shared'
import { useApp } from 'components/AppProvider/AppProvider'
import { ConfigureSource } from 'components/ConfigureSource/ConfigureSource'
import { LeftHalf } from 'components/Layout/LeftHalf'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'
import { SignedIn } from 'components/Auth/SignedIn'
import Box from '@mui/joy/Box'
import { DocumentSource } from 'components/ConfigureSource/DocumentSource'

export const Register = () => {
  const { state } = useApp()
  const { session } = state

  return (
    <LeftHalf>
      <Typography
        level="h2"
        sx={{ display: 'flex', flex: 'none', flexDirection: 'column' }}
      >
        Sign up with Github
      </Typography>

      {!session ? (
        <Auth
          supabaseClient={supabaseClient}
          appearance={{ theme: ThemeSupa }}
          providers={['github']}
          providerScopes={{ github: 'repo' }}
          redirectTo="/register"
          onlyThirdPartyProviders
        />
      ) : (
        <Box
          display="flex"
          flexDirection="column"
          gap="32px"
          minHeight={0}
          flex={1}
        >
          <SignedIn />
          <ConfigureSource />
          <DocumentSource />
        </Box>
      )}
    </LeftHalf>
  )
}
