import Box from '@mui/joy/Box'
import { GenerateConfigType } from '@schematicos/types'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { SettingsLiveData } from 'sections/composer/types'
import DialogContent from '@mui/joy/DialogContent'
import { match } from 'ts-pattern'
import { RtkQueryRootForm } from 'sections/composer/settings/RtkQueryRootForm'
import { SharedRootConfig } from 'sections/composer/settings/SharedRootConfig'

type RootSettingsConfigProps = {
  generateConfig?: GenerateConfigType
  internal$: BehaviorSubject<SettingsLiveData>
}

export const RootSettingsConfig = ({
  generateConfig,
  internal$
}: RootSettingsConfigProps) => {
  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      px="32px"
      pt="32px"
      height="100%"
      minHeight="0"
      maxWidth="400px"
      sx={{ overflowY: 'scroll', fontSize: '14px' }}
    >
      <DialogContent sx={{ gap: '32px' }}>
        <SharedRootConfig internal$={internal$} />
        {generateConfig?.library.map(library => {
          return match(library)
            .with('rtk-query', () => (
              <RtkQueryRootForm key="root" internal$={internal$} />
            ))
            .with('mui-form', () => null)
            .exhaustive()
        })}
      </DialogContent>
    </Box>
  )
}
