import { useFetchError } from 'components/useFetchError/useFetchError'
import { WorkflowModel, workflowModels } from '@schematicos/types'
import { useEffect, useRef, useState } from 'react'
import { supabaseClient } from 'lib/supabaseClient/supabaseClient'

export const useGetWorkflows = () => {
  const { handleFetchError } = useFetchError()
  const [workflows, setWorkflows] = useState<WorkflowModel[]>()
  const loadingRef = useRef(false)

  useEffect(() => {
    if (loadingRef.current) {
      return
    }

    loadingRef.current = true

    void supabaseClient.functions
      .invoke('workflows', { method: 'GET' })
      .then(({ data, error }) => {
        if (error) {
          throw error
        }

        const parsed = workflowModels.parse(data)

        setWorkflows(parsed)
      })
      .catch(handleFetchError('Failed to retrieve workflows'))
      .finally(() => (loadingRef.current = false))
  }, [])

  return {
    data: workflows,
    loading: loadingRef.current
  }
}
