type SchematicLogoProps = {
  color?: string
  width?: string
  height?: string
}
export const SchematicLogo = ({
  color = 'currentColor',
  width = '24px',
  height = '24px'
}: SchematicLogoProps) => (
  <svg
    width={width}
    height={height}
    fill={color}
    version="1.1"
    viewBox="0 0 100 100"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m65.074 65.066c-2.3633 1.6406-4.7266 3.2812-7.0859 4.9258-2.8008-4.2422-5.6055-8.4883-8.4062-12.73-0.11719 0.082031-0.035156 0.007812-0.12109 0.12109-2.8633 4.2031-5.7266 8.4062-8.5859 12.613-2.3008-1.6602-4.6055-3.3242-6.9062-4.9844l0.17969-0.17969c3.082-4.0039 6.1641-8.0078 9.25-12.012v-0.058593c-4.9258-1.3516-9.8516-2.6914-14.777-4.0312 0.88281-2.7422 1.7617-5.4883 2.6445-8.2305 4.8438 1.7422 9.6875 3.4844 14.531 5.2266-0.23828-5.125-0.48047-10.25-0.71875-15.375h8.707c-0.21875 5.125-0.44141 10.25-0.66016 15.375 4.8633-1.7227 9.7305-3.4414 14.594-5.1641 0.85938 2.7227 1.7227 5.4453 2.582 8.168-4.9258 1.3594-9.8477 2.7227-14.773 4.082 3.1836 4.082 6.3672 8.168 9.5469 12.25z" />
  </svg>
)
