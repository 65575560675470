import Box from '@mui/joy/Box'
import { memo, useEffect } from 'react'
import { NodeProps } from 'reactflow'
import { NodeContainer } from 'sections/composer/shared/NodeContainer'
import { ExportIcon } from 'icons/ExportIcon'
import Typography from '@mui/joy/Typography'
import { ExportConfigType, WorkflowNode } from '@schematicos/types'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { ExportLiveData, NodeAction } from 'sections/composer/types'
import { useWorkflow } from 'sections/composer/WorkflowProvider'
import { match, P } from 'ts-pattern'
import {
  createLook,
  getHighlightColor,
  getMainColor
} from 'sections/composer/shared/createLook'

export const ExportNode = memo(
  (node: NodeProps<NodeAction<ExportLiveData>>) => {
    const { data, selected } = node
    const { dispatch } = useWorkflow()
    const { exportConfig } = useInternalSubject(data.internal$)

    useEffect(() => {
      const workflowNode: WorkflowNode = {
        id: node.id,
        type: 'export',
        position: { x: node.xPos, y: node.yPos },
        selected: node.selected,
        data: {
          exportConfig: exportConfig
        }
      }

      dispatch({
        type: 'updateNode',
        payload: {
          node: workflowNode
        }
      })
    }, [node.xPos, node.yPos, exportConfig, node.selected])

    return (
      <NodeContainer
        selected={selected}
        look={createLook({ nodeType: 'export', selected })}
        highlightColor={getHighlightColor('export')}
      >
        <Box
          display="flex"
          flex={1}
          sx={{
            color: getMainColor({ nodeType: 'export', selected }),
            padding: '8px',
            gap: '8px'
          }}
        >
          <ExportIcon width="24px" height="24px" />
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            paddingRight="8px"
          >
            <Typography sx={{ fontSize: 'sm', lineHeight: '16px' }}>
              Export
            </Typography>

            <ExportNodeSummary exportConfig={exportConfig} />
          </Box>
        </Box>
      </NodeContainer>
    )
  }
)

type ExportNodeSummaryProps = {
  exportConfig?: ExportConfigType
}

const ExportNodeSummary = ({ exportConfig }: ExportNodeSummaryProps) => {
  if (!exportConfig) {
    return null
  }

  return (
    <Box display="flex" gap="16px">
      <Typography sx={{ color: 'neutral.400', fontSize: 'sm' }}>
        {match(exportConfig.type)
          .with('github', () => 'GitHub pull request')
          .with('zip', () => 'Zip file download')
          .with(P.nullish, () => 'No export selected')
          .exhaustive()}
      </Typography>
    </Box>
  )
}
