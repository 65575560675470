import { memo, useEffect } from 'react'
import { NodeProps } from 'reactflow'
import Typography from '@mui/joy/Typography'
import { MenuLiveData } from 'sections/composer/types'
import List from '@mui/joy/List'
import ListItem from '@mui/joy/ListItem'
import MenuList from '@mui/joy/MenuList'
import MenuItem from '@mui/joy/MenuItem'
import { useStore } from 'sections/composer/core/store'
import { usePrevious } from 'components/usePrevious/usePrevious'

export const MenuNode = memo(
  ({ id, data, selected, xPos, yPos }: NodeProps<MenuLiveData>) => {
    const { deleteNode, selectMenuNode } = useStore(state => ({
      deleteNode: state.deleteNode,
      selectMenuNode: state.selectMenuNode
    }))

    const previousSelected = usePrevious(selected)

    useEffect(() => {
      if (selected === false && previousSelected === true) {
        deleteNode(id)
      }
    }, [selected, id])

    return (
      <MenuList
        component="div"
        variant="outlined"
        size="sm"
        sx={{
          boxShadow: 'sm',
          flexGrow: 0,
          minWidth: 160,
          maxHeight: 240,
          overflow: 'auto'
        }}
      >
        <List>
          <ListItem sticky>
            <Typography
              sx={{
                lineHeight: '16px',
                fontSize: '12px',
                fontWeight: '700',
                color: 'neutral.400',
                textTransform: 'uppercase'
              }}
            >
              Select next step
            </Typography>
          </ListItem>
          {data.items.map(({ label, type }) => (
            <MenuItem
              key={type}
              onClick={() => {
                selectMenuNode({
                  selectedNodeType: type,
                  menuNodeId: id,
                  menuNodeData: data,
                  menuNodePosition: {
                    x: xPos,
                    y: yPos
                  }
                })
              }}
            >
              {label}
            </MenuItem>
          ))}
        </List>
      </MenuList>
    )
  }
)
