import Box from '@mui/joy/Box'
import { SettingsConfigType, defaultExportPath } from '@schematicos/types'
import Typography from '@mui/joy/Typography'
import Input from '@mui/joy/Input'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import FormHelperText from '@mui/joy/FormHelperText'
import { useEffect } from 'react'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { setModelExportPath } from 'sections/composer/settings/mutators'
import { SettingsLiveData } from 'sections/composer/types'
import { Controller, useForm } from 'react-hook-form'
import DialogContent from '@mui/joy/DialogContent'
import debounce from 'lodash/debounce'
import { z } from 'zod'

type ModelSettingsConfigProps = {
  modelName: string
  settingsConfig: SettingsConfigType
  internal$: BehaviorSubject<SettingsLiveData>
}

const modelConfigFormValues = z.object({
  exportPath: z.string()
})

type ModelConfigFormValues = z.infer<typeof modelConfigFormValues>

export const ModelSettingsConfig = ({
  settingsConfig,
  modelName,
  internal$
}: ModelSettingsConfigProps) => {
  const { control, watch, setValue } = useForm<ModelConfigFormValues>({
    defaultValues: {
      exportPath: defaultExportPath
    }
  })

  useEffect(() => {
    const debouncedEmit = debounce((value: unknown) => {
      const { exportPath } = modelConfigFormValues.parse(value)
      setModelExportPath({ modelName, exportPath, internal$ })
    }, 500)

    const subscription = watch(value => debouncedEmit(value))

    return () => subscription.unsubscribe()
  }, [watch, modelName])

  useEffect(() => {
    const modelExportPath =
      settingsConfig.components.models?.[modelName]?.exportPath

    setValue('exportPath', modelExportPath ?? defaultExportPath)
  }, [modelName])

  return (
    <Box
      display="flex"
      flex="1"
      flexDirection="column"
      px="32px"
      pt="32px"
      height="100%"
      minHeight="0"
      maxWidth="400px"
      sx={{ overflowY: 'scroll', fontSize: '14px' }}
    >
      <DialogContent sx={{ gap: '24px' }}>
        <Box display="flex" flexDirection="column" gap="2px">
          <Typography
            sx={{ fontSize: '20px', color: 'neutral.700', fontWeight: 500 }}
          >
            {modelName}
          </Typography>
        </Box>

        <Controller
          name="exportPath"
          control={control}
          render={({ field }) => (
            <FormControl>
              <FormLabel>Export path</FormLabel>
              <Input placeholder={defaultExportPath} {...field} />
              <FormHelperText sx={{ display: 'inline' }}>
                Destination file for the generated model code
              </FormHelperText>
            </FormControl>
          )}
        />
      </DialogContent>
    </Box>
  )
}
