import { WorkflowEdge, WorkflowModel, WorkflowNode } from '@schematicos/types'
import { FC, ReactNode, createContext, useContext, useEffect } from 'react'
import { useImmerReducer } from 'use-immer'
import { match } from 'ts-pattern'

type WorkflowState = {
  workflow: WorkflowModel
}

type WorkflowContextType = {
  state: WorkflowState
  dispatch: (action: WorkflowAction) => void
}

const WorkflowContext = createContext<WorkflowContextType | undefined>(
  undefined
)

type RenameWorkflow = {
  type: 'renameWorkflow'
  payload: {
    name: string
  }
}

const renameWorkflow =
  (state: WorkflowState) =>
  ({ payload }: RenameWorkflow) => {
    state.workflow.name = payload.name
  }

type UpdateNode = {
  type: 'updateNode'
  payload: {
    node: WorkflowNode
  }
}

const updateNode =
  (state: WorkflowState) =>
  ({ payload }: UpdateNode) => {
    const nodeIndex = state.workflow.content.nodes.findIndex(({ id }) => {
      return id === payload.node.id
    })

    nodeIndex === -1
      ? state.workflow.content.nodes.push(payload.node)
      : (state.workflow.content.nodes[nodeIndex] = payload.node)
  }

type RemoveNode = {
  type: 'removeNode'
  payload: {
    nodeId: string
  }
}

const removeNode =
  (state: WorkflowState) =>
  ({ payload }: RemoveNode) => {
    state.workflow.content.nodes = state.workflow.content.nodes.filter(
      ({ id }) => id !== payload.nodeId
    )
  }

type UpdateEdges = {
  type: 'updateEdges'
  payload: {
    edges: WorkflowEdge[]
  }
}

const updateEdges =
  (state: WorkflowState) =>
  ({ payload }: UpdateEdges) => {
    state.workflow.content.edges = payload.edges
  }

type WorkflowAction = RenameWorkflow | UpdateNode | RemoveNode | UpdateEdges

const workflowReducer = (state: WorkflowState, action: WorkflowAction) => {
  match(action)
    .with({ type: 'renameWorkflow' }, renameWorkflow(state))
    .with({ type: 'updateNode' }, updateNode(state))
    .with({ type: 'removeNode' }, removeNode(state))
    .with({ type: 'updateEdges' }, updateEdges(state))
    .exhaustive()
}

type WorkflowProviderProps = {
  workflow: WorkflowModel
  children: ReactNode
  persistWorkflow: (workflow: WorkflowModel) => void
}

export const WorkflowProvider: FC<WorkflowProviderProps> = ({
  children,
  workflow,
  persistWorkflow
}) => {
  const [state, dispatch] = useImmerReducer<WorkflowState, WorkflowAction>(
    workflowReducer,
    { workflow }
  )

  useEffect(() => {
    persistWorkflow(state.workflow)
  }, [state.workflow])

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  return (
    <WorkflowContext.Provider value={value}>
      {children}
    </WorkflowContext.Provider>
  )
}

export const useWorkflow = () => {
  const context = useContext(WorkflowContext)
  if (context === undefined) {
    throw new Error('useWorkflow must be used within a WorkflowProvider')
  }
  return context
}
