import Box from '@mui/joy/Box'
import FormControl from '@mui/joy/FormControl'
import FormLabel from '@mui/joy/FormLabel'
import { Controller, useForm } from 'react-hook-form'
import Radio from '@mui/joy/Radio'
import RadioGroup from '@mui/joy/RadioGroup'
import { Badge } from 'components/Badge/Badge'
import DialogContent from '@mui/joy/DialogContent'
import { useEffect } from 'react'
import { GenerateConfigType, generateConfigType } from '@schematicos/types'
import { useInternalSubject } from 'sections/composer/useInternalSubject'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { GenerateLiveData } from 'sections/composer/types'
import Checkbox from '@mui/joy/Checkbox'
import { generateOptions } from 'sections/composer/generate/generateOptions'
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'

type GenerateDrawerContentProps = {
  internal$: BehaviorSubject<GenerateLiveData>
}

export const GenerateDrawerContent = ({
  internal$
}: GenerateDrawerContentProps) => {
  const { generateConfig } = useInternalSubject<GenerateLiveData>(internal$)

  const { control, watch } = useForm<GenerateConfigType>({
    defaultValues: generateConfig
  })

  useEffect(() => {
    const subscription = watch((value: unknown) => {
      internal$.next({ generateConfig: generateConfigType.parse(value) })
    })

    return () => subscription.unsubscribe()
  }, [watch])

  return (
    <Box
      component="form"
      display="flex"
      flexDirection="column"
      minHeight={0}
      flex={1}
      py="16px"
      px="12px"
      maxWidth="400px"
    >
      <DialogContent>
        <Controller
          name="library"
          control={control}
          render={({ field }) => (
            <FormGroup sx={{ py: '8px', px: '12px', marginTop: 0, gap: '8px' }}>
              <FormLabel sx={{ fontSize: 'sm' }}>Transform to</FormLabel>
              {generateOptions.map(option => (
                <FormControlLabel
                  key={option.value}
                  sx={{
                    marginBlockStart: '0',
                    marginLeft: '0',
                    paddingLeft: '8px',
                    gap: '8px'
                  }}
                  control={
                    <Checkbox
                      size="sm"
                      value={option.value}
                      checked={field.value.includes(option.value)}
                      onChange={event => {
                        const values = event.target.checked
                          ? [...field.value, option.value]
                          : field.value.filter(value => value !== option.value)

                        field.onChange(values)
                      }}
                    />
                  }
                  label={
                    <FormLabel sx={{ fontWeight: 400, lineHeight: '16px' }}>
                      {option.label}
                    </FormLabel>
                  }
                />
              ))}
            </FormGroup>
          )}
        />

        <Controller
          name="typeSystem"
          control={control}
          render={({ field }) => (
            <FormControl sx={{ py: '8px', px: '12px' }}>
              <FormLabel
                sx={{
                  fontSize: 'sm'
                }}
              >
                Type system
              </FormLabel>
              <RadioGroup {...field} sx={{ gap: '8px', marginTop: 0 }}>
                <Radio
                  label="Zod"
                  value="zod"
                  size="sm"
                  sx={{
                    marginBlockStart: '0',
                    marginLeft: '0',
                    paddingLeft: '8px',
                    gap: '8px'
                  }}
                />
                <Radio
                  label="TypeScript"
                  value="typescript"
                  size="sm"
                  sx={{
                    marginBlockStart: '0',
                    marginLeft: '0',
                    paddingLeft: '8px',
                    gap: '8px'
                  }}
                />
                <Box display="flex" gap="16px">
                  <Radio
                    size="sm"
                    label="io-ts"
                    value="io-ts"
                    disabled
                    sx={{
                      fontSize: 'sm',
                      marginLeft: '0',
                      paddingLeft: '8px',
                      gap: '8px'
                    }}
                  />
                  <Badge color="primary">Coming soon</Badge>
                </Box>
              </RadioGroup>
            </FormControl>
          )}
        />
      </DialogContent>
    </Box>
  )
}
