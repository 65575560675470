import { FC, ReactNode, createContext, useContext } from 'react'
import { match } from 'ts-pattern'
import { useImmerReducer } from 'use-immer'

type PathEditorItemProviderState = {
  path: string
  renaming: boolean
}

type PathEditorItemProviderContextType = {
  state: PathEditorItemProviderState
  dispatch: (action: PathEditorItemProviderAction) => void
}

const PathEditorItemProviderContext = createContext<
  PathEditorItemProviderContextType | undefined
>(undefined)

type RenamePathEditorItemProvider = {
  type: 'setRenaming'
  payload: {
    renaming: boolean
  }
}

const setRenaming =
  (state: PathEditorItemProviderState) =>
  ({ payload }: RenamePathEditorItemProvider) => {
    state.renaming = payload.renaming
  }

export type PathEditorItemProviderAction = RenamePathEditorItemProvider

const pathEditorItemReducer = (
  state: PathEditorItemProviderState,
  action: PathEditorItemProviderAction
) => {
  match(action).with({ type: 'setRenaming' }, setRenaming(state)).exhaustive()
}

type PathEditorItemProviderProviderProps = {
  path: string
  renaming?: boolean
  children: ReactNode
}

export const PathEditorItemProviderProvider: FC<
  PathEditorItemProviderProviderProps
> = ({ path, renaming = false, children }) => {
  const [state, dispatch] = useImmerReducer<
    PathEditorItemProviderState,
    PathEditorItemProviderAction
  >(pathEditorItemReducer, { path, renaming })

  // NOTE: you *might* need to memoize this value
  // Learn more in http://kcd.im/optimize-context
  const value = { state, dispatch }

  return (
    <PathEditorItemProviderContext.Provider value={value}>
      {children}
    </PathEditorItemProviderContext.Provider>
  )
}

export const usePathEditorItem = () => {
  const context = useContext(PathEditorItemProviderContext)
  if (context === undefined) {
    throw new Error(
      'usePathEditorItemProvider must be used within a PathEditorItemProviderProvider'
    )
  }
  return context
}
