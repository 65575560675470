import CardContent from '@mui/joy/CardContent'
import Typography from '@mui/joy/Typography'
import { ViewCard } from 'components/Card/ViewCard'
import BoltIcon from '@mui/icons-material/Bolt'
import { SchemaModel } from '@schematicos/types'
import Dropdown from '@mui/joy/Dropdown'
import IconButton from '@mui/joy/IconButton'
import Menu from '@mui/joy/Menu'
import MenuButton from '@mui/joy/MenuButton'
import MenuItem from '@mui/joy/MenuItem'
import MoreVert from '@mui/icons-material/MoreVert'
import Box from '@mui/joy/Box'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import Button from '@mui/joy/Button'
import { useCreateWorkflow } from 'loaders/workflows/useCreateWorkflow'

type ViewSchemaCardProps = {
  schema: SchemaModel
}

export const ViewSchemaCard = ({ schema }: ViewSchemaCardProps) => {
  const navigate = useNavigate()

  const createWorkflowFn = useCreateWorkflow({
    onSuccess: workflow => navigate(`/dashboard/workflows/${workflow.id}`)
  })

  return (
    <ViewCard to={schema.id}>
      <CardContent sx={{ height: '100%', minHeight: '100px' }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="flex-start"
        >
          <Typography
            level="title-lg"
            sx={{ color: 'neutral.700', fontWeight: 500, fontSize: '16px' }}
          >
            {schema.name}
          </Typography>
          <SchemaCardMenu schemaId={schema.id} />
        </Box>
        <Box display="flex" flex={1} />
        <Box display="flex" alignItems="flex-end">
          <Button
            onClick={event => {
              event.preventDefault()

              createWorkflowFn(schema)
            }}
            size="sm"
            variant="plain"
            sx={{
              p: 0,
              pr: '8px',
              height: '24px',
              minHeight: '24px',
              fontWeight: 500
            }}
            startDecorator={<BoltIcon fontSize="small" />}
          >
            Generate
          </Button>
        </Box>
      </CardContent>
    </ViewCard>
  )
}

type SchemaCardMenuProps = {
  schemaId: string
}

const SchemaCardMenu = ({ schemaId }: SchemaCardMenuProps) => (
  <Dropdown>
    <MenuButton
      onClick={event => {
        event.preventDefault()
        event.stopPropagation()
      }}
      slots={{ root: IconButton }}
      slotProps={{
        root: { variant: 'plain' }
      }}
      sx={{
        height: '24px',
        minHeight: '24px',
        width: '24px',
        minWidth: '24px',
        borderRadius: '50%'
      }}
    >
      <MoreVert fontSize="small" sx={{ color: 'neutral.700' }} />
    </MenuButton>

    <Menu size="sm" placement="bottom-end" disablePortal>
      <MenuItem
        component={RouterLink}
        to={`${schemaId}/config`}
        sx={{ color: 'neutral.700' }}
      >
        Configuration
      </MenuItem>
    </Menu>
  </Dropdown>
)
