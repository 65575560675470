import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import Box from '@mui/joy/Box'
import Search from '@mui/icons-material/Search'
import Input from '@mui/joy/Input'
import Divider from '@mui/joy/Divider'
import {
  SettingsConfigType,
  GenerateLibraryType,
  OasRoot
} from '@schematicos/types'
import { NavigationMenu } from 'components/ApiPaths/NavigationMenu'
import { ComponentsNavigation } from 'components/ApiPaths/ComponentsNavigation'
import { ReactNode, useEffect, useState } from 'react'
import { PathsNavigation } from 'components/ApiPaths/PathsNavigation'
import { TreeView } from '@mui/x-tree-view/TreeView'
import { OnNodeSelect } from 'components/ApiPaths/onNodeSelect'
import { ToggleOperationArgs } from 'components/types'
import { toOperationNodeId } from 'components/ApiPaths/toOperationNodeId'
import { toModelNodeId } from 'components/ApiPaths/toComponentNodeId copy'
import { NodeId } from 'sections/composer/types'

type ApiPathsNavigationProps = {
  settingsConfig?: SettingsConfigType
  toggleAll?: (selectAll: boolean) => void
  toggleOperation?: ({ path, method, selected }: ToggleOperationArgs) => void
  toggleModel?: (modelName: string) => void
  onNodeSelect: OnNodeSelect
  transformers: GenerateLibraryType[]
  schemaModel: OasRoot
  defaultSelectedNodeId?: NodeId
  children?: ReactNode
}

export const ApiPathsNavigation = ({
  settingsConfig,
  toggleOperation,
  toggleAll,
  toggleModel,
  onNodeSelect,
  transformers,
  schemaModel,
  defaultSelectedNodeId,
  children
}: ApiPathsNavigationProps) => {
  // Next steps:
  // 1. Make paths relative
  // 2. Toggle selectability. Only use selectability for exports
  const [query, setQuery] = useState('')

  const firstKey = defaultSelectedNodeId ?? getDefaultSelectedNode(schemaModel)

  useEffect(() => {
    if (firstKey) {
      onNodeSelect([firstKey])
    }
  }, [firstKey])

  return (
    <Box display="flex" height="100%" flexDirection="column">
      <Box display="flex" p="8px" gap="8px">
        <Input
          value={query}
          onChange={event => setQuery(event.target.value)}
          endDecorator={
            <Search sx={{ color: 'var(--joy-palette-neutral-400)' }} />
          }
          sx={{
            width: '100%',
            color: 'var(--joy-palette-neutral-500)',
            fontSize: '14px'
          }}
        />
        {toggleAll ? <NavigationMenu toggleAll={toggleAll} /> : null}
      </Box>
      <Divider orientation="horizontal" />
      <TreeView
        aria-label="Api endpoints"
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpandIcon={<ChevronRightIcon />}
        defaultExpanded={['apiPaths', 'Components', 'Schemas']}
        multiSelect={true}
        sx={{
          height: '100%',
          width: '100%',
          flexGrow: 1,
          overflowY: 'auto'
        }}
        onNodeSelect={(_events, nodeIds) => onNodeSelect(nodeIds)}
      >
        {children}
        <PathsNavigation
          settingsConfig={settingsConfig}
          toggleOperation={toggleOperation}
          transformers={transformers}
          query={query}
          schemaModel={schemaModel}
        />

        <ComponentsNavigation
          settingsConfig={settingsConfig}
          toggleModel={toggleModel}
          query={query}
          schemaModel={schemaModel}
        />
      </TreeView>
    </Box>
  )
}

const getDefaultSelectedNode = (schemaModel: OasRoot): NodeId | undefined => {
  const firstOperationNodeId = getFirstOperationNodeId(schemaModel)
  const firstModelNodeId = getFirstModelNodeId(schemaModel)

  return firstOperationNodeId || firstModelNodeId
}

const getFirstOperationNodeId = (schemaModel: OasRoot) => {
  const firstOperation = schemaModel?.operations?.[0]

  if (!firstOperation) {
    return undefined
  }

  return toOperationNodeId(firstOperation)
}

const getFirstModelNodeId = (schemaModel: OasRoot) => {
  const firstModel = Object.keys(schemaModel?.components?.models ?? {})[0]

  if (!firstModel) {
    return undefined
  }

  return toModelNodeId({ modelName: firstModel })
}
