import { WorkflowEdge, WorkflowNode } from '@schematicos/types'
import { connectNodeToParent } from 'sections/composer/core/createNode'
import { SchematicFlowNode } from 'sections/composer/types'

type InitializeNodesArgs = {
  nodes: WorkflowNode[]
  edges: WorkflowEdge[]
}

export const initializeNodes = ({ nodes, edges }: InitializeNodesArgs) => {
  const connectables: Connectables = {
    fresh: Object.fromEntries(nodes.map(node => [node.id, node])),
    connected: {}
  }

  const { connected } = recursiveConnect(connectables, edges)

  return Object.values(connected)
}

type Connectables = {
  fresh: Record<string, WorkflowNode>
  connected: Record<string, SchematicFlowNode>
}

// We want to connect all nodes to parent streams
// if they don't have a parent, we connect them empty stream
// if they have a connected parent, we connect them to the parent's stream
// if they have a fresh parent, we connect the parent recursively
// This means we need to both loop over the nodes and recurse over them
const recursiveConnect = (
  connectables: Connectables,
  edges: WorkflowEdge[]
): Connectables => {
  const freshNodeId = Object.keys(connectables.fresh)?.[0]

  if (!freshNodeId) {
    return connectables
  }

  const updatedConnectables = processConnectableNode({
    node: connectables.fresh[freshNodeId],
    connectables,
    edges,
    autoSelect: false
  })

  return recursiveConnect(updatedConnectables, edges)
}

type ProcessConnectableNodeArgs = {
  node: WorkflowNode | SchematicFlowNode
  connectables: Connectables
  edges: WorkflowEdge[]
  autoSelect: boolean
}

const processConnectableNode = ({
  node,
  connectables,
  edges,
  autoSelect
}: ProcessConnectableNodeArgs): Connectables => {
  // node is already connected
  if (isConnectedNode(node)) {
    return connectables
  }

  const parentNodeId = edges.find(edge => edge.target === node.id)?.source

  const { parentNode, connectables: updatedConnectables } = getParentNode({
    parentNodeId,
    connectables,
    edges,
    autoSelect
  })

  return connectNodeToOptionalParent({
    node,
    parentNode,
    autoSelect,
    connectables: updatedConnectables
  })
}

type ConnectNodeToOptionalParentArgs = {
  node: WorkflowNode
  parentNode?: SchematicFlowNode
  autoSelect: boolean
  connectables: Connectables
}

const connectNodeToOptionalParent = ({
  node,
  parentNode,
  autoSelect,
  connectables
}: ConnectNodeToOptionalParentArgs): Connectables => {
  const { [node.id]: childNode, ...fresh } = connectables.fresh

  const connectedChildNode: SchematicFlowNode = {
    ...childNode,
    data: connectNodeToParent({ parentNode, childNode, autoSelect })
  } as SchematicFlowNode

  // TODO: It might be possible to remove above cast by
  // using Include and Exclude types to match the type of
  // connectNodeToParent return value with the type of
  // childNode

  return {
    connected: {
      ...connectables.connected,
      [node.id]: connectedChildNode
    },
    fresh
  }
}

const isConnectedNode = (
  node: WorkflowNode | SchematicFlowNode
): node is SchematicFlowNode => {
  return 'outbound$' in node.data
}

type GetParentNodeArgs = {
  parentNodeId?: string
  connectables: Connectables
  edges: WorkflowEdge[]
  autoSelect: boolean
}

const getParentNode = ({
  parentNodeId,
  connectables,
  edges,
  autoSelect
}: GetParentNodeArgs) => {
  if (!parentNodeId) {
    return { parentNode: undefined, connectables }
  }

  if (connectables.connected[parentNodeId]) {
    return { parentNode: connectables.connected[parentNodeId], connectables }
  }

  if (connectables.fresh[parentNodeId]) {
    const updatedConnectables = processConnectableNode({
      node: connectables.fresh[parentNodeId],
      connectables,
      edges,
      autoSelect
    })

    return {
      parentNode: updatedConnectables.connected[parentNodeId],
      connectables: updatedConnectables
    }
  }

  throw new Error(`Parent node with id "${parentNodeId}" not found`)
}
