import Box from '@mui/joy/Box'
import { FormProvider, useForm } from 'react-hook-form'
import { SourceFormValues } from '@schematicos/types'
import { ReactNode } from 'react'

type ConfigureSourceFormProviderProps = {
  initialValues: SourceFormValues
  children: ReactNode
}

export const ConfigureSourceFormProvider = ({
  initialValues,
  children
}: ConfigureSourceFormProviderProps) => {
  const formMethods = useForm<SourceFormValues>({
    defaultValues: initialValues
  })

  return (
    <FormProvider {...formMethods}>
      <Box
        component="form"
        display="flex"
        flexDirection="column"
        minHeight={0}
        flex={1}
        sx={{ pt: '4px' }}
      >
        {children}
      </Box>
    </FormProvider>
  )
}
