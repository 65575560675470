import { Spinner } from 'components/Spinner/Spinner'
import { useGetTransformer } from 'loaders/transformers/useGetTransformer'
import { ReactNode } from 'react'
import { useParams } from 'react-router-dom'
import { TransformerProvider } from 'sections/transformers/TransformerProvider'

type TransformerLoaderProps = {
  children: ReactNode
}

export const TransformerLoader = ({ children }: TransformerLoaderProps) => {
  const { transformerId } = useParams()

  const { data: transformer } = useGetTransformer(
    transformerId ? { transformerId } : { skip: true }
  )

  return transformer ? (
    <TransformerProvider transformer={transformer}>
      {children}
    </TransformerProvider>
  ) : (
    <Spinner />
  )
}
