import { forwardRef, useEffect, useRef, useState } from 'react'
import { Input } from '@mui/joy'
import {
  TransformerAction,
  useTransformer
} from 'sections/transformers/TransformerProvider'
import {
  PathEditorItemProviderAction,
  usePathEditorItem
} from 'components/Paths/PathEditorItemProvider'
import { TreeItemContentProps } from '@mui/x-tree-view/TreeItem'
import { useApp } from 'components/AppProvider/AppProvider'
import { AppAction } from 'components/AppProvider/types'
import { nanoid } from 'nanoid'

export const RenameInput = forwardRef<HTMLDivElement, TreeItemContentProps>(
  (_props, ref) => {
    const { dispatch: appDispatch } = useApp()
    const { state: transformerState, dispatch: transformerDispatch } =
      useTransformer()
    const { state: pathEditorItemState, dispatch: pathEditorItemDispatch } =
      usePathEditorItem()
    const { renaming, path } = pathEditorItemState
    const { transformer } = transformerState

    const inputRef = useRef<HTMLInputElement>(null)

    const [filePathTo, setFilePathTo] = useState(path)

    useEffect(() => {
      if (renaming && inputRef.current) {
        inputRef.current.focus()
      }
    }, [renaming, inputRef.current])

    return (
      <Input
        ref={ref}
        size="sm"
        value={filePathTo}
        onChange={event => setFilePathTo(event.target.value)}
        slotProps={{
          input: {
            ref: inputRef
          }
        }}
        onKeyDown={event => {
          if (event.key === 'Escape') {
            pathEditorItemDispatch({
              type: 'setRenaming',
              payload: { renaming: false }
            })

            if (transformerState.hasNewItem) {
              transformerDispatch({
                type: 'setHasNewItem',
                payload: { hasNewItem: false }
              })
            }
          }

          if (event.key === 'Enter') {
            event.preventDefault()

            if (isDuplicateItem({ filePathTo, content: transformer.content })) {
              notifyDuplicateItem({ appDispatch, filePathTo })

              return
            }

            handleRename({
              filePathFrom: path,
              filePathTo,
              hasNewItem: transformerState.hasNewItem,
              transformerDispatch,
              pathEditorItemDispatch
            })

            transformerDispatch({
              type: 'selectFilePath',
              payload: {
                filePath: filePathTo,
                newFileSelected: path === ''
              }
            })
          }
        }}
        onBlur={() => {
          if (isDuplicateItem({ filePathTo, content: transformer.content })) {
            notifyDuplicateItem({ appDispatch, filePathTo })

            inputRef.current?.focus()

            return
          }

          handleRename({
            filePathFrom: path,
            filePathTo,
            hasNewItem: transformerState.hasNewItem,
            transformerDispatch,
            pathEditorItemDispatch
          })

          transformerDispatch({
            type: 'selectFilePath',
            payload: {
              filePath: filePathTo,
              newFileSelected: path === ''
            }
          })
        }}
      />
    )
  }
)

type IsDuplicateItemArgs = {
  filePathTo: string
  content: Record<string, string>
}

const isDuplicateItem = ({ filePathTo, content }: IsDuplicateItemArgs) => {
  return typeof content[filePathTo] !== 'undefined'
}

type NotifyDuplicateItemArgs = {
  appDispatch: (action: AppAction) => void
  filePathTo: string
}

const notifyDuplicateItem = ({
  appDispatch,
  filePathTo
}: NotifyDuplicateItemArgs) => {
  appDispatch({
    type: 'addNotification',
    payload: {
      id: nanoid(),
      type: 'error',
      title: `File ${filePathTo} already exists`
    }
  })
}

type HandleRenameArgs = {
  filePathFrom: string
  filePathTo: string
  hasNewItem: boolean
  transformerDispatch: (action: TransformerAction) => void
  pathEditorItemDispatch: (action: PathEditorItemProviderAction) => void
}

const handleRename = ({
  filePathFrom,
  filePathTo,
  hasNewItem,
  transformerDispatch,
  pathEditorItemDispatch
}: HandleRenameArgs) => {
  transformerDispatch({
    type: 'renameTransformerItem',
    payload: { filePathFrom, filePathTo }
  })

  pathEditorItemDispatch({
    type: 'setRenaming',
    payload: { renaming: false }
  })

  if (hasNewItem) {
    transformerDispatch({
      type: 'setHasNewItem',
      payload: { hasNewItem: false }
    })
  }
}
