import Chip from '@mui/joy/Chip'
import { ColorPaletteProp } from '@mui/joy/styles/types/colorSystem'
import { FC } from 'react'

type BadgeProps = {
  color?: ColorPaletteProp | 'neutral' | undefined
  children: string
}

export const Badge: FC<BadgeProps> = ({ color, children }) => (
  <Chip
    color={color}
    sx={{
      '--Chip-radius': '4px',
      height: 'fit-content',
      fontSize: '12px',
      minHeight: '20px',
      px: '6px'
    }}
  >
    {children}
  </Chip>
)
