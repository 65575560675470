import { Node, XYPosition } from 'reactflow'
import { nanoid } from 'nanoid'
import {
  SchematicFlowNode,
  NodeAction,
  GenerateLiveData,
  ArtifactsLiveData,
  SettingsLiveData,
  SchematicFlowNodeData,
  SchemaLiveData,
  SchemaLiveNode,
  SettingsLiveNode,
  GenerateLiveNode,
  ArtifactsLiveNode,
  MenuLiveData,
  MenuLiveNode,
  ViableParentNode,
  OpenSchemaLiveData,
  ExportLiveData,
  ExportLiveNode
} from 'sections/composer/types'
import { match } from 'ts-pattern'
import {
  BaseNode,
  WorkflowNode,
  defaultGenerateConfig
} from '@schematicos/types'
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject'
import { merge } from 'rxjs/internal/observable/merge'
import { scan } from 'rxjs/internal/operators/scan'
import { Observable } from 'rxjs/internal/Observable'

type CreateChildNodeArgs = {
  autoSelect: boolean
  parentNode: ViableParentNode
  position: XYPosition
}

type ConnectNodeArgs<T> = {
  autoSelect: boolean
  outbound$?: Observable<Record<string, unknown>>
  initialInternalState: T
}

const connectNode = <T>({
  autoSelect,
  outbound$: o$,
  initialInternalState
}: ConnectNodeArgs<T>) => {
  const internal$ = new BehaviorSubject<T>(initialInternalState)

  const outbound$ = o$ ?? new Observable<Record<string, unknown>>()

  return {
    autoSelect,
    inbound$: outbound$,
    outbound$: merge(internal$, outbound$).pipe(
      scan((acc, curr) => {
        return { ...acc, ...curr }
      })
    ),
    internal$
  }
}

export const createChildNode = ({
  autoSelect,
  parentNode,
  position
}: CreateChildNodeArgs):
  | SchematicFlowNode
  | Node<MenuLiveData, 'menu'>
  | null => {
  const baseNode = createBaseNode({ parentNodeId: parentNode.id, position })

  console.log('parentNode', parentNode)

  const childNode = match(parentNode)
    .with({ type: 'artifacts' }, matched => {
      return createExportNode({
        autoSelect,
        outbound$: matched.data.outbound$
      })
    })
    .with({ type: 'schema' }, matched => {
      return createGenerateNode({
        autoSelect,
        outbound$: matched.data.outbound$
      })
    })
    .with({ type: 'open-schema' }, matched => {
      return createGenerateNode({
        autoSelect,
        outbound$: matched.data.outbound$
      })
    })
    .with({ type: 'settings' }, matched => {
      return createArtifactsNode({
        autoSelect,
        outbound$: matched.data.outbound$
      })
    })
    .with({ type: 'generate' }, matched => {
      return createSettingsNode({
        autoSelect,
        outbound$: matched.data.outbound$
      })
    })
    .otherwise(matched => {
      console.error(`Unknown parent node: `, matched)
    })

  if (!childNode) {
    return null
  }

  return {
    ...childNode,
    ...baseNode
  }
}

type CreateNodeArgs = {
  autoSelect: boolean
  outbound$: Observable<Record<string, unknown>>
}

// throw new Error(`
//   X Break out id, position and parentNodeId into a separate function
//   X Implement menu node
//   - Implement transform node - later, transform and generate node should the same thing
//   - Use transform node with Form builder to generate forms
//   - Look at implementing custom data inputs
// `)

type CreateBaseNodeArgs = {
  parentNodeId: string
  position: XYPosition
}

export const createBaseNode = ({
  parentNodeId,
  position
}: CreateBaseNodeArgs): BaseNode => ({
  id: nanoid(),
  position,
  parentNodeId
})

type CreateMenuNodeFn = (args: MenuLiveData) => MenuLiveNode

export const createMenuNode: CreateMenuNodeFn = ({
  outbound$,
  items,
  parentNodeId
}) => ({
  type: 'menu',
  data: {
    parentNodeId,
    outbound$,
    items
  }
})

type CreateSchemaNodeFn = (args: CreateNodeArgs) => SchemaLiveNode

export const createSchemaNode: CreateSchemaNodeFn = ({
  autoSelect,
  outbound$
}) => ({
  type: 'schema',
  data: connectNode<SchemaLiveData>({
    autoSelect,
    initialInternalState: {},
    outbound$
  })
})

type CreateSettingsNodeFn = (args: CreateNodeArgs) => SettingsLiveNode

export const createSettingsNode: CreateSettingsNodeFn = ({
  autoSelect,
  outbound$
}) => ({
  type: 'settings',
  data: connectNode<SettingsLiveData>({
    autoSelect,
    initialInternalState: {},
    outbound$
  })
})

type CreateExportNodeFn = (args: CreateNodeArgs) => ExportLiveNode

const createExportNode: CreateExportNodeFn = ({ autoSelect, outbound$ }) => ({
  type: 'export',
  data: connectNode<ExportLiveData>({
    autoSelect,
    initialInternalState: {
      exportConfig: { type: null }
    },
    outbound$
  })
})

type CreateGenerateNodeFn = (args: CreateNodeArgs) => GenerateLiveNode

const createGenerateNode: CreateGenerateNodeFn = ({
  autoSelect,
  outbound$
}) => ({
  type: 'generate',
  data: connectNode<GenerateLiveData>({
    autoSelect,
    initialInternalState: {
      generateConfig: defaultGenerateConfig
    },
    outbound$
  })
})

type CreateArtifactNodeFn = (args: CreateNodeArgs) => ArtifactsLiveNode

const createArtifactsNode: CreateArtifactNodeFn = ({
  autoSelect,
  outbound$
}) => ({
  type: 'artifacts',
  data: connectNode<ArtifactsLiveData>({
    autoSelect,
    initialInternalState: {},
    outbound$
  })
})

type ConnectNodeToParentArgs = {
  autoSelect: boolean
  childNode: WorkflowNode
  parentNode?: SchematicFlowNode
}

export const connectNodeToParent = ({
  autoSelect,
  childNode,
  parentNode
}: ConnectNodeToParentArgs): SchematicFlowNodeData => {
  return match(childNode)
    .with({ type: 'schema' }, (matched): NodeAction<SchemaLiveData> => {
      return connectNode<SchemaLiveData>({
        autoSelect,
        initialInternalState: {
          schemaId: matched.data.schemaId
        },
        outbound$: parentNode?.data.outbound$
      })
    })
    .with(
      { type: 'open-schema' },
      (matched): NodeAction<OpenSchemaLiveData> => {
        return connectNode<OpenSchemaLiveData>({
          autoSelect,
          initialInternalState: {
            source: matched.data.source
          },
          outbound$: parentNode?.data.outbound$
        })
      }
    )
    .with({ type: 'export' }, (matched): NodeAction<ExportLiveData> => {
      return connectNode<ExportLiveData>({
        autoSelect,
        initialInternalState: {
          exportConfig: matched.data.exportConfig ?? { type: null }
        },
        outbound$: parentNode?.data.outbound$
      })
    })
    .with({ type: 'settings' }, (matched): NodeAction<SettingsLiveData> => {
      return connectNode<SettingsLiveData>({
        autoSelect,
        initialInternalState: {
          settingsConfig: matched.data.settingsConfig
        },
        outbound$: parentNode?.data.outbound$
      })
    })
    .with({ type: 'generate' }, (matched): NodeAction<GenerateLiveData> => {
      return connectNode<GenerateLiveData>({
        autoSelect,
        initialInternalState: {
          generateConfig: matched.data.generateConfig ?? defaultGenerateConfig
        },
        outbound$: parentNode?.data.outbound$
      })
    })
    .with({ type: 'artifacts' }, (): NodeAction<ArtifactsLiveData> => {
      return connectNode<ArtifactsLiveData>({
        autoSelect,
        initialInternalState: {
          artifactsMap: {}
        },
        outbound$: parentNode?.data.outbound$
      })
    })
    .exhaustive()
}
